import styled from "styled-components";
import { ArrowBackIosNew } from '@styled-icons/material-outlined/ArrowBackIosNew'

interface VisibleModalProps {
  visibleModal: boolean;
}

export const ContainerModal = styled.div<VisibleModalProps>`
  position: absolute;
  z-index: 100;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  display: ${(props) => (props.visibleModal ? "flex" : "none")};
  justify-content: center;
  align-content: center;
  background: #000000b2;
`;

export const Container = styled.div`
  width: 90%;
  z-index: 100;
  top: 13rem;
  position: absolute;
  height: auto;
  display: flex;
  flex-direction: column;
  background: radial-gradient(
    116.41% 62.81% at 50% 50%,
    #8f94ce 0%,
    #282a81 100%
  );
  padding: 10px;
  transition: transform 0.2s ease-out;
  border: 2px solid #fff;
  border-radius: 4px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;

  button {
    background: radial-gradient(
      79.29% 79.29% at 49.87% 45.36%,
      #1e1a59 0%,
      #080720 100%
    );
    padding: 5px 10px;
    border: none;
    color: #fff;
    border-radius: 4px;
  }
  span {
    font-size: 1.4rem;
    color: #fff;
    margin-left: 5px;
    font-weight: bold;
  }

  @media (max-width: 340px) {
    span {
      font-size: 1.2rem;
    }
  }
`;

export const Content = styled.div`
  margin-top: 1rem;

  div {
    display: flex;
    &.box {
      width: 100%;
      border: 3px solid #000;
      height: 3rem;
      border-radius: 8px;
      background: #fff;
      font-size: 1.2rem;
      font-weight: bold;
      justify-content: center;
      align-items: center;
      margin-bottom: 1.5rem;
      user-select: text;
    }
    &.box2 {
      width: 100%;
      border: 3px solid #000;
      height: 8rem;
      border-radius: 8px;
      background: #fff;
      font-size: 1.2rem;
      font-weight: bold;
      justify-content: center;
      align-items: center;
      margin-top: 1.5rem;
    }
  }

  button {
    background: radial-gradient(
      79.29% 79.29% at 49.87% 45.36%,
      #1fa71c 0%,
      #0d3107 100%
    );
    border: 2px solid #fff;
    border-radius: 4px;
    width: 100%;
    color: #fff;
    text-align: center;
    height: 3rem;
    font-size: 1.2rem;
    cursor: pointer;
    :hover {
      opacity: 0.9;
    }
  }
`;

export const Title = styled.span`
  font-size: 1.4rem;
  color: #fff;
  margin-left: 5px;
  font-weight: bold;
`;

export const Footer = styled.div`
  margin-top: 1rem;
`;

export const SSkeletonPulse = styled.div`
  border: 3px black solid;
  margin-bottom: 1rem;
  border-radius: 6px;
  display: inline-block;
  height: 10rem;
  width: 90%;
  background: linear-gradient(
    -90deg,
    #f0f0f07a 0%,
    #ffffff 50%,
    #f0f0f07a 100%
  );
  background-size: 400% 400%;
  animation: pulse 1.2s ease-in-out infinite;
  @keyframes pulse {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
`;

export const SSkeletonPulseGreen = styled.div`
  margin-bottom: 1rem;
  border-radius: 6px;
  display: inline-block;
  height: 10rem;
  width: 90%;
  background: linear-gradient(-90deg, #1fa71c 0%, #0d3107 50%, #1fa71c 100%);
  background-size: 400% 400%;
  animation: pulse 1.2s ease-in-out infinite;
  @keyframes pulse {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
`;

export const SSkeletonLine = styled(SSkeletonPulse)`
  width: 5.5em;
  border-radius: 5px;

  &::before {
    content: "\00a0";
  }
`;

export const Input = styled.input`
  border: 3px solid black;
  height: 3rem;
  width: 100%;
  letter-spacing: 0.05rem;
  border-radius: 5px;
  outline: none;
  font-size: 1.3rem;
  margin-top: 0.2rem;
  text-align: center;
  font-weight: 900;
  color: #0f0f0f;
  margin-bottom: 1rem;
`;

export const ArrowBackIosNewIcon = styled(ArrowBackIosNew)`
  height: 1rem;
  width: 1rem;
`;
