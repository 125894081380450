/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import * as V from "../pages/Styles/Vencedores";

import Kuadra from "../images/kuadraVencedorTitulo.svg";
import StarsKuVencedores from "../images/starsKuVencedores.svg";

import Kina from "../images/kinaVencedorTitulo.svg";
import StarsKiVencedores from "../images/starsKiVencedores.svg";

import Keno from "../images/kenoVencedorTitulo.svg";
import StarsKeVencedores from "../images/starsKeVencedores.svg";

import Acumulado from "../images/acumuladoVencedorTitulo.svg";
import StarLeftAc from "../images/starsLeftAc.svg";

import { useLocation } from "react-router";

type vencedores = {
  cartelas: ICartelas[];
  vencedores: number[];
  tipo: "kuadra" | "kina" | "keno" | "acumulado";
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const Vencedores = ({ cartelas, vencedores, tipo }: vencedores) => {
  const [tvMode, setTvmode] = useState("");

  let query = useQuery();
  let tvQuery = useQuery().get("tv");

  useEffect(() => {
    if (tvQuery) {
      setTvmode(tvQuery);
    }
  }, [tvQuery]);
  return (
    <V.Container style={tipo === "acumulado" ? {marginTop:"4rem"} : {marginTop:"3.5rem"}}>
      {!tvMode&& <V.Header tipo={tipo}>{("Vencedores " + tipo).toUpperCase()}</V.Header>}

      {tvMode&&tipo === "kuadra"&& <V.HeaderImg style={{ width: "30rem", top:"-6rem"}} src={Kuadra}/>}
      {tvMode&&tipo === "kina"&& <V.HeaderImg style={{ width: "30rem", top:"-6rem"}} src={Kina}/>}
      {tvMode&&tipo === "keno"&& <V.HeaderImg style={{ width: "30rem", top:"-6rem"}} src={Keno}/>}
      {tvMode&&tipo === "acumulado"&& <V.HeaderImg style={{ width: "35rem", top:"-7.5rem"}} src={Acumulado}/>}

      {tvMode && tipo === "kuadra"&& <V.StartLeft style={{left:"-12rem"}} src={StarsKuVencedores}/>}
      {tvMode && tipo === "kina"&& <V.StartLeft style={{left:"-11rem"}} src={StarsKiVencedores}/>}
      {tvMode && tipo === "keno"&& <V.StartLeft style={{left:"-11rem"}} src={StarsKeVencedores}/>}
      {tvMode && tipo === "acumulado"&& <V.StartLeft src={StarLeftAc}/>}

      {tvMode && tipo === "kuadra"&& <V.StartRight style={{right:"-12rem", transform: 'rotate(180deg)'}} src={StarsKuVencedores}/>}
      {tvMode && tipo === "kina"&& <V.StartRight style={{right:"-11rem", transform: 'rotate(180deg)'}} src={StarsKiVencedores}/>}
      {tvMode && tipo === "keno"&& <V.StartRight style={{right:"-11rem", transform: 'rotate(180deg)'}} src={StarsKeVencedores}/>}
      {tvMode && tipo === "acumulado"&& <V.StartRight style={{transform: 'rotate(180deg)'}} src={StarLeftAc}/>}

      <V.Boby tvMode={tvMode} tipo={tipo}>
        {vencedores.map((vencedor, index) => {
          return cartelas
            .filter((car) => car.codigo === vencedor)
            .map((car) => {
              return (
                <V.Line key={`vencedor${vencedor}_${index}`}>
                  <div
                    style={{
                      width: "calc(100% * (1/5))",
                      padding: "0rem 0.2rem 0rem 0rem",
                    }}
                  >
                    <V.Bloco
                      style={{
                        justifyContent: "center",
                      }}
                    >
                      {car.codigo}
                    </V.Bloco>
                  </div>
                  <div
                    style={{
                      width: "calc(100% * (4/5))",
                      padding: "0rem 0rem 0rem 0.1rem",
                    }}
                  >
                    <V.Bloco
                      style={{
                        padding: "0 0.2rem",
                      }}
                    >
                      {car.estabelecimento}
                    </V.Bloco>
                  </div>
                </V.Line>
              );
            });
        })}
      </V.Boby>
    </V.Container>
  );
};