import { format } from "date-fns";
import { motion } from "framer-motion";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import {
  bindKeyboard,
  SlideRenderProps,
  virtualize,
} from "react-swipeable-views-utils";
import * as Style from "../componentsStyle/CardCompraSorteio";
import { formatDate, timerSort, verifyTime } from "../hooks/formatTime";
import AudioHome from "../hooks/AudioHome";

import useInterval from "../hooks/useIntervals";
import {
  CardAndButtonsGroup,
  Wrapper,
} from "../pages/Styles/TabelaComTituloSwipe";

type Sorteio = {
  lista: ISorteio[] | undefined;
  datahora: string;
  titulo: string;
  cor: string;
};

const VirtualizeSwipeableViews = bindKeyboard(virtualize(SwipeableViews));

export const TabelaComTituloSwipe = ({
  lista,
  titulo,
  cor,
  datahora,
}: Sorteio) => {
  const [abas, setabas] = useState(false);
  const [swipeIndex, setSwipeIndex] = useState(0);
  const history = useHistory();
  const [count, setcount] = useState(0);

  useInterval(() => {
    setcount(count + 1);
  }, 1000);

  function TipoDaRodadaTitulo(codigo?: number) {
    if (titulo == "NORMAL") {
      if (codigo) {
        if (codigo % 2 === 0) {
          return "primaryNormal";
        } else {
          return "secondaryNormal";
        }
      } else {
        return "primaryNormal";
      }
    } else if (titulo == "ESPECIAL") {
      if (codigo) {
        if (codigo % 2 === 0) {
          return "primarySpecial";
        } else {
          return "secondarySpecial";
        }
      } else {
        return "primarySpecial";
      }
    } else {
      return "primarySuperSpecial";
    }
  }

  function choseSortName() {
    if (titulo == "NORMAL") {
      return "normal";
    } else if (titulo == "ESPECIAL") {
      return "special";
    } else {
      return "super";
    }
  }

  const playAudio = (type: number) => {
    console.log(type);
    return <AudioHome type={type} />;
  };

  const slideRenderer = ({ index }: SlideRenderProps) => {
    return (
      <>
        {datahora && lista && lista[index]?.codigo ? (
          <>
            <Style.Container
              className={TipoDaRodadaTitulo(lista[index]?.codigo)}
              style={{ width: "100%", marginBottom: "0", marginTop: "1rem" }}
            >
              <Style.Header>
                <h1>
                  {format(new Date(lista[index]?.data_partida), "dd/MM/yyyy")}
                </h1>
                <span style={{ display: "flex", alignItems: "center" }}>
                  SORTEIO: <h3>{lista[index]?.codigo}</h3>
                </span>
                <h1>
                  {format(new Date(lista[index]?.data_partida), "HH:mm:ss")}
                </h1>
              </Style.Header>
              <Style.Content style={{ flexDirection: "row" }}>
                <Style.ContentLeft
                  className={TipoDaRodadaTitulo(lista[index]?.codigo)}
                  style={{ width: "100%" }}
                >
                  <Style.Values>
                    <Style.Value style={{ width: "calc(1/3)*100%}" }}>
                      <span
                        className={TipoDaRodadaTitulo(lista[index]?.codigo)}
                        style={{ fontSize: "1.6rem" }}
                      >
                        KUADRA
                      </span>
                      <h4>{lista[index]?.valor_kuadra}</h4>
                    </Style.Value>
                    <Style.Value>
                      <span
                        className={`${TipoDaRodadaTitulo(
                          lista[index]?.codigo
                        )} textKeno`}
                        style={{ fontSize: "3rem" }}
                      >
                        KENO
                      </span>
                      <h4 className="textKeno">{lista[index]?.valor_keno}</h4>
                    </Style.Value>
                    <Style.Value style={{ width: "calc(1/3)*100%}" }}>
                      <span
                        className={TipoDaRodadaTitulo(lista[index]?.codigo)}
                        style={{ fontSize: "1.8rem", lineHeight: "1.35" }}
                      >
                        KINA
                      </span>
                      <h4 style={{ textAlign: "right" }}>
                        {lista[index]?.valor_kina}
                      </h4>
                    </Style.Value>
                  </Style.Values>
                  <Style.ConteinerInfoSorteio>
                    <Style.InfoSorteio>
                      {lista[index]?.valor_antecipado &&
                        "DOAÇÃO ANTECIPADA: R$" +
                          lista[index]?.valor_antecipado}
                    </Style.InfoSorteio>
                    <Style.InfoSorteio>
                      {lista[index]?.valor_cartela &&
                        "NORMAL: R$" + lista[index]?.valor_cartela}
                    </Style.InfoSorteio>
                  </Style.ConteinerInfoSorteio>
                </Style.ContentLeft>
              </Style.Content>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Style.TextCronometro>
                  {timerSort(
                    formatDate(lista[index]?.data_partida),
                    formatDate(datahora),
                    count
                  )}

                  {verifyTime(
                    formatDate(lista[index]?.data_partida),
                    formatDate(datahora),
                    count
                  ) == 1 && (
                    <>
                      {playAudio(1)}
                      {/* {setType(1)} */}
                    </>
                  )}

                  {verifyTime(
                    formatDate(lista[index]?.data_partida),
                    formatDate(datahora),
                    count
                  ) == 2 && (
                    <>
                      {playAudio(2)}
                      {/* {setType(2)} */}
                    </>
                  )}
                  {verifyTime(
                    formatDate(lista[index]?.data_partida),
                    formatDate(datahora),
                    count
                  ) == 3 && (
                    <>
                      {playAudio(3)}
                      {/* {setType(3)} */}
                    </>
                  )}
                  {timerSort(
                    formatDate(lista[index]?.data_partida),
                    formatDate(datahora),
                    count
                  ) == "PREPARANDO" && (
                    <>
                      <Style.TextAnimationPoints>.</Style.TextAnimationPoints>
                      {playAudio(4)}
                    </>
                  )}
                </Style.TextCronometro>
                <Style.ButtonGreen
                  style={{
                    marginTop: "0.25rem",
                    background: "#147e1f",
                    border: "none",
                    borderRadius: "5px",
                  }}
                  onClick={() =>
                    history.push("/comprar/sorteios?type=" + choseSortName())
                  }
                >
                  COMPRAR
                </Style.ButtonGreen>
              </div>
            </Style.Container>
            {lista && lista.length > 1 ? (
              <Style.ContentFooterBox
                className={TipoDaRodadaTitulo(lista[index]?.codigo)}
              >
                <Style.FooterBox
                  className={TipoDaRodadaTitulo(lista[index]?.codigo)}
                />
                <Style.FooterBox2
                  className={TipoDaRodadaTitulo(lista[index]?.codigo)}
                />
              </Style.ContentFooterBox>
            ) : (
              <></>
            )}
          </>
        ) : (
          <>
            <Style.Container
              className={TipoDaRodadaTitulo()}
              style={{ width: "100%", marginBottom: "0", marginTop: "1rem" }}
            >
              <Style.Header>
                <span></span>
                {/* <h3>{"-"}</h3> */}
              </Style.Header>
              <Style.Content style={{ flexDirection: "row" }}>
                <Style.ContentLeft
                  className={TipoDaRodadaTitulo()}
                  style={{ width: "100%" }}
                >
                  <Style.Values>
                    <Style.Value>
                      {/* <span className={TipoDaRodadaTitulo(titulo)}>KUADRA</span> */}
                      {/* <h4>{""}</h4> */}
                    </Style.Value>
                    <Style.Value>
                      <span className={TipoDaRodadaTitulo()}>
                        SEM SORTEIOS{" "}
                      </span>
                      {/* <h4 className="textKeno">{""}</h4> */}
                    </Style.Value>
                    <Style.Value>
                      <span className={TipoDaRodadaTitulo()}>{/* KINA */}</span>
                      {/* <h4>{"kuadra"}</h4> */}
                    </Style.Value>
                  </Style.Values>
                  <Style.ConteinerInfoSorteio>
                    {/* <InfoSorteio>DATA: - HORA:</InfoSorteio> */}
                    {/* <InfoSorteio>DOAÇÃO:</InfoSorteio> */}
                  </Style.ConteinerInfoSorteio>
                </Style.ContentLeft>
              </Style.Content>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height: "1",
                }}
              >
                <Style.TextCronometro
                  style={{ height: "1rem" }}
                ></Style.TextCronometro>
              </div>
            </Style.Container>
          </>
        )}
      </>
    );
  };

  const handleChangeIndex = (index: number) => {
    if (lista?.length === 0) {
      return;
    }
    setSwipeIndex(index);
  };

  const listLength = () => {
    if (!lista || lista?.length === undefined) {
      return 1;
    } else {
      return lista?.length;
    }
  };

  return (
    <Wrapper
    // onClick={() => history.push("/comprar/sorteios?type=" + sendClick())}
    >
      <div
        style={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "space-around",
        }}
      >
        {swipeIndex > 0 ? (
          <div
            className="Esquerda"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
              textShadow: "0px 3px 3px rgba(0, 0, 0, 0.81),",
              fontWeight: 900,
            }}
            onClick={() => setSwipeIndex(swipeIndex - 1)}
          >
            <Style.TriangleRightIcon style={{ color: "white" }} />
            {"ANTERIOR"}
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
              textShadow: "0px 3px 3px rgba(0, 0, 0, 0.81)",
              opacity: "0.2",
            }}
          >
            <Style.TriangleRightIcon
              style={{ color: "white", opacity: "0.2" }}
            />
            {"ANTERIOR"}
          </div>
        )}
        <Style.TitleTop>{titulo}</Style.TitleTop>
        {lista && lista.length > 1 ? (
          <>
            {swipeIndex + 1 === lista.length || lista.length === 0 ? (
              <div
                style={{
                  display: "flex",
                  alignSelf: "flex-end",
                  alignItems: "center",
                  color: "white",
                  textShadow: "0px 3px 3px rgba(0, 0, 0, 0.81)",
                  fontWeight: 900,
                  opacity: "0.2",
                }}
              >
                {"PRÓXIMO"}
                <Style.TriangleRightIcon
                  style={{
                    color: "white",
                    opacity: "0.2",
                    alignItems: "center",
                  }}
                />
              </div>
            ) : (
              <div
                className="Direita"
                style={{
                  display: "flex",
                  color: "white",
                  textShadow: "0px 3px 3px rgba(0, 0, 0, 0.81)",
                  alignItems: "center",
                  fontWeight: 900,
                }}
                onClick={() => {
                  setSwipeIndex(swipeIndex + 1);
                }}
              >
                {"PRÓXIMO"}
                <Style.TriangleRightIcon
                  style={{
                    color: "white",
                    display: "flex",
                    flexDirection: "row",
                    textShadow: "0px 3px 3px rgba(0, 0, 0, 0.81)",
                  }}
                />
              </div>
            )}
          </>
        ) : (
          <>
            {" "}
            {/* Botão do próximo */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                color: "white",
                textShadow: "0px 3px 3px rgba(0, 0, 0, 0.81)",
                alignItems: "center",
                opacity: "0.2",
              }}
            >
              {"PRÓXIMO"}
              <Style.TriangleRightIcon
                style={{ color: "white", opacity: "0.2" }}
              />
            </div>
          </>
        )}
      </div>

      <motion.div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        initial={{ x: -100 }}
        animate={{ x: 0 }}
        transition={{ delay: 1 }}
      >
        <CardAndButtonsGroup>
          <>
            <VirtualizeSwipeableViews
              index={swipeIndex}
              onChangeIndex={handleChangeIndex}
              slideRenderer={slideRenderer}
              slideCount={listLength()}
              enableMouseEvents
              containerStyle={{ width: "85vw" }}
            />
          </>
          <></>
        </CardAndButtonsGroup>
      </motion.div>
    </Wrapper>
  );
};

export default TabelaComTituloSwipe;
