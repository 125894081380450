import styled from 'styled-components';

interface ShareProps{
    isVisible: boolean;
}

export const Container = styled.div<ShareProps>`
    transform: ${props => props.isVisible ? 'translateY(0vw)' : 'translateY(65vw)'};
    position: fixed;
    bottom: 0;
    transition: transform .4s ease-out;
    z-index: 10000000;
    background: #fff;
    width: 100%;
    padding: 10px;
    height: 25%;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    button{
        background: none;
    }
`;

export const Header = styled.div`
    span{
        font-size: 16px;
        color: #bababa;
        font-weight: normal;
    }
`;

export const Content = styled.div`
    display: flex;
    margin-top: 10px;
    padding: 10px;
`;

export const Item = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    margin-right: 20px;
    font-weight: lighter;
`;