import { Search } from "@styled-icons/boxicons-regular/Search";
import { ArrowIosDownwardOutline } from "@styled-icons/evaicons-outline/ArrowIosDownwardOutline";
import { Trash } from "@styled-icons/foundation/Trash";
import styled from "styled-components";

interface Props {
  isExpanded: boolean;
}

export const Container = styled.div`
  background: radial-gradient(
    116.41% 62.81% at 50% 50%,
    #8f94ce 0%,
    #3f408a 100%
  );
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const Input = styled.input`
  width: 80%;
  height: 100%;
  letter-spacing: 0.05rem;
  padding-left: 0.5rem;
  border-radius: 5px;
  border: none;
  outline: none;
  font-size: 1rem;
`;

export const Tittle = styled.h4`
  font-size: 1.7rem;
  color: #f0d50c;
`;

export const FormContainer = styled.div`
  width: 90%;
  height: 3rem;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

export const LinkContainer = styled.div`
  height: 100%;
  font-size: 2rem;
  width: 20%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const TittleWhite = styled.h4`
  font-size: 2.5rem;
  color: white;
  text-align: center;
  text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.81);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Button = styled.div`
  width: 100%;
  height: 3rem;
  background: #f0d50c;
  margin-left: 1rem;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DivTitle = styled.div`
  display: flex;
  width: 90%;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonToExpand = styled.button<Props>`
  background: radial-gradient(
    397.62% 397.62% at 51.13% -283.33%,
    #1b10b1 0%,
    #111040 100%
  );
  color: #fff;
  height: 35px;
  width: 40px;
  border-radius: 4px;
  border: 1px solid #fff;
  font-size: 1.2rem;
  font-weight: 900;
  svg {
    &.expand {
      transform: ${(props) =>
        props.isExpanded ? "rotate(180deg)" : "rotate(0deg)"};
      transition: transform 0.3s ease-out;
    }
  }
`;

export const Table = styled.table`
  border-radius: 10px;
  width: 90%;
  padding: 0.3rem;

  &.normal {
    background: white;
  }

  &.especial {
    background: linear-gradient(
        89.48deg,
        #cd911b -9.2%,
        #f8b123 14.81%,
        #fefc37 26.55%,
        #fffe8e 44.15%,
        #fefd5a 62.3%,
        #f8b125 79.37%,
        #cd911b 93.24%
      ),
      linear-gradient(
        89.48deg,
        #978a32 6.65%,
        #b2a129 27.17%,
        #c5b023 47.43%,
        #d7bf1d 69.71%,
        #bdaa2a 93.24%
      ),
      linear-gradient(
        89.48deg,
        #978a32 6.65%,
        #b2a129 27.17%,
        #c5b023 47.43%,
        #d7bf1d 69.71%,
        #bdaa2a 93.24%
      ),
      linear-gradient(
        89.48deg,
        #978a32 6.65%,
        #b2a129 27.17%,
        #c5b023 47.43%,
        #d7bf1d 69.71%,
        #bdaa2a 93.24%
      );
  }
  &.super {
    background: linear-gradient(
      92.18deg,
      #5993d4 -8.3%,
      #afe2ff 16.91%,
      #ffedf7 46.1%,
      #fdcde1 74.65%,
      #fe7cec 96.4%
    );
  }
`;

export const Tr = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 900;
  border-radius: 10px;
`;

export const Th = styled.div<{ w: string }>`
  width: ${(props) => props.w};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  font-size: 1rem;
  font-weight: 700;
`;

export const Td = styled.div<{ w: string }>`
  width: ${(props) => props.w};
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
`;

export const TrInside = styled(Tr)`
  background: #121030;
  border-radius: 0px;
`;

export const ContainerList = styled.div`
  background: #131052;
  padding: 0.3rem 0rem;
  margin: 0.2rem 0;
`;

export const SearchIcon = styled(Search)`
  height: 1rem;
  width: 1rem;
`;

export const TrashIcon = styled(Trash)`
  height: 1rem;
  width: 1rem;
`;

export const ArrowDownOutlineIcon = styled(ArrowIosDownwardOutline)`
  height: 1rem;
  width: 1rem;
`;
