import * as React from "react";
import useWindowSize from "../hooks/useWindowSize";

//Para alterar a cor, precisa alterar o nome do id da url para pintura #prefix__paint0_radial
//Ele precisa ser unico entre todos os componentes
export interface CustomItemProps {
  color: string[] | undefined;
}

export const BallC: React.FC<CustomItemProps> = ({ color }) => {
  const { width } = useWindowSize();
  const DefineSizeBall = () => {
    if (width >= 2048) {
      return "14.5rem";
    } else if (width >= 1500 && width <= 2047) {
      return "13rem";
    } else if (width >= 1028 && width <= 1499) {
      return "13rem";
    } else if (width <= 1027 && width >= 800) {
      return "11.5rem";
    } else {
      return "6.5rem";
    }
  };
  return (
    <svg
      width={DefineSizeBall()}
      height={DefineSizeBall()}
      viewBox="0 0 468 468"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx={234} cy={234} r={234} fill="url(#prefix__paint0_radial)" />
      <circle
        cx={233.5}
        cy={217.5}
        r={168.5}
        fill="url(#prefix__paint1_radial)"
      />
      <circle cx={234} cy={218} r={145} fill="url(#prefix__paint2_radial)" />
      <circle
        cx={129.5}
        cy={203.5}
        r={22.5}
        fill="url(#prefix__paint3_radial)"
      />
      <circle
        cx={99.5}
        cy={357.5}
        r={13.5}
        fill="url(#prefix__paint4_radial)"
      />
      <circle
        cx={326.5}
        cy={399.5}
        r={13.5}
        fill="url(#prefix__paint5_radial)"
      />
      <ellipse
        cx={330.271}
        cy={84.567}
        rx={10}
        ry={22}
        transform="rotate(-37.223 330.271 84.567)"
        fill="url(#prefix__paint6_radial)"
      />
      <ellipse
        cx={382.896}
        cy={266.73}
        rx={44.3}
        ry={28.929}
        transform="rotate(-68.977 382.896 266.73)"
        fill="url(#prefix__paint7_radial)"
      />
      <defs>
        <radialGradient
          id="prefix__paint0_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(90 0 234) scale(234)"
        >
          <stop stopColor={color ? color[0] : "#000"} />
          <stop offset={0.771} stopColor={color ? color[1] : "#000"} />
          <stop offset={1} stopColor={color ? color[2] : "#000"} />
        </radialGradient>
        <radialGradient
          id="prefix__paint1_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(90 8 225.5) scale(168.5)"
        >
          <stop stopColor="#373737" />
          <stop offset={1} />
        </radialGradient>
        <radialGradient
          id="prefix__paint2_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(90 8 226) scale(145)"
        >
          <stop stopColor="#E2E2E2" />
          <stop offset={0.938} stopColor="#BABABA" />
          <stop offset={1} stopColor="#969696" />
        </radialGradient>
        <radialGradient
          id="prefix__paint3_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(90 -37 166.5) scale(22.5)"
        >
          <stop stopColor="#fff" />
          <stop offset={0.339} stopColor="#FFFCFC" stopOpacity={0.408} />
          <stop offset={0.74} stopColor="#FFFCFC" stopOpacity={0} />
        </radialGradient>
        <radialGradient
          id="prefix__paint4_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(90 -129 228.5) scale(13.5)"
        >
          <stop stopColor="#fff" />
          <stop offset={0.339} stopColor="#FFFCFC" stopOpacity={0.408} />
          <stop offset={0.74} stopColor="#FFFCFC" stopOpacity={0} />
        </radialGradient>
        <radialGradient
          id="prefix__paint5_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(90 -36.5 363) scale(13.5)"
        >
          <stop stopColor="#fff" />
          <stop offset={0.339} stopColor="#FFFCFC" stopOpacity={0.408} />
          <stop offset={0.74} stopColor="#FFFCFC" stopOpacity={0} />
        </radialGradient>
        <radialGradient
          id="prefix__paint6_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(0 22 -10 0 330.271 84.567)"
        >
          <stop stopColor="#fff" />
          <stop offset={0.339} stopColor="#FFFCFC" stopOpacity={0.408} />
          <stop offset={0.74} stopColor="#FFFCFC" stopOpacity={0} />
        </radialGradient>
        <radialGradient
          id="prefix__paint7_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(0 28.9294 -44.3005 0 382.896 266.73)"
        >
          <stop stopColor="#fff" />
          <stop offset={0.339} stopColor="#FFFCFC" stopOpacity={0.408} />
          <stop offset={0.74} stopColor="#FFFCFC" stopOpacity={0} />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default BallC;
