import React from "react";
import styled from "styled-components";
import Ball1C from "../../images/ball1C";
import Ball2C from "../../images/ball2C";
import Ball3C from "../../images/ball3C";
import Ball4C from "../../images/ball4C";
import Ball5C from "../../images/ball5C";

const Container = styled.div`
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    background: radial-gradient(79.29% 79.29% at 49.87% 45.36%, #F9F9F9 0%, #9D9D9D 100%);

    border-radius: 1rem;
    border: 0.2rem solid #000D82;
`;

export const BolaContainer = styled.div`
    width: 100%;
    height: calc(100% * 1/5);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    p{
        position: absolute;
        padding-bottom: 0.5rem;
        font-size: 1.7rem;
        font-weight: bold;
    }
`;

interface TuboBola{
    bolas:number[],
    turno: number,
}

export const TuboBola:React.FC<TuboBola> = ({bolas, turno}) =>{
    
    const colorBalls = {
        blue: ["#0016D9", "#0013BA", "#000D82"],
        red: ["#D90000", "#BA0000", "#820000"],
        yellow: ["#D4D900", "#BAA700", "#827C00"],
        green: ["#11D900", "#0FBA00", "#00820D"],
        cyan: ["#00BFD9", "#00AFBA", "#008282"],
        gray: ["#c0c0c0", "#a3a3a3", "#9e9e9e"],
    };
    

    function ballColors(ball: number) {

        if (ball < 18) return colorBalls.blue;
        else if (ball > 19 && ball < 36) return colorBalls.red;
        else if (ball > 37 && ball < 54) return colorBalls.yellow;
        else if (ball > 55 && ball < 72) return colorBalls.green;
        else return colorBalls.cyan;
    }

    return(
        <Container>
            {bolas[turno - 1] &&
                <BolaContainer>
                    <Ball1C color={ballColors(bolas[turno - 1])} />
                    <p>{bolas[turno - 1]}</p>
                </BolaContainer>
            }
            {bolas[turno - 2] &&
                <BolaContainer>
                    <Ball2C color={ballColors(bolas[turno - 2])} />
                    <p>{bolas[turno - 2]}</p>
                </BolaContainer>
            }
            {bolas[turno - 3] &&
                <BolaContainer>
                    <Ball3C color={ballColors(bolas[turno - 3])} />
                    <p>{bolas[turno - 3]}</p>
                </BolaContainer>
            }
            {bolas[turno - 4] &&
                <BolaContainer>
                    <Ball4C color={ballColors(bolas[turno - 4])} />
                    <p>{bolas[turno - 4]}</p>
                </BolaContainer>
            }
            {bolas[turno - 5] &&
                <BolaContainer>
                    <Ball5C color={ballColors(bolas[turno - 5])} />
                    <p>{bolas[turno - 5]}</p>
                </BolaContainer>
            }
        </Container>
    )
}

export default TuboBola;