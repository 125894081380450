import React, { useEffect, useState } from "react";

import { Container } from "../componentsStyle/NomeServidor";
import { GetTitleServer } from "../WebRequests/requestsJson";
//import {useApi} from '../services/apiContext';
const NomeServidor: React.FC = () => {
  const [tittle, setTittle] = useState();
  useEffect(() => {
    async function fetch() {
      const title = await GetTitleServer();
      if (title.data) {
        setTittle(title.data.data.nome_server);
      }
    }
    fetch();
  }, []);
  //const {route} = useApi();

  return (
    <Container>
      <span>
        {tittle ? tittle : window.location.href}
        {/* {process.env.REACT_APP_ROUTE?.replace(/[^\d\^\w]/, " ").replace(
          ".app",
          ""
        ) + " v0.0.3"} */}
      </span>
    </Container>
  );
};

export default NomeServidor;
