import { add } from "date-fns";
import React, { useEffect, useState } from "react";
import useInterval from "../../hooks/useIntervals";
import AnuncioSort from "./AnuncioSort";
import { AnuncioProximos, AnuncioVencedores } from "./AnuncioProximos";
import id from "date-fns/esm/locale/id/index.js";
import NomeServidor from "../../components/NomeServidor";

type CardSorte = {
  sortList: ISorteio[] | undefined;
  vencedores?: IVencedoresHistorico[];
  datahora: string;
};

interface AddType {
  sorteio?: ISorteio;
  vencedores?: IVencedoresHistorico[];
}

function handleSortByDate(a: ISorteio, b: ISorteio) {
  const dataA = new Date(a.data_partida);
  const dataB = new Date(b.data_partida);
  return dataA.getTime() - dataB.getTime();
}

export const CardSorteTv = ({ sortList, datahora, vencedores }: CardSorte) => {
  const [adds, setAdds] = useState([] as AddType[]);
  const [index, setIndex] = useState(0);
  const [count, setCount] = useState(0);

  useInterval(() => {
    setCount(count + 1);
  }, 1000);

  useEffect(() => {
    const anuncios_sorteios: AddType[] =
      sortList?.sort(handleSortByDate)?.map((sorteio) => ({
        sorteio,
      })) || [];

    console.log(
      anuncios_sorteios.map((x) => new Date(x.sorteio?.data_partida || ""))
    );
    const anuncio_vendedores: AddType = {
      vencedores,
    };
    setAdds([...anuncios_sorteios, anuncio_vendedores]);
  }, [sortList, vencedores]);

  const currentAdd = adds?.[index];

  useEffect(() => {
    const add = adds?.[index];
    if (!add?.sorteio && !add?.vencedores) {
      if (index + 1 < adds.length) {
        setIndex(index + 1);
      } else {
        setIndex(0);
      }
    }
  }, [adds, index]);

  useInterval(() => {
    const sorteio_data =
      currentAdd?.sorteio && new Date(currentAdd?.sorteio?.data_partida);
    const timeLeft =
      sorteio_data && sorteio_data.getTime() - new Date().getTime();
    if (timeLeft && timeLeft < 2 * 60 * 1000) {
      // min -> seg -> millesimos
      return;
    } else if (index + 1 < adds.length) {
      setIndex(index + 1);
    } else {
      setIndex(0);
    }
  }, 10000);

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        width: "100%",
      }}
    >
      {(currentAdd?.sorteio && (
        <AnuncioSort
          tipoAnuncio={currentAdd.sorteio.tipo_rodada}
          datahora={datahora}
          count={count}
          sorteio={currentAdd.sorteio}
        />
      )) ||
        (currentAdd?.vencedores ? (
          <AnuncioVencedores vencedores={vencedores} />
        ) : (
          <AnuncioVencedores />
        ))}
    </div>
  );
};
