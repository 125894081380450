import { motion } from "framer-motion";
import React from "react";
import * as V from "../../pages/Styles/Vencedor";
import Kuadra from "../../images/kuadraPremio.svg";
import KuadraG from "../../images/kuadraPremioG.svg";
import Kina from "../../images/kinaPremio.svg";
import KinaG from "../../images/kinaPremioG.svg";
import Keno from "../../images/kenoPremio.svg";
import KenoG from "../../images/kenoPremioG.svg";
import Acumulado from "../../images/acumuladoPremio.svg";
import AcumuladoG from "../../images/acumuladoQuadro.svg";
import KuadraBlur from "../../images/kuadraBlur.svg";
import KinaBlur from "../../images/kinaBlur.svg";
import KenoBlur from "../../images/kenoBlur.svg";
import AcumuladoBlur from "../../images/kenoBlur.svg";
import useWindowDimensions from "../../hooks/useWindowSize";
import { useLocation } from "react-router";

// import {ReactComponent as Moeda} from '../../images/MoedaComprimida.svg'

type Card = {
  tipo: string;
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

// const MoedasXY = [
//   {x: 49, y: 10},
//   {x: 40, y: 10},
//   {x: 30, y: 30},
//   {x: 0, y: 1},
//   {x: 55, y: 1},
//   {x: .002, y: 1},
//   {x: .002, y: 1},
//   {x: .002, y: 1},
//   {x: .002, y: 1},
//   {x: .002, y: 1},
//   {x: .002, y: 1},
//   {x: .002, y: 1},
// ]

export const VencedoresAnimacao: React.FC<Card> = ({ tipo }) => {
  const { width } = useWindowDimensions();
  return (
    <V.ContentPremio>
      <V.ImagemPremio>
        <div
          style={{
            width: "100%",
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: 'center',
            marginLeft: 50,
            marginBottom: 50,
            margin: "0",
            padding: "0",
          }}
        >
          <motion.div
            style={{
              zIndex: "2",
              margin: "0",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "0",
            }}
          >
            {width > 700 ? (
              <img
                style={{ width: "100%", margin: "0", padding: "0" }}
                src={
                  tipo === "kuadra"
                    ? KuadraG
                    : tipo === "kina"
                    ? KinaG
                    : tipo === "keno"
                    ? KenoG
                    : AcumuladoG
                }
              />
            ) : (
              // <>
              //  <div style={{width: '100%',height: '100vh'}}>
              //   {MoedasXY.map(({x, y}, index) => (<V.Moeda key={index} x={x} y={y} />))}
              //   </div>
              //   <img style={{ width: "100%", margin: "0", padding:"0" ,position:'absolute'}}
              //             src={tipo === "kuadra" ? KuadraG :
              //                   tipo === "kina" ? KinaG :
              //                   tipo === "keno" ? KenoG :
              //     AcumuladoG} />
              // </>

              <img
                style={{ width: "100%", margin: "0 0 0 0", padding: "0" }}
                src={
                  tipo === "kuadra"
                    ? Kuadra
                    : tipo === "kina"
                    ? Kina
                    : tipo === "keno"
                    ? Keno
                    : Acumulado
                }
              />
            )}
          </motion.div>
          {width < 700 && (
            <motion.div
              style={{
                position: "absolute",
                zIndex: "1",
                margin: "0",
                padding: "0",
              }}
            >
              <img
                style={{ width: "100%", margin: "0", padding: "0" }}
                src={
                  tipo === "kuadra"
                    ? KuadraBlur
                    : tipo === "kina"
                    ? KinaBlur
                    : tipo === "keno"
                    ? KenoBlur
                    : AcumuladoBlur
                }
              />
            </motion.div>
          )}
        </div>
      </V.ImagemPremio>
    </V.ContentPremio>
  );
};
