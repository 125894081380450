/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import * as S from "../componentsStyle/StyleModalQRCODE";
import useWindowSize from "../hooks/useWindowSize";
import { GetMinhasCartelas } from "../WebRequests/requestsJson";
import { webSocketSorteioUsuario } from "../WebRequests/requestWebSocket";

const ModalQRCODE: React.FC<{}> = ({}) => {
  const [dataHistory, setdataHistory] = useState("");
  const [valor, setValor] = useState<string>("");
  const [data, setData] = React.useState("");
  const [valorWebSocket, setvalorWebSocket] = useState("");
  const [loading, setLoading] = useState(false);
  const { width, height } = useWindowSize();
  const largura = width;
  const altura = height / 2;
  const [bilhete, setBilhete] = useState<string>("");
  const [codigo, setCodigo] = useState<string>("");
  const history = useHistory();
  const { getWebSocket, lastMessage } = webSocketSorteioUsuario(valorWebSocket);
  function UpdateValue(valorString: string) {
    const pule = getParameterByName("pule", valorString);
    const bilhete = getParameterByName("bilhete", valorString);
    if (pule != "null") {
      setData(pule);
    } else if (bilhete != "null") {
      setData(bilhete);
    } else {
      alert("Não foi possível ler");
    }
  }
  function getParameterByName(name: string, url = window.location.href) {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return "null";
    if (!results[2]) return "";
    const numero = decodeURIComponent(results[2].replace(/\+/g, " "));
    return numero;
  }
  useEffect(() => {
    formatMensage(data);
  }, [lastMessage]);
  //validação de bilhetes
  const validacaoBilhete = async (bilhete: string) => {
    const { data, error } = await GetMinhasCartelas(bilhete);
    setLoading(false);
    if (data) {
      window.location.href = "/sorteiousuario?codigo=" + bilhete;
    } else toast.error("Bilhete não válido.");
  };

  //validacao pule
  async function formatMensage(mens: any) {
    //const mensage = await JSON.parse(lastMessage?.data);
    ("");
    if (valorWebSocket) {
      const mensage = await JSON.parse(lastMessage?.data);
      setLoading(false);
      mensage.code == "404"
        ? toast.error(mensage.message)
        : (toast.success("Carregando...."),
          (window.location.href = "/sorteiousuario?codigo=" + data));
    }
  }
  //validação pule
  const validacaoPule = (pule: string) => {
    setLoading(true);
    getWebSocket();
    formatMensage(lastMessage);
  };
  function buttonSend() {
    if (data.length == 10) {
      validacaoBilhete(data);
    } else if (data.length == 9) {
      setvalorWebSocket(data);
      validacaoPule(data);
    } else {
      toast.error("Pule não válida.");
    }
  }

  return (
    <>
      <S.ConteinerCamera
        style={{
          width: "100%",
          margin: "1rem 0 1rem",
        }}
      >
        <S.ContainerComponents className="tiago">
          <div
            style={{
              width: "95%",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              maxWidth: "20rem",
              margin: "0 0 1rem",
            }}
          >
            <BarcodeScannerComponent
              width={"width: max(100% , 20rem)"}
              height={"250px"}
              onUpdate={(err, valor) => {
                if (valor) {
                  const valorString = valor.toString();
                  UpdateValue(valorString);
                } else setValor("Procurando...");
              }}
            />
          </div>

          <S.ContainerModal>
            <S.TextBilhete> OU DIGITE O NÚMERO DO BILHETE </S.TextBilhete>
            <S.ContainerInput>
              <S.Input
                onChange={(e) => {
                  setData(e.currentTarget.value);
                  setdataHistory(e.currentTarget.value);
                }}
                value={data}
                minLength={1}
                type="tel"
                placeholder="DIGITE O NÚMERO"
              />
              {loading ? (
                <S.ButtonSend onClick={() => buttonSend()}>
                  <S.SpinnerIcon />
                </S.ButtonSend>
              ) : (
                <S.ButtonSend onClick={() => buttonSend()}>
                  {"CONFIRMAR"}
                </S.ButtonSend>
              )}
            </S.ContainerInput>

            {/* <S.Input type='number' onChange={() => getParameterByName("bilhete",data)?.toString()}  minLength={1} placeholder='Ou digite o número'/> */}
          </S.ContainerModal>
          {/* <S.Input required minLength={1} type='number' pattern='\d*' name='Buscar sorteio' placeholder='Buscar sorteio' onChange={e => {setQuery(e.target.value)}} /> */}
        </S.ContainerComponents>
      </S.ConteinerCamera>
    </>
  );
};

export default ModalQRCODE;
