import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "../../App.css";
import CardMeuSorteio from "../../components/CardMeuSorteio";
import CheckNetwork from "../../components/CheckNetwork";
import Header from "../../components/Header";
import ModalQrCodeComponent from "../../components/ModalQrCodeComponent";
import NomeServidor from "../../components/NomeServidor";
import getNext, { sortList } from "../../hooks/getNext";
import { getAllTickets, getTicketRequest, getTicketRequestPule, remove, reset, updateSorteio, updateStatusSorteio } from "../../store/modules/bilhete/actions";
import { GetDataHoraServidor } from "../../WebRequests/requestsJson";
import { webSocketSorteioUsuario } from "../../WebRequests/requestWebSocket";
import * as S from "../Styles/SorteioUsuarioStyle";

interface IExpandedList {
  key: string;
  isExpanded: boolean;
}

export function SorteioUsuario() {
  const history = useHistory();
  const myRef = useRef<null | HTMLDivElement>(null);
  const [sorteios, setSorteios] = useState<ISorteio[]>();
  const [dataHora, setDataHora] = useState("");
  const [pule, setPule] = useState<string | null>("");
  const [bilheteRequest, setBilheteRequest] = useState<IBilheteRequest[]>([]);
  const dispatch = useDispatch();
  const [codigoDigitado, setcodigoDigitado] = useState("");
  const [codigoBusca, setcodigoBusca] = useState("");
  const [valorWebSocket, setvalorWebSocket] = useState("");
  const [openCamera, setOpenCamera] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [listExpanded, setListExpanded] = useState<IExpandedList[]>([]);
  const [requestMessageSocket, setRequestMessageSocket] = useState<string[]>(
    []
  );
  const [connectServer, setConnectServer] = useState(true);
  const [bilhetesOrdenados, setBilhetesOrdenados] =
    useState<IBilheteSorteio[]>();

  const bilhetes: IBilheteSorteio[] = useSelector(
    (state: any) => state.bilhetes.lista
  );

  const loading: boolean = useSelector((state: any) => state.bilhetes.loading);

  const loadingRefresh: boolean = useSelector(
    (state: any) => state.bilhetes.loadingRefresh
  );

  if (bilhetes === undefined) {
    dispatch(reset());
  }

  const mensagem = { type: "sorteio_ids", values: "1265" };
  const handleSendMessage = useCallback(
    () => sendJsonMessage(JSON.stringify(mensagem)),
    []
  );

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let codigoQuery = useQuery().get("codigo");

  const { getWebSocket, lastMessage, readyState, sendJsonMessage } =
    webSocketSorteioUsuario(codigoBusca ? codigoBusca : codigoQuery);

  useEffect(() => {
    if (!codigoQuery && codigoBusca == "") {
      if (bilhetes.length > 0) {
        dispatch(getAllTickets(bilhetes));
      }
    }
  }, []);

  useEffect(() => {
    console.log(readyState);
    if (readyState == 1 || readyState == 0) {
      setConnectServer(true);
    } else {
      setConnectServer(false);
    }
  }, [readyState]);

  useEffect(() => {
    if (bilhetes.length > 0) {
      if (!codigoQuery && codigoBusca == "") {
        setcodigoBusca(getNext(bilhetes));
      } else if (codigoQuery) {
        const result = bilhetes.filter((item) => item.hash == codigoQuery);
        if (result.length > 0) {
          setcodigoBusca(getNext(bilhetes));
        }
      }
      setBilhetesOrdenados(sortList(bilhetes));
    } else {
      setBilhetesOrdenados([]);
    }
  }, [bilhetes]);

  useEffect(() => {
    createListExpanded();
  }, [bilhetesOrdenados]);

  useEffect(() => {
    async function fetch() {
      const response = await GetDataHoraServidor();
      setDataHora(response?.data?.data_hora);
    }
    fetch();
  }, []);

  useEffect(() => {
    formatMensage();
  }, [lastMessage]);

  const createListExpanded = () => {
    setListExpanded([]);
    if (bilhetesOrdenados) {
      for (let item of bilhetesOrdenados) {
        setListExpanded((prevState) => [
          ...prevState,
          {
            key: item.hash,
            isExpanded: true,
          },
        ]);
      }
    }
  };

  async function formatMensage() {
    if (!lastMessage) {
      return;
    }
    try {
      const mensage = await JSON.parse(lastMessage?.data);

      if (mensage.code == "404") {
        toast.error(mensage.message);

        return;
      } else {
        //toast.success("Carregando....");
      }

      // const requestData = {
      //   bilhetes: mensage?.bilhetes,
      //   sorteios: mensage?.sorteios,
      // };

      //dispatch(getTicketRequestPule(requestData));
      if (mensage?.type == "update_sorteio") {
        dispatch(updateSorteio(mensage?.sorteio));
      } else if (mensage?.type != "sorteio") {
        dispatch(getTicketRequestPule(mensage?.bilhetes));
      }

      if (mensage.type == "sorteio") {
        dispatch(updateStatusSorteio(mensage?.id_sorteio));
      }

      setBilheteRequest(mensage?.bilhetes);
      setSorteios(mensage?.sorteios);

      if (!dataHora) {
        setDataHora(mensage?.datahora);
      }
    } catch (error: any) {}

    //handleSendMessage();
  }

  function ValicacaoCampo() {
    if (codigoDigitado.length == 10) {
      dispatch(getTicketRequest(codigoDigitado));
    } else if (codigoDigitado.length == 9) {
      setvalorWebSocket(codigoDigitado);
      validacaoPule();
      setcodigoBusca(codigoDigitado);
    } else {
      toast.error("Pule não válida.");
    }
  }

  function validacaoPule() {
    codigoQuery = codigoDigitado;
    getWebSocket();
    formatMensage();
  }

  const handleLessOrMore = (idBilhete: any) => {
    const list = [];
    for (let item of listExpanded) {
      list.push(item);
    }
    const index = list.findIndex((item: any) => item.key == idBilhete);
    list[index].isExpanded = !listExpanded[index].isExpanded;
    setListExpanded(list);
  };

  const handleRemove = (id: any, index: any) => {
    if (!listExpanded[index].isExpanded) {
      setListExpanded([]);
      dispatch(remove(id));
      return;
    }

    const list = [];
    for (let item of listExpanded) {
      list.push(item);
    }
    list[index].isExpanded = false;
    setListExpanded(list);

    setTimeout(function () {
      setListExpanded([]);
      dispatch(remove(id));
    }, 350);

    //createListExpanded();
  };

  return (
    <S.Container>
      <Header tipo="menu" setModal={() => setOpenCamera(!openCamera)} />

      <S.TittleWhite>{"MEUS SORTEIOS"}</S.TittleWhite>
      <S.ContainerTittleStar onClick={() => history.push("/vencedores")}>
        <S.ButtonStar>
          <S.StarIcon />
        </S.ButtonStar>
        <S.TittleWhite
          style={{ fontSize: "1.5rem", margin: "0", marginLeft: "0.2rem" }}
        >
          {"VENCEDORES ANTERIORES"}
        </S.TittleWhite>
      </S.ContainerTittleStar>
      {loadingRefresh ? (
        <>
          <S.ContainerSkeleton>
            <S.HeaderSkeleton />
            <S.ContentSkeleton />
          </S.ContainerSkeleton>
        </>
      ) : (
        <>
          {bilhetesOrdenados &&
          bilhetesOrdenados.length > 0 &&
          listExpanded.length > 0 ? (
            <>
              {bilhetesOrdenados.map((item, index) => (
                <>
                  {listExpanded[index] != undefined && (
                    <S.ContainerSorteio
                      key={index}
                      className={
                        listExpanded[index].isExpanded ? "active" : "inative"
                      }
                      isExpanded={listExpanded[index].isExpanded}
                    >
                      <S.HeaderSorteio
                        isExpanded={listExpanded[index].isExpanded}
                      >
                        <button>
                          <S.TrashIcon
                            onClick={() => handleRemove(item.hash, index)}
                          />
                        </button>
                        <span>{"BILHETE: " + item.hash}</span>
                        <button
                          className="right"
                          onClick={() => handleLessOrMore(item.hash)}
                        >
                          <S.ExpandIcon className="expand" />
                        </button>
                      </S.HeaderSorteio>
                      <S.ContentSorteio
                        isExpanded={listExpanded[index].isExpanded}
                      >
                        <CardMeuSorteio
                          sorteio={item.sorteio}
                          dataServidor={dataHora}
                          dataPartida={item.sorteio.data_partida}
                          bilheteRequest={item.hash}
                          cartelas={item.cartelas}
                          vencedora={item.vencedora}
                        />
                      </S.ContentSorteio>
                    </S.ContainerSorteio>
                  )}
                </>
              ))}
            </>
          ) : (
            <S.ContainerSemjogo>
              <div>Caso possua bilhete</div>
              <S.ContainerInput>
                <S.Input
                  onChange={(e) => {
                    setcodigoDigitado(e.currentTarget.value);
                  }}
                  type="number"
                  placeholder="DIGITE O CÓDIGO"
                />
                {loading ? (
                  <S.ButtonGreen
                    style={{
                      width: "30%",
                      height: "3.5rem",
                      marginTop: "0rem",
                    }}
                    disabled
                  >
                    <S.SpinnerIcon />
                  </S.ButtonGreen>
                ) : (
                  <S.ButtonGreen
                    onClick={() => ValicacaoCampo()}
                    style={{
                      width: "30%",
                      height: "3.5rem",
                      marginTop: "0rem",
                    }}
                  >
                    Confirmar
                  </S.ButtonGreen>
                )}
              </S.ContainerInput>

              <div style={{ marginTop: "2rem" }}>Ou acesse</div>
              <S.ButtonGreen onClick={() => history.push("/comprar")}>
                Pré-Compra
              </S.ButtonGreen>
            </S.ContainerSemjogo>
          )}
        </>
      )}

      <ModalQrCodeComponent
        openCam={openCamera}
        OpenCamera={() => setOpenCamera(!openCamera)}
      />

      <NomeServidor />
      <CheckNetwork connectServer={connectServer} />
    </S.Container>
  );
}

export default SorteioUsuario;
