import React, { useState, useEffect } from 'react';

// import { Container } from './styles';

import ReactHowler from 'react-howler';

interface IAudioHome {
    type: number;
    //1 - faltam 2 minutos
    //2 - falta 1 minuto
    //3 - faltam 30 segundos
    //4 - doacão encerrada
}



const AudioHome: React.FC<IAudioHome> = ({ type }) => {
    const [isPlay, setIsPlay] = useState(true);
    const [src, setSrc] = useState<string>();
    const handleOnEnd = () => {
        setIsPlay(false)
    }    
    
    useEffect(() => {
        console.log(type);
        switch (type) {
            case 1:
                setSrc("/assets/audios/faltam_dois_minutos.mp3");
                break;
            case 2:
                setSrc("/assets/audios/falta_um_minuto.mp3");
                break;
            case 3:
                setSrc("/assets/audios/faltam_trinta_segundos.mp3");
                break;
            case 4:
                setSrc("/assets/audios/doacao_encerrada.mp3");
                break;
            default:
                return;
        }
    }, [type])
    return (
        <>
            {src  && (
                <ReactHowler
                    key={type}
                    src={src}
                    playing={isPlay}
                    volume={0.4}
                    loop={false}
                    onEnd={handleOnEnd}
                />
            )}
        </>
    )

}

export default AudioHome;

