import styled from "styled-components";
import { ArrowDropDown } from "@styled-icons/material/ArrowDropDown";
import { ArrowDropUp } from "@styled-icons/material/ArrowDropUp";

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #000000;
`;

export const ImgBackground = styled.img`
  margin-top: 2rem;
  height: 20rem;
  max-width: 400px;
  position: absolute;
  z-index: 5;
`;

export const CelContainerCut = styled.div`
  width: 70%;
  max-width: 375px;
  height: 50vh;
  max-height: 812px;
  margin-top: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  border: 0.2rem solid #7be548;
  border-radius: 3.5rem;
`;

export const TitleCel = styled.h3`
  font-size: 1.5rem;
  font-weight: 500;
  font-style: italic;
  text-align: center;
  color: #ffffff;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  span {
    width: 100%;
  }
  span.title {
    font-size: 1.6rem;
  }
  span.nameInsta {
    font-size: 1.1rem;
  }
`;

export const TextCel = styled.p`
  margin: 0;
  color: #ffffff;
  font-size: 1rem;
  margin: 0.5rem 0;
`;

export const CelContainer = styled.div`
  width: 75%;
  max-width: 375px;
  height: 65vh;
  max-height: 812px;
  margin-top: 14rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  position: relative;
  border: 0.2rem solid #7be548;
  border-radius: 3.5rem;
`;

export const CelTopContainer = styled.div`
  width: 85%;
  height: 4rem;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
`;

export const CelBallTop = styled.div`
  width: 0.7rem;
  height: 0.7rem;
  border: 0.1rem solid #7be548;
  border-radius: 50%;
  position: absolute;
  left: 0;
`;

export const CelBallTop2 = styled(CelBallTop)`
  left: 1rem;
`;

export const CelSoundTop = styled.div`
  width: 5rem;
  height: 0.7rem;
  border: 0.1rem solid #7be548;
  border-radius: 1rem;
`;

export const CelBody = styled.div`
  width: 90%;
  height: calc(100% - 8.5rem);
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const CelBodyContent = styled.div`
  height: 85%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const CelIframeInsta = styled.iframe`
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
`;

export const CelBtn = styled.div`
  width: 3rem;
  height: 3rem;
  margin: 0.5rem 0;
  border: 0.1rem solid #7be548;
  border-radius: 50%;
`;

export const Input = styled.input`
  border-radius: 2rem;
  height: 2.2rem !important;
  width: 13rem;
  outline: none;
  padding: 0.1rem 1rem;
  font-weight: 400;
  font-size: 1.1rem;
  text-align: center;
`;

export const Navigation = styled.div`
  padding: 0.2rem 2rem;
  margin: 0.3rem 0;
  background: #7be548;
  color: #000000;
  font-weight: bold;
  border-radius: 1rem;
`;

export const ArrowDropDownIcon = styled(ArrowDropDown)`
  height: 1rem;
  width: 1rem;
`;

export const ArrowDropUpIcon = styled(ArrowDropUp)`
  height: 1rem;
  width: 1rem;
`;
