import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as Style from "../componentsStyle/SearchGroupStyle";
import { useHistory } from "react-router";

interface SearchGroupProps {
  tv?: boolean;
  useQueryTVParams?: string;
  width?: string;
}

const SearchGroup: React.FC<SearchGroupProps> = ({
  tv,
  useQueryTVParams,
  width,
}) => {
  const [query, setQuery] = useState("");
  const history = useHistory();

  function goToLink() {
    history.push(tv ? `/${query}?tv=${useQueryTVParams}` : `/${query}`);
  }
  return (
    <>
      {!tv && (
        <Style.FormContainer width={width} isTv={tv ? tv : false}>
          <Style.Input
            required
            minLength={1}
            maxLength={10}
            type="number"
            pattern="\d*"
            name="Buscar sorteios"
            placeholder="Buscar sorteios"
            onKeyPress={(ev) => {
              if (ev.key === "Enter" || ev.key === "OK") {
                goToLink();
              }
            }}
            onChange={(e) => {
              setQuery(e.target.value.replace(" " || "-", ""));
            }}
          />
          <Style.LinkContainer>
            <Link to={tv ? `/${query}?tv=${useQueryTVParams}` : `/${query}`}>
              <Style.SearchIcon style={{ color: "white" }} />
            </Link>
          </Style.LinkContainer>
        </Style.FormContainer>
      )
      }
    </>
  );
};

export default SearchGroup;
