export const PlayAudio = (
    audio: AudioBufferSourceNode[],
    sorteio?: IPartida,
    turno?: number,
    TurnoAdd?: (val:number) => void,
    mute?: boolean,
    ) => {
    if (audio.length > 0 && mute){
        if(sorteio && turno && TurnoAdd && turno < sorteio?.bolas_sorteadas.length - 1){
          if (audio[turno+1] != undefined){
            audio[turno+1].start(0)
            if(turno === sorteio?.bolas_sorteadas.length -1){
                TurnoAdd(turno + 1)
            }
          }
          if (sorteio  && sorteio?.bola_kuadra === sorteio?.bolas_sorteadas[turno + 1]) {
            setTimeout(() => {
                audio[sorteio?.bolas_sorteadas.length].start(0)
            }, 1200);
          }
          if (sorteio  && sorteio?.bola_kina === sorteio?.bolas_sorteadas[turno + 1]) {
            setTimeout(() => {
                audio[sorteio?.bolas_sorteadas.length + 1].start(0)
            }, 1200);
          }
          if (sorteio  && sorteio?.bola_keno === sorteio?.bolas_sorteadas[turno + 1]) {
            setTimeout(() => {
              if(sorteio.numero_bolas_acumulado >= sorteio.bolas_sorteadas.length) audio[sorteio?.bolas_sorteadas.length + 3].start(0)
              else audio[sorteio?.bolas_sorteadas.length + 2].start(0)
            }, 1200);
          }
        }
      }
}

export default PlayAudio;