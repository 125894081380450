/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import "react-perfect-scrollbar/dist/css/styles.css";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PersistGate } from "redux-persist/integration/react";
import "./App.css";
import { ModalConfirmacaoAudio } from "./components/ModalConfirmarAudio";
import Comprar from "./pages/Comprar/Comprar";
import EscolherSorteios from "./pages/Comprar/EscolherSorteios/EscolherSorteios";
// Pages 👇🏼👇🏼
import Game from "./pages/Game/Game";
import Instagran from "./pages/Instagram";
import MeuSorteio from "./pages/MeuSorteio/MeuSorteio";
import PaginaDosVencedores from "./pages/MeuSorteio/PaginaDosVencedores";
import SorteioNaoAconteceu from "./pages/MeuSorteio/SorteioNaoAconteceu";
import SorteioUsuario from "./pages/MeuSorteio/SorteioUsuario";
import { persistor, store } from "./store";
import { GetTitleServer } from "./WebRequests/requestsJson";

function App() {
  const [isModalVisible, setIsModalVisible] = useState(true);

  useEffect(() => {
    async function fetch() {
      const title = await GetTitleServer();
      if (title.data) {
        document.title = title.data.data.nome_server;
      }
    }
    fetch();
  }, []);

  function handleCloseModal() {
    setIsModalVisible(false);
  }

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Switch>
          <Route path="/comprar" exact component={Comprar} />
          <Route path="/comprar/sorteios" component={EscolherSorteios} />
          <Route path="/meusorteio" component={MeuSorteio} />
          <Route path="/sorteiousuario" component={SorteioUsuario} />
          <Route path="/vencedores" exact component={PaginaDosVencedores} />
          <Route path="/instagram" exact component={Instagran} />
          <Route
            path="/sorteionaoaconteceu"
            exact
            component={SorteioNaoAconteceu}
          />
          {/* <Route path="/ComprarLista" component={ComprarLista} /> */}
          <Route path="/:partida" exact component={Game} />
          <Route
            exact
            path="/"
            component={MeuSorteio}
            Redirect
            to="/meusorteio"
          />
        </Switch>
        <ModalConfirmacaoAudio
          visible={isModalVisible}
          onRequestClose={handleCloseModal}
          onConfirm={handleCloseModal}
        />
        <ToastContainer autoClose={3000} />
      </PersistGate>
    </Provider>
  );
}

export default App;
