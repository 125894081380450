import { parseISO, isBefore } from 'date-fns';


// Retorna se verdadeiro se o valor da cartela é o antecipado
export const isAnticipated = (sorteio: ISorteio) => {
  const dataAntecipado = parseISO(sorteio.hora_antecipado);
  const dataAtual = new Date();
  return isBefore(dataAtual, dataAntecipado);
}


// Fazer operações matematicas com valor retornado
export const currentValue = (sorteio: ISorteio) => {
  const dataAntecipado = parseISO(sorteio.hora_antecipado);
  const dataAtual = new Date();
  const antecipado = isBefore(dataAtual, dataAntecipado);

  if (antecipado) {
    return sorteio.valor_antecipado;
  } else {
    return sorteio.valor_cartela;
  }
}

export const currentValueFormated = (sorteio: ISorteio) => {
  const dataAntecipado = parseISO(sorteio.hora_antecipado);
  const dataAtual = new Date();
  const antecipado = isBefore(dataAtual, dataAntecipado);
  if (antecipado) {
    return formatValueString(parseFloat(sorteio.valor_antecipado));
  } else {
    return formatValueString(parseFloat(sorteio.valor_cartela));
  }
}

const formatValueString = (value: number) => {
  //const valueInt: number = +value;
  return value.toLocaleString("pt-br", { minimumFractionDigits: 2 });
};