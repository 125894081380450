import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "../../App.css";
import CheckNetwork from "../../components/CheckNetwork";
import Header from "../../components/Header";
import NomeServidor from "../../components/NomeServidor";
import TabelaWin from "../../components/PaginaDosVencedores/TabelaWin";
import { GetPaginaVencedores, GetPaginaVencedoresBuscar } from "../../WebRequests/requestsJson";
import * as S from "../Styles/PaginaDosVencedores";

interface IExpandedList {
  key: string;
  isExpanded: boolean;
}

export function PaginaDosVencedores() {
  const history = useHistory();
  const myRef = useRef<null | HTMLDivElement>(null);
  const [sorteios, setSorteios] = useState<ISorteio[]>();
  const [dataHora, setDataHora] = useState("");
  const [pule, setPule] = useState<string | null>("");
  const [bilheteRequest, setBilheteRequest] = useState<IBilheteRequest[]>([]);
  const dispatch = useDispatch();
  const [codigoDigitado, setcodigoDigitado] = useState("");
  const [codigoBusca, setcodigoBusca] = useState("");
  const [valorWebSocket, setvalorWebSocket] = useState("");
  const [openCamera, setOpenCamera] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const [listExpanded, setListExpanded] = useState<IExpandedList[]>([]);
  const [requestMessageSocket, setRequestMessageSocket] = useState<string[]>(
    []
  );
  const [Vencedores, setVencedores] = useState<IVencedores>();

  const [sorteioBuscar, setsorteioBuscar] = useState<number>();
  const [click, setclick] = useState(false);
  useEffect(() => {
    vencedores();
  }, []);

  const vencedores = async () => {
    const { data, error } = await GetPaginaVencedores();
    if (data) {
      setVencedores(data.data);
    } else toast.error("Vencedores não encontrados, tente novamente.");
  };

  const vencedoresBuscar = async (sorteioNumero: number | undefined) => {
    const { data, error } = await GetPaginaVencedoresBuscar(sorteioNumero);
    if (data) {
      if (data.data.sorteios.length == 0) {
        toast.error("Vencedores não encontrados, tente novamente.");
        vencedores();
      }
      setVencedores(data.data);
    } else toast.error("Vencedores não encontrados, tente novamente.");

    setclick(false);
  };

  return (
    <S.Container>
      <Header tipo="limpo" setModal={() => setOpenCamera(!openCamera)} />
      <S.TittleWhite style={{ fontSize: "2rem", width: "100%" }}>
        {"VENCEDORES ANTERIORES"}
      </S.TittleWhite>

      <S.FormContainer>
        <S.Input
          required
          minLength={1}
          maxLength={10}
          type="tel"
          name="Buscar sorteios"
          placeholder="Buscar sorteios"
          onChange={(e) =>
            setsorteioBuscar(
              Number(e.currentTarget.value.replace(" " || "-", ""))
            )
          }
        />
        <S.LinkContainer>
          <S.Button
            onClick={() => {
              vencedoresBuscar(sorteioBuscar);
              setclick(true);
            }}
          >
            <S.SearchIcon style={{ color: "white" }} />
          </S.Button>
        </S.LinkContainer>
      </S.FormContainer>

      {click && sorteioBuscar
        ? Vencedores?.sorteios
            .filter((x) => x.sorteio == sorteioBuscar)
            .map((item, index) => (
              <div key={index + item.sorteio}>
                <TabelaWin winner={item} />
              </div>
            ))
        : Vencedores?.sorteios.map((item, index) => (
            <div key={index + item.sorteio}>
              <TabelaWin winner={item} />
            </div>
          ))}
      <NomeServidor />
      <CheckNetwork />
    </S.Container>
  );
}

export default PaginaDosVencedores;
