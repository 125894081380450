import styled from "styled-components";
import PerfectScrollbar from "react-perfect-scrollbar";
import { ArrowIosBack } from "@styled-icons/evaicons-solid/ArrowIosBack";
import { Search } from "@styled-icons/boxicons-regular/Search";
import { ArrowIosBackOutline } from "@styled-icons/evaicons-outline/ArrowIosBackOutline";
import { ShoppingCart } from "@styled-icons/material-rounded/ShoppingCart";
import { Home } from "@styled-icons/boxicons-solid/Home";
import { Trash } from "@styled-icons/foundation/Trash";

export const Container = styled.div`
  width: 100%;
  background: radial-gradient(
    116.41% 62.81% at 50% 50%,
    #8f94ce 0%,
    #3f408a 100%
  );
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-bottom: 6rem;
`;

export const LogoContainer = styled.div<{ tipo: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2.5rem;
  width: ${(props) =>
    props.tipo === "limpo" ||
    props.tipo === "limpoHome" ||
    props.tipo === "contagemRegressiva"
      ? "calc(100% - 6.4rem)"
      : "30%"};
  ${(props) =>
    (props.tipo === "limpo" || props.tipo === "contagemRegressiva") &&
    "margin-right: 3.2rem;"}
  ${(props) => props.tipo === "limpoHome" && "margin-right: 3.2rem;"}
  ${(props) => props.tipo === "menuMain" && "width: 30%;padding-left:2rem;"}
`;

export const LogoContainerSorteios = styled.div`
  display: flex;
  justify-content: center;
  width: 76%;
  margin-top: 2.5rem;
`;

export const ContainerCabecalho = styled.div`
  height: 6rem;
  width: 100%;
  display: flex;
  align-items: center;
  background: radial-gradient(
    79.29% 79.29% at 49.87% 45.36%,
    #1b10b1 0%,
    #111040 100%
  );
  justify-content: space-between;
  border-bottom: 3px solid #fff;
  margin-bottom: 2rem;
`;
export const ContainerLeft = styled.button`
  border: none;
  border-right: solid 1px white;
  display: flex;
  width: 3.2rem;
  height: 100%;
  background: radial-gradient(
    79.29% 79.29% at 49.87% 45.36%,
    #1e1a59 0%,
    #080720 100%
  );
  align-items: center;
  justify-content: center;
  text-align: center;
  svg {
    color: #fff;
    font-size: 1.7rem;
  }
`;

export const ContainerRight = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  padding-right: 0.8rem;
  height: 4rem;
  padding: 1rem 1rem 0rem 1rem;
  @media (max-width: 300px) {
    padding: 1rem 1rem 0rem 0rem;
  }
`;

export const ContainerRightSorteios = styled.div`
  display: flex;
  flex-direction: column;
  width: 3.4rem;
  height: 100%;
  border-left: 1px solid #fff;
  background: #f7b634;
  color: #111040;
  font-size: 1.5rem;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  svg {
    color: #111040;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;

  margin-top: 0.5rem;
  button {
    line-height: 1;
    font-weight: bold;
    background: #fff;
    color: #100e36;
    height: 3.5rem;
    width: 48%;
    margin-right: 5px;
    border: none;
    border-radius: 4px;
  }
`;

export const ContainerMenu = styled.div`
  display: flex;
`;

export const Back = styled(ArrowIosBack)`
  color: white;
  height: 2rem;
`;

export const FormContainer = styled.div`
  grid-area: search;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f0d50c;
  border-radius: 5px 5px 5px 5px;
  border: none;
  width: 100%;
  padding: 3px 3px;
  height: 2.5rem;
  @media (min-width: 1024px) {
    margin-left: 5rem;
    margin-top: 1rem;
    width: 16rem;
  }
`;

export const Input = styled.input`
  width: 80%;
  height: 100%;
  letter-spacing: 0.05rem;
  padding-left: 0.5rem;
  border-radius: 5px;
  border: none;
  outline: none;
  font-size: 1rem;
`;

export const LinkContainer = styled.div`
  height: 100%;
  font-size: 2rem;

  border: none;
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const ContentMeio = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: auto;
  height: calc(100% - 12rem);
`;

export const Title = styled.div`
  height: 3rem;
  width: 90%;
  background: none;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  font-weight: bold;
  font-size: 1.7rem;
  color: #fff;
  text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.81);
  @media (max-width: 354px) {
    font-size: 1.5rem;
  }
  @media (max-width: 313px) {
    font-size: 1.1rem;
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: 1.5rem;
  font-weight: 900;
  width: 90%;
  height: 10rem;
  border-radius: 5px;
  border: solid white 3px;
  background: radial-gradient(
    79.29% 79.29% at 49.87% 45.36%,
    #1e1a59 0%,
    #080720 100%
  );
  cursor: pointer;
  svg {
    max-width: 100%;
  }

  @media (max-width: 290px) {
    font-size: 1.2rem;
  }
`;

export const TitleBox = styled.div`
  font-size: 1.5rem;
  color: #fff;
  margin-top: 1rem;
  text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.81);
  font-weight: bold;
`;

// export const Text1Box = styled.div`
//  align-self: center;
//     justify-self: center;
//     color:#f0d50c;
// `;

export const Text2Box = styled.div`
  color: white;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
`;

export const Box2 = styled(Box)`
  background: linear-gradient(
      89.48deg,
      #795205 -9.2%,
      #b88918 10.54%,
      #e2bd31 27.61%,
      #fefc37 45.75%,
      #edc228 59.63%,
      #dbb829 76.7%,
      #bb8c1b 93.24%
    ),
    linear-gradient(
      89.48deg,
      #978a32 6.65%,
      #b2a129 27.17%,
      #c5b023 47.43%,
      #d7bf1d 69.71%,
      #bdaa2a 93.24%
    ),
    linear-gradient(
      89.48deg,
      #978a32 6.65%,
      #b2a129 27.17%,
      #c5b023 47.43%,
      #d7bf1d 69.71%,
      #bdaa2a 93.24%
    ),
    linear-gradient(
      89.48deg,
      #978a32 6.65%,
      #b2a129 27.17%,
      #c5b023 47.43%,
      #d7bf1d 69.71%,
      #bdaa2a 93.24%
    );
`;

export const Box3 = styled(Box)`
  background: linear-gradient(
    92.18deg,
    #5993d4 -8.3%,
    #afe2ff 16.91%,
    #ffedf7 46.1%,
    #fdcde1 74.65%,
    #fe7cec 96.4%
  );
`;

export const DivBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
  margin-top: 3rem;
`;

export const PurchaseResume = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 3rem;
  background: #ffffff;
  border-radius: 4px;
  width: 90%;
  margin-top: 2rem;
  padding: 5px;
  font-weight: bold;
  span {
    color: #141140;
    font-size: 1.2rem;
  }

  h5 {
    height: 100%;
    border-radius: 4px;
    border: 1px solid #707a91;
    font-size: 1.2rem;
    background: #cbcfd8;
    text-align: center;
    padding: 3px;
    font-weight: normal;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  svg {
    color: #100e36;
    font-size: 1.5rem;
  }
  .amount {
    width: 4rem;
  }
  .totalValue {
    width: 6rem;
  }
  @media (max-width: 450px) {
    span {
      font-size: 1.1rem;
    }

    h5 {
      font-size: 1.1rem;
    }
    .amount {
      width: 3rem;
    }
    .totalValue {
      width: 5rem;
    }
  }
  @media (max-width: 390px) {
    span {
      font-size: 1rem;
    }

    h5 {
      font-size: 1rem;
    }
    .amount {
      width: 3rem;
    }
    .totalValue {
      width: 4rem;
    }
  }
  @media (max-width: 330px) {
    span {
      font-size: 0.9rem;
    }

    h5 {
      font-size: 0.9rem;
    }
    .amount {
      width: 2.5rem;
    }
    .totalValue {
      width: 3.5rem;
    }
  }
  @media (max-width: 300px) {
    span {
      font-size: 0.8rem;
    }

    h5 {
      font-size: 0.8rem;
    }
    .amount {
      width: 2.5rem;
    }
    .totalValue {
      width: 3.5rem;
    }
  }
  @media (max-width: 280px) {
    span {
      font-size: 0.7rem;
    }

    h5 {
      font-size: 0.7rem;
    }
    .amount {
      width: 1.8rem;
    }
    .totalValue {
      width: 3.5rem;
    }
  }
`;

export const ButtonSend = styled.button`
  background: radial-gradient(
    79.29% 79.29% at 49.87% 45.36%,
    #23a830 0%,
    #116c1a 100%
  );
  border: 2px solid #ffff;
  border-radius: 4px;
  color: #ffff;
  text-align: center;
  margin-top: 2rem;
  height: 3rem;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  width: 100%;
  :hover {
    opacity: 0.9;
  }
`;

export const ButtonGroupFooter = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ButtonMore = styled.button`
  background: #f7b634;
  border: 2px solid #ffff;
  border-radius: 4px;
  color: #100e36;
  text-align: center;
  margin-top: 2rem;
  height: 3rem;
  font-size: 1.2rem;
  width: 100%;
  margin-right: 10px;
  font-weight: bold;
  cursor: pointer;
  :hover {
    opacity: 0.9;
  }
`;

export const Scroll = styled(PerfectScrollbar)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 45vh;
`;

export const ButtonGreen = styled.button`
  background: #147e1f;
  border: 3px solid white;
  color: white;
  margin: 0;
  line-height: 1;
  font-weight: bold;
  height: 3.5rem;
  width: 50%;
  margin-right: 5px;
  border: none;
  border-radius: 4px;
`;

export const ButtonBlack = styled.button`
  background: #02031b;
  border: 3px solid white;
  color: white;
  margin: 0;
  line-height: 1;
  font-weight: bold;
  height: 3.5rem;
  width: 48%;
  margin-right: 5px;
  border: none;
  border-radius: 4px;
`;

export const ButtonGroupGreen = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  justify-content: space-between;
  width: 70%;
  margin-top: 1.7rem;
  margin-left: 0.5rem;
  @media (max-width: 430px) {
    align-items: flex-start;
  }
  @media (max-width: 300px) {
    margin: 0rem;
  }
`;

export const ButtonGroupBlack = styled.div`
  display: flex;
  align-items: center;
  background: #02031b;
`;

export const textTopWhite = styled.div`
  font-size: 1rem;
  color: white;
  font-weight: 900;
`;

export const textTopYellow = styled.div`
  font-size: 1.3rem;
  color: #f0d50c;
  font-weight: 900;
`;

export const ContainerTexts = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 50%;
`;

export const ContainerBotCabecalho = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ContainerTopCabecalho = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const ContainerRightTop = styled.div`
  display: flex;
  width: 80%;
  justify-content: space-between;
`;

export const StyledSpinner = styled.svg`
  animation: rotate 2s linear infinite;
  margin: -25px 0 0 -25px;
  width: 25px;
  height: 25px;

  & .path {
    stroke: white;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

export const SSkeletonPulse = styled.div`
  margin-bottom: 1rem;
  border-radius: 6px;
  display: inline-block;
  height: 10rem;
  width: 90%;
  background: linear-gradient(
    -90deg,
    #f0f0f07a 0%,
    #ffffff 50%,
    #f0f0f07a 100%
  );
  background-size: 400% 400%;
  animation: pulse 1.2s ease-in-out infinite;
  @keyframes pulse {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
`;

export const SSkeletonLine = styled(SSkeletonPulse)`
  width: 5.5em;
  border-radius: 5px;

  &::before {
    content: "\00a0";
  }
`;

export const SearchIcon = styled(Search)`
  height: 2rem;
  width: 2rem;
`;

export const ArrowIcon = styled(ArrowIosBackOutline)`
  height: 2rem;
  width: 2rem;
`;

export const CartIcon = styled(ShoppingCart)`
  height: 2rem;
  width: 2rem;
`;

export const HomeIcon = styled(Home)`
  height: 2rem;
  width: 2rem;
`;

export const TrashIcon = styled(Trash)`
  height: 2rem;
  width: 2rem;
`;
