import {closestTo, parseISO, format, isAfter, isBefore } from 'date-fns';

const getNext = (bilhetes: IBilheteSorteio[]) => {
    
    let dates: any[] = [];
    const dateToCompare = new Date();

    for(let item of bilhetes){
        const date = parseISO(item.sorteio.data_partida);
        //console.log(date);
        if(isAfter(date, dateToCompare)){
            dates.push(date);
        }
        
    }
    if(bilhetes.length > 0 && dates.length == 0){
        return bilhetes[0].hash;
    }
    
    //console.log(dateToCompare);
    const result = closestTo(dateToCompare, dates);
    
    console.log(result);   
    if(result){
        var resultFormated = format(result, "yyyy-MM-dd'T'HH:mm:ss")   
       
    }
     const bilhete = bilhetes.filter((item) => item?.sorteio?.data_partida === resultFormated?.toString());
    //console.log(bilhete);
    return bilhete[0].hash;
   
 
}

export const sortList = (bilhetes:IBilheteSorteio[]) => {
    const futureList: IBilheteSorteio[] = [];
    const pastList: IBilheteSorteio[] = [];
    const finalList: IBilheteSorteio[] = [];
   
    for (let item of bilhetes) {
        if (!isBefore(new Date(), parseISO(item.data_hora_sorteio))) {
          //console.log(item.data_hora_sorteio);
          pastList.push(item);
        } else {
          futureList.push(item);
        } 
      }

      pastList.sort(function (a, b) {
        var dateA = +new Date(a.data_hora_sorteio), dateB = +new Date(b.data_hora_sorteio)
        return dateA - dateB
      });
      
      futureList.sort(function (a, b) {
        var dateA = +new Date(a.data_hora_sorteio), dateB = +new Date(b.data_hora_sorteio)
        return dateA - dateB
      });

      for(let item of futureList){
        finalList.push(item);
      }
      for(let item of pastList){
        finalList.push(item);
      }

      
      return finalList;
    
}



export default getNext;