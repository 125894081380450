import { addSeconds, differenceInSeconds, subSeconds } from "date-fns";
import React, { useState } from "react";
import useInterval from "./useIntervals";
import ReplacePadStart from "../Utils/ReplacePadStart";
import AudioHome from './AudioHome';
import ReactHowler from 'react-howler';
export const timerSort = (dataSort: Date, dataServer: Date, count: number) => {

  const dSecond = differenceInSeconds(dataSort, addSeconds(dataServer, count));
  const second = dSecond % 60;
  const minute = Math.floor(dSecond / 60) % 60;
  const hour = Math.floor(Math.floor(dSecond / 60) / 60) % 24;
  const days = Math.floor(Math.floor(Math.floor(dSecond / 60) / 60) / 24);
  if (dSecond < 0) {
    //playAudio(4)
    return (`PREPARANDO`);
  } else {

    const response = `${days} dias ${ReplacePadStart(hour.toString(), 2)}:${ReplacePadStart(minute.toString(), 2)}:${ReplacePadStart(second.toString(), 2)}`;
    return (response);
  }
}

export const verifyTime = (dataSort: Date, dataServer: Date, count: number) => {

  const dSecond = differenceInSeconds(dataSort, addSeconds(dataServer, count));
  const second = dSecond % 60;
  const minute = Math.floor(dSecond / 60) % 60;
  const hour = Math.floor(Math.floor(dSecond / 60) / 60) % 24;
  const days = Math.floor(Math.floor(Math.floor(dSecond / 60) / 60) / 24);
  //const response = `${days} dias ${ReplacePadStart(hour.toString(), 2)}:${ReplacePadStart(minute.toString(), 2)}:${ReplacePadStart(second.toString(), 2)}`;
  //return (`${days} dias ${hour.toString().padStart?.(2,"0")}:${minute.toString().padStart?.(2,"0")}:${second.toString().padStart?.(2,"0")}`);
  console.log("days:" +  days);
  console.log("hour:" +  hour);
  console.log("minute:" +  minute);
  console.log("second:" +  second);

  if (days == 0 && hour.toString().padStart?.(2,"0") == "00" && ((minute == 2 && second <= 1) || (minute == 1 && second > 55))  ) {
    console.log(1);
    return 1;
  }
  if (days == 0 && hour.toString().padStart?.(2,"0") == "00" && ((minute == 1 && second <= 1) || (minute == 0 && second > 55))  ) {
    console.log(2);
    return 2;
  }
  if (days == 0 && hour.toString().padStart?.(2,"0") == "00" && minute == 0 && second <= 30 && second >= 25  ) {    
    console.log(3);
    return 3;
  }
  return 0;
} 



export const formatDate = (data: string) => {
  const d = parseInt(data.slice(8, 10));
  const m = parseInt(data.slice(5, 7));
  const y = parseInt(data.slice(0, 4));
  const h = parseInt(data.slice(11, 13));
  const M = parseInt(data.slice(14, 16));
  const s = parseInt(data.slice(17, 19));

  return new Date(y, m - 1, d, h, M, s);
}

