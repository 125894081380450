import React from "react";
import styled from "styled-components";
import { ballColors } from "../../hooks/colorBalls";
import BallC from "../../images/ballC";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: radial-gradient(
    79.29% 79.29% at 49.87% 45.36%,
    #ffffff 0%,
    #acacac 100%
  );
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  border: 0.2rem solid #000d82;

  h1 {
    position: absolute;
    font-size: 5.2rem;
    padding-bottom: 1rem;
    @media (min-width: 1024px) {
      font-size: 5.5rem;
    }
  }
`;

interface bola {
  numero: number;
}

export const Bola: React.FC<bola> = ({ numero }) => {
  return (
    <Container>
      <BallC color={ballColors(numero)} />
      <h1>{numero}</h1>
    </Container>
  );
};

export default Bola;
