import { takeLatest, call, put, all } from "redux-saga/effects";
import api from "../../../services/api";
import { getTicketSuccess, getAllTicketsSuccess, 
  getAllTicketsError, getTicketError, } from "./actions";

export type PromiseFn = (...args: any) => Promise<any>;
export type GetT<T> = T extends Promise<infer N> ? N : any;
export type GetFnResult<T extends PromiseFn> = GetT<ReturnType<T>>;

export function* getTicket({ payload }: any) {
  try {
    const { bilhete } = payload;
    var response: IBilheteSorteio[] = [];
    const res: GetFnResult<typeof api> = yield call(
      api.get,
      `api/kol/bilhete_v2/${bilhete}/`
    );
    response.push(res.data);
    yield put(getTicketSuccess(response));
  } catch (err) {
    console.log(err);
    yield put(getTicketError());
  }
}

export function* getTicketPule({ payload }: any) {
  try {
    const { bilhetes } = payload;
    var response: IBilheteSorteio[] = [];
    if (bilhetes) {
      for (let bilhete of bilhetes) {
        const res: GetFnResult<typeof api> = yield call(
          api.get,
          `api/kol/bilhete_v2/${bilhete.bilhete}/`
        );
        response.push(res.data);
      }
    }

    yield put(getTicketSuccess(response));
  } catch (err) {
    console.log(err);
    yield put(getTicketError());
  }
}


export function* getAllTicketsRequest({ payload }: any) {
  try {
    const { bilhetes } = payload;
    var response: IBilheteSorteio[] = [];
    if (bilhetes) {
      for (let bilhete of bilhetes) {
        const res: GetFnResult<typeof api> = yield call(
          api.get,
          `api/kol/bilhete_v2/${bilhete.hash}/`
        );
        response.push(res.data);
      }
    }

    yield put(getAllTicketsSuccess(response));
  } catch (err) {
    yield put(getAllTicketsError());
    console.log(err);
  }
}

export default all([
  takeLatest("@ticket/GET_TICKET_REQUEST", getTicket),
  takeLatest("@ticket/GET_TICKET_REQUEST_PULE", getTicketPule),
  takeLatest("@ticket/GET_ALL_TICKETS_REQUEST", getAllTicketsRequest),
]);
