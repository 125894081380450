/* eslint-disable @typescript-eslint/no-unused-vars */

import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import "../../App.css";
import MinhasCartelasBottom from "../../components/CartelasBottom";
import CheckNetwork from "../../components/CheckNetwork";
import { GetLogoServer } from "../../components/GetLogoServer";
import NomeServidor from "../../components/NomeServidor";
import { formatDate } from "../../hooks/formatTime";
import Alert from "../../images/alert";
import * as S from "../Styles/SorteioNaoAconteceuStyle";

export function SorteioNaoAconteceu() {
  const history = useHistory();
  const [bilhete, setBilhete] = useState<string | null>("");
  const [cartelas, setCartelas] = useState<IMinhasCartelas>();
  //const [sorteio, setSorteio] = useState<IBilhete>();
  const [isOpenMyCards, setIsOpenMyCards] = useState<boolean>(true);
  const [dataPartida, setdataPartida] = useState<string | null>();
  //const dispatch = useDispatch();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let bilheteQuery = useQuery().get("bilhete");
  let dataPartidaQuery = useQuery().get("data_partida");
  let sorteioQuery = useQuery().get("sorteio");
  let puleQuery = useQuery().get("pule");

  useEffect(() => {
    if (bilheteQuery) {
      setBilhete(bilheteQuery);
      //dispatch(getTicketRequest(bilheteQuery));
    }
    if (dataPartidaQuery) {
      setdataPartida(dataPartidaQuery);
      //redirecionamento para página de SorteioUsuario
    }
  }, [bilheteQuery, dataPartidaQuery]);
  return (
    <S.Container>
      <S.ContainerCabecalho>
        <S.ContainerLeft onClick={() => history.goBack()}>
          <S.ArrowBackIosIcon />
        </S.ContainerLeft>
        <S.LogoContainer>
          <GetLogoServer tamanho="7rem" />
        </S.LogoContainer>
      </S.ContainerCabecalho>

      <S.TittleWhite style={{ fontSize: "2rem" }}>
        {bilheteQuery ? "SORTEIO AINDA NÃO ACONTECEU" : "TENTE NOVAMENTE"}
      </S.TittleWhite>
      {bilheteQuery ? (
        <S.ContainerBox>
          <Alert />
          <S.ContainerTexts>
            <S.TitleBox>BILHETE: </S.TitleBox>
            <S.TitleBox style={{ color: "#f7b634" }}>{bilhete}</S.TitleBox>
          </S.ContainerTexts>
          <S.TitleBox>
            DATA: {dataPartida && format(formatDate(dataPartida), "dd/MM/yyyy")}
            - HORA: {dataPartida && format(formatDate(dataPartida), "HH:mm:ss")}
          </S.TitleBox>
        </S.ContainerBox>
      ) : (
        <S.ContainerBox>
          <Alert />
          <div style={{ display: "flex", flexDirection: "row" }}>
            <S.TitleBox>ERRO DURANTE </S.TitleBox>
          </div>
          <S.TitleBox>CARREGAMENTO DO BILHETE</S.TitleBox>
        </S.ContainerBox>
      )}
      <MinhasCartelasBottom
        isOpenMyCards={true}
        setIsOpenMyCards={setIsOpenMyCards}
        MinhasCartelas={cartelas}
        codigoSorteio={sorteioQuery}
        codigoBilhete={bilheteQuery}
      />
      <NomeServidor />
      <CheckNetwork />
    </S.Container>
  );
}

export default SorteioNaoAconteceu;
