import React, { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { Container, Header, Content, Footer } from '../componentsStyle/ModalConfirmacaoCompra';
import { useDispatch, useSelector } from "react-redux";
import { cleanCart, removeToCart } from "../store/modules/carrinho/actions";
import { PreCompra } from "../WebRequests/requestsJson";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

interface ModalConfirmacaoCompraProps {
    setPreCompraTxt: Dispatch<SetStateAction<string | undefined>>;
    setCodigoConfirmacao: Dispatch<SetStateAction<string | undefined>>;
    setVisibleModal: Dispatch<SetStateAction<boolean>>;
    setVisibleModalConfirm: Dispatch<SetStateAction<boolean>>;
    visibleModalConfirm: boolean;
    sorteiosNaoDisponiveis: string[] | undefined;
}


const ModalConfirmarCompra: React.FC<ModalConfirmacaoCompraProps> = ({
    setPreCompraTxt,
    setCodigoConfirmacao,
    setVisibleModal,
    setVisibleModalConfirm,
    visibleModalConfirm,
    sorteiosNaoDisponiveis,
}) => {

    const carrinho = useSelector((state: any) => state.carrinho);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        
        if (sorteiosNaoDisponiveis) {
            
            if (carrinho.length == 0) {
                setVisibleModalConfirm(false);
                if (sorteiosNaoDisponiveis) {
                    if (sorteiosNaoDisponiveis?.length == 1) {
                        toast.error("O sorteio " + sorteiosNaoDisponiveis[0] + " não está mais disponível");
                    }
                    if (sorteiosNaoDisponiveis?.length > 1) {
                        toast.error("Os sorteios " + sorteiosNaoDisponiveis.join(',') + " não estão mais disponíveis");
                    }
                }
                history.push("/comprar/sorteios?type=normal");
            }
        }


    }, [sorteiosNaoDisponiveis]);

    
    const handleSend = async () => {
        let list = [];
        if (carrinho) {
            for (let data of carrinho) {
                list.push({
                    sorteio: data.sorteio.codigo,
                    qtd_cartelas: data.quantidade,
                    pre_compra_txt: data.pre_compra_txt,
                });
            }
            const res = await PreCompra(list);

            if (res.error) {
                let listaSorteiosIndisponiveis = [];

                if (res.error.response?.data) {
                    for (let item of res.error.response?.data) {
                        listaSorteiosIndisponiveis.push(item.sorteio.id);
                    }
                }
                console.log(listaSorteiosIndisponiveis);
                dispatch(removeToCart(listaSorteiosIndisponiveis));
                toast.error("Erro ao finalizar pré compra.");
                return;
            }
            if (setPreCompraTxt) setPreCompraTxt(res.data.data.pre_compra_txt);
            if (setCodigoConfirmacao) setCodigoConfirmacao(res.data.data.codigo);

            setVisibleModalConfirm(false);
            setVisibleModal(true);
            dispatch(cleanCart());
            history.push("/comprar/sorteios?type=normal");
        }
    };

    return (
        <Container visibleModalConfirm={visibleModalConfirm}>
            <Header>
                Não foi possível finalizar a compra.
            </Header>
            <Content>
                {sorteiosNaoDisponiveis && sorteiosNaoDisponiveis.length == 1 && (
                    <>
                        <p>O sorteio {sorteiosNaoDisponiveis[0]} não está mais disponível.</p>
                        <p>Deseja prosseguir com a compra sem esse sorteio?</p>
                    </>
                )}
                {sorteiosNaoDisponiveis && sorteiosNaoDisponiveis.length > 1 && (
                    <>
                        <p>Os sorteios {sorteiosNaoDisponiveis.join(',')} não estão mais disponíveis.</p>
                        <p>Deseja prosseguir com a compra sem esses sorteios?</p>
                    </>
                )}
                
            </Content>
            <Footer>
                <button onClick={() => handleSend()}>Sim</button>
                <button onClick={() => setVisibleModalConfirm(false)}>Não</button>
            </Footer>
        </Container>
    )
}

export default ModalConfirmarCompra;