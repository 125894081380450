import axios, { AxiosError } from "axios";
import api from "../services/api";

interface ISorteiosSelecionados {
  sorteio: number;
  qtd_cartelas: number;
}

type ErrorRecord = Record<
  string,
  string | string[] | { detail: string | ErrorRecord }
>;

declare type ApiError = {
  detail?: string | ErrorRecord;
};

/* API KOL */
const API_URL_KOL = "/api/kol";

export const PreCompra = async (data: ISorteiosSelecionados[]) => {
  const res = await api
    .post(`${API_URL_KOL}/pre_compra/`, data)
    .catch((err) => err as AxiosError);

    
  if (!(res instanceof Error)) return { data: res };
  else return { error: res };
};

export const GetBilhete = async (id: string) => {
  const res = await api
    .get<IBilhete>(`${API_URL_KOL}/bilhete/${id}/`)
    .catch((err) => err as AxiosError<ApiError>);

  if (!(res instanceof Error)) return { data: res };
  else return { error: res };
};

export const GetMinhasCartelas = async (bilhete: string) => {
  //const {api} = useApi();
  const res = await api
    .get<IMinhasCartelas>(`${API_URL_KOL}/bilhete/${bilhete}/`)
    .catch((err) => err as AxiosError<ApiError>);

  if (!(res instanceof Error)) return { data: res };
  else return { error: res };
};

export const GetProximosSorteiosCompra = async () => {
  //const {api} = useApi();
  const res = await api
    .get<ISorteios>(`${API_URL_KOL}/proximossorteios/`)
    .catch((err) => err as AxiosError<ApiError>);

  if (!(res instanceof Error)) return { data: res.data };
  else return { error: res };
};

export const GetDataHoraServidor = async () => {
  const res = await api
    .get(`${API_URL_KOL}/data_hora/`)
    .catch((err) => err as AxiosError<ApiError>);

  if (!(res instanceof Error)) return { data: res.data };
  else return { error: res };
};

export const GetPaginaVencedores = async () => {
  //const {api} = useApi();
  const res = await api
    .get<IVencedores>(`${API_URL_KOL}/ultimos_ganhadores/`)
    .catch((err) => err as AxiosError<ApiError>);

  if (!(res instanceof Error)) return { data: res };
  else return { error: res };
};
export const GetPaginaVencedoresBuscar = async (sorteioNumero:Number | undefined) => {
  //const {api} = useApi();
  const res = await api
    .get<IVencedores>(`${API_URL_KOL}/ultimos_ganhadores/${sorteioNumero}/`)
    .catch((err) => err as AxiosError<ApiError>);

  if (!(res instanceof Error)) return { data: res };
  else return { error: res };
};

/****************************************************************************/

/* API MEDIA */
const API_URL_MEDIA = "/api/media";

export const GetTitleServer = async () => {
  const res = await api
    .get(`${process.env.REACT_APP_BASE_URL}/api/media/nome/`)
    .catch((err) => err as AxiosError<ApiError>);

  if (!(res instanceof Error)) return { data: res };
  else return { error: res };
};

/***************************************************************************/

/* API V1 */
const API_URL_V1 = "/api/v1";

export const GetProximosSorteios = async () => {
  //const {api} = useApi();
  const res = await api
    .get<ISorteios>(`${API_URL_V1}/proximossorteios/`)
    .catch((err) => err as AxiosError<ApiError>);

  if (!(res instanceof Error)) return { data: res.data };
  else return { error: res };
};

export const GetProximosEspeciais = async () => {
  //const {api} = useApi();
  const res = await api
    .get<ISorteios>(`${API_URL_V1}/proximos_especiais/`)
    .catch((err) => err as AxiosError<ApiError>);

  if (!(res instanceof Error)) return { data: res.data };
  else return { error: res };
};

export const GetHistorico = async () => {
  //const {api} = useApi();
  const res = await api
    .get<IVencedores[]>(`${API_URL_V1}/historico/`)
    .catch((err) => err as AxiosError<ApiError>);

  if (!(res instanceof Error)) return { data: res };
  else return { error: res };
};

export async function GetWinners() {
  //const {api} = useApi();
  const res = await api
    .get<IVencedores[]>(`${API_URL_V1}/ultimos_ganhadores/`)
    .catch((err) => err as AxiosError<ApiError>);

  if (!(res instanceof Error)) return { data: res };
  else return { error: res };
}

export const GetStatus = async (id: string | number) => {
  //const {api} = useApi();
  const res = await api
    .get<IVencedores[]>(`${API_URL_V1}/status/${id}/`)
    .catch((err) => err as AxiosError<ApiError>);

  if (!(res instanceof Error)) return { data: res };
  else return { error: res };
};

export const GetPartida = async (id:string, token?:string | null) => {
  const apiModificada = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}`,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      'Authorization': `Token ${token? token : process.env.REACT_APP_TOKEN}`
    },
  });
  
  const res = await apiModificada
  .get<IPartida>(`${API_URL_V1}/sorteio/1/${id}/`)
  .catch((err) => err as AxiosError<ApiError>);

  if (!(res instanceof Error)) return { data: res };
  else return { error: res };
};

export const GetReplay = async () => {
  //const {api} = useApi();
  const res = await api
    .get<IPartida[]>(`${API_URL_V1}/replay`)
    .catch((err) => err as AxiosError<ApiError>);

  if (!(res instanceof Error)) return { data: res };
  else return { error: res };
};

/****************************************************************************/


/* API V2 */
const API_URL_V2 = "/api/v2";

export const GetBilhetesHistorico = async (id_pos: string) => {
  //const {api} = useApi();
  const res = await api
    .get<IPartida[]>(`${API_URL_V2}/historico_bilhetes/${id_pos}/`)
    .catch((err) => err as AxiosError<ApiError>);

  if (!(res instanceof Error)) return { data: res };
  else return { error: res };
};

export const GetCardsFromBilhete = async (
  id_pos = 1,
  id_bilhete = 472629006607,
  token: string,
  sessao: string
) => {
  //const {api} = useApi();
  const res = await api
    .get<ICartelas[]>(`${API_URL_V2}/bilhete/${id_pos}/${id_bilhete}`, {
      headers: {
        Authorization: `Token ${token}`,
        sessao: `${sessao}`,
      },
    })
    .catch((err) => err as AxiosError<ApiError>);

  if (!(res instanceof Error)) return { data: res };
  else return { error: res };
};

/*****************************************************************************/


