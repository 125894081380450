import { format } from "date-fns";
import { motion } from "framer-motion";
import React from "react";
import * as StyleCard from "../../componentsStyle/CardCompraSorteio";
import { formatMoneyNotSDec } from "../../hooks/formatNumber";
import { formatDate, timerSort, verifyTime } from "../../hooks/formatTime";
import { CalculateTimeLeft } from "../../hooks/useTimer";
import * as S from "../../pages/Styles/CardSorteTv";
import AudioHome from "../../hooks/AudioHome";

type anuncio = {
  sorteio?: ISorteio;
  count: number;
  datahora: string;
  tipoAnuncio: number;
};

const AnuncioSort: React.FC<anuncio> = ({
  sorteio,
  count,
  datahora,
  tipoAnuncio,
}) => {
  const playAudio = (type: number) => {
    return <AudioHome type={type} />;
  };
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, rotate: 0, scale: 1 }}
      style={{
        width: "100%",
        height: "calc(100vh - 3rem)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {" "}
      {tipoAnuncio === 1 ? (
        <S.Title>NORMAL</S.Title>
      ) : tipoAnuncio === 2 ? (
        <S.Title>PRÓXIMO ESPECIAL</S.Title>
      ) : (
        <S.Title>PRÓXIMO SUPER ESPECIAL</S.Title>
      )}
      {sorteio && (
        <>
          <S.CardContainerTv tipo={sorteio?.tipo_rodada}>
            {sorteio && (
              <S.ContainerADS>
                <S.HeaderADS>
                  <h2>SORTEIO:</h2>
                  <div>
                    <h3>{sorteio.codigo}</h3>
                    {/* <p>
                                    {format(new Date(sortList[SortAtual]?.data_partida), 'dd/MM')+" "}
                                    <span>ÁS</span>
                                    {" "+format(new Date(sortList[SortAtual]?.data_partida), 'HH:mm')}
                                </p> */}
                  </div>
                </S.HeaderADS>
                <S.BodyADS>
                  <S.Ward tipo={1}>
                    <h4>KUADRA</h4>
                    <p>
                      <span>
                        {formatMoneyNotSDec(parseFloat(sorteio.valor_kuadra))}
                      </span>
                    </p>
                  </S.Ward>
                  <S.Ward tipo={3}>
                    <h4>KENO</h4>
                    <p>
                      <span>
                        {formatMoneyNotSDec(parseFloat(sorteio.valor_keno))}
                      </span>
                    </p>
                  </S.Ward>
                  <S.Ward tipo={2}>
                    <h4>KINA</h4>
                    <p>
                      <span>
                        {formatMoneyNotSDec(parseFloat(sorteio.valor_kina))}
                      </span>
                    </p>
                  </S.Ward>
                  <S.InfoADS>
                    <div>
                      {"data : ".toUpperCase() +
                        format(new Date(sorteio.data_partida), "dd/MM") +
                        " hora: ".toUpperCase() +
                        format(new Date(sorteio.data_partida), "HH:mm")}
                    </div>
                    <div>
                      {/* {"doações: ".toUpperCase() +
                        formatMoneyNotSDec(parseFloat(sorteio.valor_cartela))} */}
                    </div>
                  </S.InfoADS>
                </S.BodyADS>
                {datahora && (
                  <S.FooterADS>
                    {"Sorteio em: ".toUpperCase() +
                      timerSort(
                        formatDate(sorteio.data_partida),
                        formatDate(datahora),
                        count
                      )}
                    {timerSort(
                      formatDate(sorteio.data_partida),
                      formatDate(datahora),
                      count
                    ) == "PREPARANDO" && (
                      <>
                        <StyleCard.TextAnimationPoints>
                          .
                        </StyleCard.TextAnimationPoints>
                        {playAudio(4)}
                      </>
                    )}

                    {verifyTime(
                      formatDate(sorteio.data_partida),
                      formatDate(datahora),
                      count
                    ) == 1 && <>{playAudio(1)}</>}

                    {verifyTime(
                      formatDate(sorteio.data_partida),
                      formatDate(datahora),
                      count
                    ) == 2 && <>{playAudio(2)}</>}
                    {verifyTime(
                      formatDate(sorteio.data_partida),
                      formatDate(datahora),
                      count
                    ) == 3 && <>{playAudio(3)}</>}
                  </S.FooterADS>
                )}
              </S.ContainerADS>
            )}
          </S.CardContainerTv>
          <S.CardSecond tipo={sorteio?.tipo_rodada} />
          <S.CardThird tipo={sorteio?.tipo_rodada} />
        </>
      )}
    </motion.div>
  );
};

export default AnuncioSort;
