import produce from 'immer';



interface ISorteiosSelecionados {
    sorteio: ISorteio | null;
    quantidade: number | null;
}
// const INITIAL_STATE: ISorteiosSelecionados[] = [{
//     sorteio: null,
//     quantidade: null
// }];

export default function carrinho(state: ISorteiosSelecionados[] = [], action: any) {

    switch (action.type) {
        case '@cart/ADD':
            return produce(state, draft => {
                draft.push(action.payload.item);

            });

        case '@cart/CLEAN_CART':
            return produce(state, draft => {
                draft.length = 0;
            });

        case '@cart/REMOVE_TO_CART':
            return produce(state, draft => {
                const list: ISorteiosSelecionados[] = [];
                let difference = state.filter(x => !action.payload.sorteios.includes(x.sorteio?.codigo.toString()));
               
                if (difference.length > 0) {                    
                    draft.length = 0;
                    for (let item of difference) {
                        draft.push(item);
                    }
                } else {
                    draft.length = 0;
                }

            });
        case '@cart/REMOVE':
            return produce(state, draft => {
                const list: ISorteiosSelecionados[] = [];
                let difference = state.filter(x => x.sorteio?.codigo != action.payload.id);
                
                if (difference.length > 0) {                    
                    draft.length = 0;
                    for (let item of difference) {
                        draft.push(item);
                    }
                } else {
                    draft.length = 0;
                }

            });

        case '@cart/UPDATE_AMOUNT': {
            if (action.quantidade <= 0) {
                return state;
            }
            return produce(state, draft => {
                if (draft) {
                    const sorteioIndex = draft.findIndex(p => p.sorteio?.codigo === action.codigo);
                    if (sorteioIndex >= 0) {
                        draft[sorteioIndex].quantidade = draft[sorteioIndex].quantidade + action.quantidade;
                    }
                }

            })
        }

        default:
            return state;
    }


}
