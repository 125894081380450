import React, { useEffect, useState, Dispatch, SetStateAction } from "react";
import { useLocation } from "react-router";
import {
  Container,
  Header,
  Footer,
} from "../componentsStyle/ModalConfirmacaoAudio";

interface ModalConfirmacaoAudioProps {
  visible: boolean;
  onRequestClose: () => void;
  onConfirm: () => void;
}

export function ModalConfirmacaoAudio({
  visible,
  onConfirm,
  onRequestClose,
}: ModalConfirmacaoAudioProps) {
  function handleConfirm() {
    onConfirm();
    onRequestClose();
  }
  const queryTV = new URLSearchParams(useLocation().search).get("tv")
  
  return (
    <Container
      isOpen={visible && !!queryTV}
      onRequestClose={onRequestClose}
      style={{
        overlay: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "rgba(0,0,0,0.42)",
          zIndex: 5
        },
      }}
    >
      <Header>AUTORIZA A APLICAÇÃO ATIVAR O SOM DO SISTEMA?</Header>
      <Footer>
        <button onClick={handleConfirm}>SIM</button>
        <button onClick={onRequestClose}>NÃO</button>
      </Footer>
    </Container>
  );
}
