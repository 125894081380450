import { ArrowBackIos } from "@styled-icons/material/ArrowBackIos";
import styled from "styled-components";
import { ArrowIosBack } from "styled-icons/evaicons-solid";

export const Container = styled.div`
  width: 100%;
  background: radial-gradient(
    116.41% 62.81% at 50% 50%,
    #8f94ce 0%,
    #3f408a 100%
  );
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const ContainerSemjogo = styled.div`
  display: flex;
  color: black;
  font-size: 2rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 2rem;
  padding: 1rem;
`;
export const Input = styled.input`
  height: 3.5rem;
  width: 60%;
  border: none;
  letter-spacing: 0.05rem;
  border-radius: 5px;
  //border: 4px solid #19164d;
  outline: none;
  font-size: 1.3rem;
  margin-top: 0.2rem;
  text-align: center;
  font-weight: 900;
  color: #0f0f0f;
  padding: 1rem;
`;
export const ButtonRed = styled.button`
  border: solid 1px #111040;
  color: white;
  background: radial-gradient(
    79.29% 79.29% at 49.87% 45.36%,
    #f73434 0%,
    #6c1c11 100%
  );
  border-radius: 5px;
  width: calc((1 / 3) * 100%);
  height: 1.6rem;
`;
export const ContainerInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-top: 1rem;
`;
export const ButtonGreen = styled(ButtonRed)`
  background: radial-gradient(
    79.29% 79.29% at 49.87% 45.36%,
    #23a830 0%,
    #116c1a 100%
  );
  width: 80%;
  font-size: 1.5rem;
  height: 3.5rem;
  margin-top: 1rem;
`;
export const Tittle = styled.h4`
  font-size: 1.7rem;
  color: #f0d50c;
`;
export const TittleWhite = styled.h4`
  font-size: 2.5rem;
  color: white;
  text-align: center;
  text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.81);
  margin-top: 2rem;
`;

export const TitleContainer = styled.div`
  width: 100%;
  margin-top: 1rem;
  background: radial-gradient(
    79.29% 79.29% at 49.87% 45.36%,
    #1b10b1 0%,
    #111040 100%
  );
  display: flex;
  height: auto;
  justify-content: center;
  align-items: center;
  font-weight: 900;
`;

export const LogoContainerSorteios = styled.div`
  display: flex;
  justify-content: center;
  width: 80%;
`;

export const ContainerMenu = styled.div`
  display: flex;
`;

export const Back = styled(ArrowIosBack)`
  color: white;
  height: 2rem;
`;

export const FormContainer = styled.div`
  margin-right: 1rem;
  grid-area: search;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f0d50c;
  border-radius: 5px 5px 5px 5px;
  border: none;
  width: 50%;
  height: 2.5rem;
  @media (min-width: 1024px) {
    margin-left: 5rem;
    margin-top: 1rem;
    width: 16rem;
  }
`;

export const ContainerCabecalho = styled.div`
  height: 6rem;
  width: 100%;
  display: flex;
  align-items: center;
  background: radial-gradient(
    79.29% 79.29% at 49.87% 45.36%,
    #1b10b1 0%,
    #111040 100%
  );
  justify-content: space-between;
  border-bottom: 3px solid #fff;
`;

export const ContainerLeft = styled.div`
  border-right: solid 1px white;
  display: flex;
  width: 12%;
  height: 100%;
  background: radial-gradient(
    79.29% 79.29% at 49.87% 45.36%,
    #1e1a59 0%,
    #080720 100%
  );
  align-items: center;
  justify-content: center;

  svg {
    color: #fff;
    font-size: 1.7rem;
  }
`;

export const ContainerRight = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-right: 1rem;
  height: 4rem;
`;

export const LinkContainer = styled.div`
  height: 100%;
  font-size: 2rem;

  border: none;
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2.5rem;
  width: 90%;
`;

export const ContainerBox = styled.div`
  border: 3px solid white;
  background: radial-gradient(
    79.29% 79.29% at 49.87% 45.36%,
    #f9f9f9 0%,
    #9d9d9d 100%
  );
  border-radius: 10px;
  width: 90%;
  height: 17rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 1rem;
`;

export const TitleBox = styled.a`
  text-align: center;
  //-webkit-text-fill-color: white;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #100e36;
  color: white;
  font-size: 2rem;
  font-weight: 900;
`;

export const ContainerTexts = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
`;

export const ArrowBackIosIcon = styled(ArrowBackIos)`
  height: 1rem;
  width: 1rem;
`;
