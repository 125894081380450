import React, { useEffect, useState } from "react";
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import * as S from "../pages/Styles/ComprarStyle";
import { GetLogoServer } from "./GetLogoServer";
import SearchGroup from './SearchGroup';

type header = {
  tipo: "menu" | "loja" | "menuMain" | "limpo" | "limpoHome" | "contagemRegressiva";
  setModal?: () => void;
};

const Header = ({ tipo, setModal }: header) => {
  const [carrinhoQuantidade, setCarrinhoQuantidade] = useState(0);
  const carrinho = useSelector((state: any) => state.carrinho);

  useEffect(() => {
    if (carrinho.length > 0) {
      let count = 0;
      for (let item of carrinho) {
        count += item.quantidade;
        setCarrinhoQuantidade(count);
      }
    } else {
      setCarrinhoQuantidade(0);
    }
  }, [carrinho]);

  const [query, setQuery] = useState("");
  const history = useHistory();

  const logo = ({ remainingTime}: any ) => (
    <div role="timer" aria-live="assertive">
      <S.HomeIcon />
    </div>
  )
 
  return (
    <S.ContainerCabecalho>
      {tipo == "menu" ||
        tipo === "loja" ||
        tipo === "limpo" ||
        tipo === "limpoHome" ? (
        // tipo === "contagemRegressiva" ? (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            height: "100%",
          }}
        >
          {tipo !== "limpoHome" && (
            <S.ContainerLeft onClick={() => history.goBack()}>

              <S.ArrowIcon style={{ alignSelf: "center" }} />


            </S.ContainerLeft>
          )}
          {tipo === "limpoHome" || tipo === "loja" ? (     
            <S.ContainerLeft onClick={() => history.push("/")}>

              <S.HomeIcon />

            </S.ContainerLeft>
          ) : (
            <></>
          )}


        </div>
      ) : (
        <></>
      )}

      {tipo === "contagemRegressiva" && (
        <S.ContainerLeft onClick={() => history.push("/")}>
          <CountdownCircleTimer
            isPlaying
            duration={5}
            colors={'#FFF'}
            size={45}
            strokeWidth={4}
            trailColor={"#080720"}
            onComplete={() => history.push("/")}
          >
            {logo}
          </CountdownCircleTimer>
        </S.ContainerLeft>
      )}
      <S.LogoContainer tipo={tipo}>
        <GetLogoServer tamanho="7rem" />
      </S.LogoContainer>


      {tipo == "menu" || tipo == "menuMain" ? (
        <S.ContainerRight>
          <SearchGroup />
          <S.ButtonGroup>
            {tipo !== "menu" && (
              <button onClick={() => history.push("/sorteiousuario")}>
                MEUS SORTEIOS
              </button>
            )}
            {setModal && <button onClick={() => setModal()}>QRCODE</button>}
            <button
              style={{
                background: "#147e1f",
                border: "3px solid white",
                color: "white",
                margin: "0",
              }}
              onClick={() => history.push("/comprar")}
            >
              COMPRAR
            </button>
          </S.ButtonGroup>
        </S.ContainerRight>
      ) : (
        <></>
      )}
      {tipo === "loja" && (
        <>
          {carrinho.length > 0 ? (
            <S.ContainerRightSorteios
              onClick={() => history.push("/comprar/sorteios?type=confirm")}
            >
              <span>{carrinhoQuantidade}</span>
              <S.CartIcon />
            </S.ContainerRightSorteios>
          ) : (
            <S.ContainerRightSorteios>
              <span>{carrinhoQuantidade}</span>
              <S.CartIcon />
            </S.ContainerRightSorteios>
          )}
        </>
      )}
    </S.ContainerCabecalho>
  );
};

export default Header;
