import useWebSocket from "react-use-websocket";
import useInterval from "../hooks/useIntervals";

// Commons Config

export function webSocketService(token?: string) {
  if (token) {
    const socketUrl = `${process.env.REACT_APP_BASE_URL_WS}/ws/tela/${token}/connect/`;
    console.log("entrei com token tv: " + token);
    return useWebSocket(socketUrl, { share: true, reconnectInterval: 5000, reconnectAttempts: 40000, shouldReconnect: (closeEvent) => true });
  } else {
    const socketUrl = `${process.env.REACT_APP_BASE_URL_WS}/ws/tela/${process.env.REACT_APP_TOKEN}/connect/`;
    return useWebSocket(socketUrl, { share: true, reconnectInterval: 5000, reconnectAttempts: 40000, shouldReconnect: (closeEvent) => true });
  }
}

export function webSocketSorteioUsuario(codigo_pule: string | null) {
  const socketUrl = `${process.env.REACT_APP_BASE_URL_WS}/ws/${process.env.REACT_APP_TOKEN}/bilhete/${codigo_pule}/connect/`;
  return useWebSocket(socketUrl, { share: true, reconnectInterval: 1000, shouldReconnect: (closeEvent) => true });
}
