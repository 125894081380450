import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { Vencedores } from "../components/Vencedor";
import * as V from "../pages/Styles/Vencedor";
import * as S from "../style";
import Header from "./Header";
import { PremioUser } from "./PremioUser";


type TabelaVencedor = {
  cartelas: ICartelas[];
  vencedoresKeno: number[];
  vencedoresKina: number[];
  vencedoresKuadra: number[];
  acumulado: boolean;
  sorteio: IPartida;
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const TabelaVencedores = ({
  cartelas,
  vencedoresKeno,
  vencedoresKina,
  vencedoresKuadra,
  acumulado,
  sorteio
}: TabelaVencedor) => {
  const history = useHistory();

  const [tvMode, setTvmode] = useState("");
  const [TipoGanhador, setTipoGanhador] = useState<number>(1);
  const [Count, setCount] = useState(15000);
  let query = useQuery();

  let tvQuery = useQuery().get("tv");

  useEffect(() => {
    if (tvQuery) {
      setTvmode(tvQuery);
    }
  }, [tvQuery]);

  setTimeout(() => {
    if (tvMode) {
      history.push(`/?tv=${tvQuery}`);
    } else {
      history.push("/");
    }
  }, Count);

  setTimeout(() => {
    if (TipoGanhador < 3) setTipoGanhador(TipoGanhador + 1);
    else setTipoGanhador(1);
  }, Count / 3);
  return (
    <S.Container
      TVMode={tvMode}
      style={
        tvMode
          ? {
              alignItems: "center",
              background:
                "radial-gradient( 79.29% 79.29% at 49.87% 45.36%, #8f94ce 0%, #282a81 100% )",
            }
          : {
              alignItems: "flex-start",
              background:
                "radial-gradient( 79.29% 79.29% at 49.87% 45.36%, #8f94ce 0%, #282a81 100% )",
            }
      }
    >
      {!tvMode ? (
        <>
          <Header tipo="contagemRegressiva" />
          <V.ContainerVencedoresTables>
            <V.Title>MINHAS CARTELAS</V.Title>
            <PremioUser
              sorteio={sorteio} 
              vencedoresKuadra={vencedoresKuadra}
              vencedoresKina={vencedoresKina}
              vencedoresKeno={vencedoresKeno}/>
            
            <V.Title>OUTROS JOGADORES</V.Title>
            {acumulado ? (
              <Vencedores
                cartelas={cartelas}
                tipo="acumulado"
                vencedores={vencedoresKeno}
              />
            ) : (
              <Vencedores
                cartelas={cartelas}
                tipo="keno"
                vencedores={vencedoresKeno}
              />
            )}

            <Vencedores
              cartelas={cartelas}
              tipo="kina"
              vencedores={vencedoresKina}
            />
            <Vencedores
              cartelas={cartelas}
              tipo="kuadra"
              vencedores={vencedoresKuadra}
            />
          </V.ContainerVencedoresTables>
        </>
      ) : (
        <>
          <V.ContainerVencedoresTables>
            {TipoGanhador === 3 && acumulado && (
              <Vencedores
                cartelas={cartelas}
                tipo="acumulado"
                vencedores={vencedoresKeno}
              />
            )}
            {TipoGanhador === 3 && !acumulado && (
              <Vencedores
                cartelas={cartelas}
                tipo="keno"
                vencedores={vencedoresKeno}
              />
            )}

            {TipoGanhador === 2 && (
              <Vencedores
                cartelas={cartelas}
                tipo="kina"
                vencedores={vencedoresKina}
              />
            )}
            {TipoGanhador === 1 && (
              <Vencedores
                cartelas={cartelas}
                tipo="kuadra"
                vencedores={vencedoresKuadra}
              />
            )}
          </V.ContainerVencedoresTables>
        </>
      )}
    </S.Container>
  );
};
