import { format } from "date-fns";
import React, { Dispatch, SetStateAction } from "react";
import { TelegramIcon, TelegramShareButton, WhatsappIcon } from "react-share";
import { Container, Content, Header, Item } from "../componentsStyle/ShareStyle";

interface ShareProps {
  isVisible: boolean;
  setVisibleShare: Dispatch<SetStateAction<boolean>>;
  code: string;
  phone: number;
}

const Share: React.FC<ShareProps> = ({
  isVisible,
  setVisibleShare,
  code,
  phone,
}) => {
  const date = new Date();
  const MSG_SOCIAL =
    process.env.REACT_APP_ROUTE?.replaceAll("app", "").replaceAll(".", " ") +
    `%0A` +
    "Data: " +
    format(date, "dd/MM/yyyy HH:mm:ss") +
    `%0A` +
    "Código Pré-Compra: " +
    code;
  const URL = `https://api.whatsapp.com/send?phone=55${phone}&text=${MSG_SOCIAL}`;

  return (
    <Container isVisible={isVisible}>
      <Header>
        <span>Compartilhar com</span>
      </Header>
      <Content>
        <Item onClick={() => window.open(URL)}>
          {/* <WhatsappShareButton title={MSG_SOCIAL} url={URL}> */}
          <WhatsappIcon size={48} round />
          {/* </WhatsappShareButton> */}
          <span>Whatsapp</span>
        </Item>

        <Item>
          <TelegramShareButton title={MSG_SOCIAL} url={URL}>
            <TelegramIcon size={48} round />
          </TelegramShareButton>
          <span>Telegram</span>
        </Item>
      </Content>
    </Container>
  );
};

export default Share;
