import styled, { keyframes } from "styled-components";
import { Spinner } from "@styled-icons/icomoon/Spinner";

const rotate = keyframes`
    from {
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
`;

export const ConteinerCamera = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 4px;
  border-radius: 15px;
`;

export const ContainerCameraModal = styled.div`
  width: 100%;
  height: 10rem;
  display: flex;
  border-radius: 15px;
`;

export const TextBilhete = styled.text`
  color: white;
  font-size: 1.3rem;
  font-weight: 900;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 0.1rem;
`;

export const ContainerComponents = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  min-height: 20rem;
`;

export const Input = styled.input`
  height: 3.5rem;
  width: 65%;
  border: none;
  letter-spacing: 0.05rem;
  border-radius: 5px 0px 0px 5px;
  outline: none;
  font-size: 1.3rem;
  text-align: center;
  font-weight: 900;
  color: #0f0f0f;
`;

export const ContainerModal = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const ButtonSend = styled.button`
  height: 100%;
  width: 35%;
  background: radial-gradient(
    79.29% 79.29% at 49.87% 45.36%,
    #23a830 0%,
    #116c1a 100%
  );
  color: white;
  border: none;
  border: 4px solid #19164d;
  border-right: none;
  border-radius: 10px;
  font-size: 1rem;
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    animation: ${rotate} 2s linear infinite;
  }
`;

export const ContainerInput = styled.div`
  border: 4px solid #19164d;
  justify-content: space-between;

  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: white;
  border-radius: 10px;
`;

export const SpinnerIcon = styled(Spinner)`
  height: 1rem;
  width: 1rem;
`;
