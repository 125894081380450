import produce from "immer";
import { toast } from "react-toastify";

const lista: IBilheteSorteio[] = [];

const INITIAL_STATE = {
  lista: lista,
  loading: false,
  loadingRefresh: false,
};

export default function bilhetes(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case "@ticket/GET_TICKET_REQUEST":
      return produce(state, (draft) => {
        draft.loading = true;
      });
    case "@ticket/GET_TICKET_REQUEST_PULE":

      return produce(state, (draft) => {
        draft.loading = true;
      });
    case "@ticket/UPDATE_SORTEIO":
      return produce(state, (draft) => {

        const { sorteio } = action.payload;
        if (state.lista) {
          state.lista.map((stateItem, idx) => {
            if (stateItem.sorteio.codigo == sorteio.codigo) {
              draft.lista[idx].sorteio = sorteio;
            }
          })
        }
      });
    case "@ticket/UPDATE_STATUS_SORTEIO":
      return produce(state, (draft) => {

        //const {sorteio} = action.payload;
        const { id } = action.payload;
        //console.log(sorteio);
        if (state.lista) {
          state.lista.map((stateItem, idx) => {
            if (stateItem.sorteio.codigo == id) {
              draft.lista[idx].sorteio.status = "replay";
            }
          })
        }
      });

    case "@ticket/RESET":
      //const response: IBilheteSorteio[] = [];
      const response = INITIAL_STATE;
      return response;

    case "@ticket/REMOVE":
      return produce(state, (draft) => {

        if (state) {
          const filteredItems = state.lista.filter(function (item) {
            return item.hash != action.payload.id;
          });

          if (filteredItems.length > 0) {
            draft.lista.length = 0;
            for (let item of filteredItems) {
              draft.lista.push(item);
            }
          } else {
            draft.lista.length = 0;
          }
        }
      });


    case "@ticket/GET_TICKET_SUCCESS":
      return produce(state, (draft) => {
        for (let item of action.payload.data) {
          if (state) {
            const isExist = state.lista.some((p) => p.hash === item.hash);
            if (!isExist) {
              draft.lista.push(item);
            } else {
              state.lista.map((stateItem, idx) => {
                if (stateItem.sorteio.codigo == item.sorteio.codigo) {
                  draft.lista[idx].sorteio = item.sorteio;
                }
              })


            }
          } else {
            draft.lista.push(item);
          }
        }
        draft.loading = false;
      });

   
    case "@ticket/CLEAN":
      return produce(state, (draft) => {
        draft.lista.length = 0;
      });

    case "@ticket/GET_ALL_TICKETS_REQUEST":
      return produce(state, (draft) => {
        draft.loadingRefresh = true;

      });

    case "@ticket/GET_ALL_TICKETS_SUCCESS":
      return produce(state, (draft) => {
        if (action.payload.bilhetes.length > 0) {
          draft.lista.length = 0;
          for (let item of action.payload.bilhetes) {
            draft.lista.push(item);
          }
          draft.loadingRefresh = false;
        } else {
          draft.loadingRefresh = false;
        }

      });


    case "@ticket/GET_ALL_TICKETS_ERROR":
      return produce(state, (draft) => {

        draft.lista = [];
        draft.loadingRefresh = false;
        draft.loading = false;
        toast.error("Ocorreu um erro na consulta de seus bilhetes.")


      });
    case "@ticket/GET_TICKET_ERROR":
      return produce(state, (draft) => {
        draft.loadingRefresh = false;
        draft.loading = false;
        toast.error("Número do bilhete não válido.");
      });





    default:
      return state;
  }
}
