import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
`;

export const CardAndButtonsGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
`;

export const ContainerCard = styled.div`
  margin-top: 8px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  cursor: grabbing;
`;

export const ListContainer = styled.div`
  width: 100%;
`;

export const BoxContainer = styled.div`
  list-style: none;
  background: radial-gradient(
    79.29% 79.29% at 49.87% 45.36%,
    #1e1a59 0%,
    #080720 100%
  );
  padding-top: 5px;
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;
  border-radius: 10px;
  border: 2px solid white;
  width: 100%;
`;

export const TextTop = styled.h2`
  padding: 0.2rem 0;
  margin: 0rem;
  font-size: 1.7rem;
  font-style: initial;
  color: #fcfd05;
  margin-top: 0px;
  line-height: 100%;
  font-weight: 700;
`;
export const TextMid = styled(TextTop)`
  padding: 0.2rem 0;
  color: #fcfdfa;
  font-size: 1.8rem;
  font-weight: 800;
`;
export const TextBot = styled(TextTop)`
  color: #fcfdfa;
  font-size: 1.3rem;
`;

export const ContainerHour = styled.div`
  padding: 0.2rem 1.5rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  p {
    display: flex;
    font-size: 1.4rem;
    color: #fcfdfa;
  }
`;

export const BoxBottomText = styled.div`
  padding: 0.2rem 0;
  background: #f0d50c;
  cursor: pointer;
  width: 100%;
  border-radius: 0px 0px 7px 7px;
  display: flex;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: 800;
`;
