import styled from "styled-components";

export const CardContainerTv = styled.div<{ tipo: number }>`
  height: 60%;
  width: 80%;
  z-index: 1;
  ${(props) =>
    props.tipo === 1
      ? `background: #ffffff;`
      : props.tipo === 2
      ? `background: linear-gradient(89.48deg, #CD911B -9.2%, #F8B123 14.81%, #FEFC37 26.55%, #FFFE8E 44.15%, #FEFD5A 62.3%, #F8B125 79.37%, #CD911B 93.24%);`
      : `background: linear-gradient(92.18deg, #5993D4 -8.3%, #AFE2FF 16.91%, #FFEDF7 46.1%, #FDCDE1 74.65%, #FE7CEC 96.4%);`};
  border-radius: 0.5rem;
  padding: 0 1.8rem;
  animation-name: identifier;
  animation-duration: 1s;
  animation-delay: -2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  /* @keyframes identifier {
        0%{
            background: linear-gradient(89.48deg, #CD911B -9.2%, #F8B123 14.81%, #FEFC37 26.55%, #FFFE8E 44.15%, #FEFD5A 62.3%, #F8B125 79.37%, #CD911B 93.24%);
        }
        25%{
            background: linear-gradient(89.48deg, #CD911B -9.2%, #CD911B 14.81%, #F8B123 26.55%, #FEFC37 44.15%, #FFFE8E 62.3%, #FEFD5A 79.37%, #F8B125 93.24%);
        }
        50%{
            background: linear-gradient(89.48deg, #F8B125 -9.2%, #CD911B 14.81%, #CD911B 26.55%, #F8B123 44.15%, #FEFC37 62.3%, #FFFE8E 79.37%, #FEFD5A 93.24%);
        }
        75%{
            background: linear-gradient(89.48deg, #FEFD5A -9.2%, #F8B125 14.81%, #CD911B 26.55%, #CD911B 44.15%, #F8B123 62.3%, #FEFC37 79.37%, #FFFE8E 93.24%);
        }
    } */
  opacity: 1;
`;

export const Title = styled.div`
  font-size: 3rem;
  font-weight: bolder;
  color: #ffffff;
  text-shadow: 0px 8.0143px 8.0143px #000000ce;
  margin-bottom: 1rem;
`;

export const ContainerADS = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const HeaderADS = styled.div`
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #111040;

  h2 {
    font-size: 1.8rem;
  }
  div {
    h3 {
      font-size: 1.8rem;
      font-weight: bolder;
    }
  }
`;

export const BodyADS = styled.div`
  height: calc(100% - 7rem);
  background: radial-gradient(
    706.7% 704.76% at 50% -590.48%,
    #1b10b1 0%,
    #111040 100%
  );
  box-shadow: 0px 9.82641px 9.82641px rgba(0, 0, 0, 0.25),
    inset -9.82641px -9.82641px 9.82641px rgba(0, 0, 0, 0.25),
    inset 0px 9.82641px 9.82641px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 0.5rem 0.5rem;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  padding: 2rem 2rem 0;
`;

export const Ward = styled.div<{ tipo: 1 | 2 | 3 }>`
  width: 20%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  h4 {
    color: #f7b634;
    font-size: 2rem;
    line-height: 2rem;
    ${(props) => props.tipo === 3 && "font-size:2.4rem;line-height:2rem;"}
    @media(min-width:1024px) {
      font-size: 2rem;
      ${(props) => props.tipo === 3 && "font-size:3.2rem;line-height:3.3rem;"}
    }
  }
  p {
    color: #ffffff;
    font-size: 2rem;
    line-height: 1.8rem;
    font-weight: bolder;
    ${(props) => props.tipo === 3 && "font-size:2.2rem;line-height:2rem;"}
    @media(min-width:1024px) {
      font-size: 2.5rem;
      line-height: 2.2rem;
      ${(props) => props.tipo === 3 && "font-size:3.2rem;line-height:3.3rem;"}
    }
  }
`;

export const InfoADS = styled.div`
  width: 100%;
  font-size: 1.3rem;
  font-weight: bolder;

  display: flex;
  justify-content: space-between;
  align-items: center;

  color: #ffffff;

  margin-top: 1rem;
  margin-bottom: 0.4rem;
  @media (min-width: 1024px) {
    font-size: 1.6rem;
    line-height: 2.2rem;
  }
`;

export const FooterADS = styled.div`
  min-height: 3rem;
  color: #111040;
  font-size: 1.5rem;
  font-weight: bolder;

  padding: 0.5rem 0rem;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const CardSecond = styled.div<{ tipo: number }>`
  width: 65%;
  height: 4%;
  ${(props) =>
    props.tipo === 1
      ? `background: #ffffff;`
      : props.tipo === 2
      ? `background: linear-gradient(89.48deg, #CD911B -9.2%, #F8B123 14.81%, #FEFC37 26.55%, #FFFE8E 44.15%, #FEFD5A 62.3%, #F8B125 79.37%, #CD911B 93.24%);`
      : `background: linear-gradient(92.18deg, #5993D4 -8.3%, #AFE2FF 16.91%, #FFEDF7 46.1%, #FDCDE1 74.65%, #FE7CEC 96.4%);`};
  border-radius: 0.5rem;
  border-radius: 0 0 0.4rem 0.4rem;
  opacity: 0.8;
  z-index: 1;
`;

export const CardThird = styled(CardSecond)`
  width: 50%;
  z-index: 0;
  opacity: 0.7;
`;
