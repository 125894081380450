import { link } from "fs";
import React from "react";
import ReactDOM from "react-dom";
import { useHistory } from "react-router";
//import { useApi } from "../services/apiContext";

//Nesse componente a imagem é setada pela rota padrão, temos as seguintes variações:
// https://devs1.mrkeno.app/api/media/logo/
// https://devs1.mrkeno.app/api/media/favicon/
// https://devs1.mrkeno.app/api/media/login/
// https://devs1.mrkeno.app/api/media/nome/
// Juan
type logo = {
  tamanho: string;
};
export const GetLogoServer = ({ tamanho }: logo) => {
  //const {baseUrl} = useApi();
  const history = useHistory();
  const logo = process.env.REACT_APP_BASE_URL + "/api/media/login/"
  return <img onClick={() => history.push('/') } src={logo} alt="logo" style={{ height: tamanho }} />;
};
