import React, { useState } from "react";
import { formatMoney } from "../../hooks/formatNumber";
import * as S from "../../pages/Styles/PaginaDosVencedores";

interface IMyProps {
  winner: ISorteioVencedores;
}
const TabelaWin: React.FC<IMyProps> = ({ winner }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const typeGame = () => {
    if (winner.tipo_sorteio == "NORMAL") {
      return "normal";
    }
    if (winner.tipo_sorteio == "ESPECIAL") {
      return "especial";
    }
    if (winner.tipo_sorteio == "SUPER ESPECIAL") {
      return "super";
    }
  };

  return (
    <div
      style={{
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        flexWrap: "wrap",
      }}
    >
      <S.DivTitle style={{ marginTop: "1rem", marginBottom: "0.5rem" }}>
        <S.TittleWhite style={{ fontSize: "1.3rem" }}>
          {`SORTEIO: ${winner.sorteio}`}
        </S.TittleWhite>
        <S.ButtonToExpand
          isExpanded={isExpanded}
          className="right"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <S.ArrowDownOutlineIcon className="expand" />
        </S.ButtonToExpand>
      </S.DivTitle>

      {isExpanded && (
        <S.Table className={typeGame()}>
          <S.Tr style={{ marginBottom: "0.3rem" }}>
            <S.Th w="25%">CARTELA</S.Th>
            <S.Th w="50%">VENCEDOR</S.Th>
            <S.Th w="25%">PRÊMIO</S.Th>
          </S.Tr>
          <div
            style={{
              background:
                "radial-gradient( 79.29% 79.29% at 49.87% 45.36%, #1b10b1 0%, #111040 100% )",
            }}
          >
            {winner.bilhetes_keno.map((win, index) => (
              <S.ContainerList key={index} style={{ background: "#12121280" }}>
                <S.Tr>
                  <S.Td w="25%">{win.numero_cartela}</S.Td>
                  <S.Td w="50%">{win.estabelecimento}</S.Td>
                  <S.Td w="30%">
                    <span style={{ color: "#F0D50C", marginRight: "0.3rem" }}>
                      {"KE"}
                    </span>
                    {formatMoney(win.premio)}
                  </S.Td>
                </S.Tr>
              </S.ContainerList>
            ))}
            {winner.bilhetes_kina.map((win, index) => (
              <S.ContainerList key={index} style={{ background: "#12121280" }}>
                <S.Tr>
                  <S.Td w="25%">{win.numero_cartela}</S.Td>
                  <S.Td w="50%">{win.estabelecimento}</S.Td>
                  <S.Td w="30%">
                    <span style={{ color: "#202EB1", marginRight: "0.3rem" }}>
                      {"KI"}
                    </span>
                    {formatMoney(win.premio)}
                  </S.Td>
                </S.Tr>
              </S.ContainerList>
            ))}
            {winner.bilhetes_kuadra.map((win, index) => (
              <S.ContainerList key={index} style={{ background: "#12121280" }}>
                <S.Tr>
                  <S.Td w="25%">{win.numero_cartela}</S.Td>
                  <S.Td w="50%">{win.estabelecimento}</S.Td>
                  <S.Td w="30%">
                    <span style={{ color: "#20B12E", marginRight: "0.3rem" }}>
                      {"KA"}
                    </span>
                    {formatMoney(win.premio)}
                  </S.Td>
                </S.Tr>
              </S.ContainerList>
            ))}
          </div>
        </S.Table>
      )}
    </div>
  );
};

export default TabelaWin;
