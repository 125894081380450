import React, { useEffect, useRef, useState } from "react";
import "../../../App.css";
import { Award } from "../../../components/award";
import { Cart } from "../../../components/cart";
import { GetLogoServer } from "../../../components/GetLogoServer";
import { Table } from "../../../components/Table";
import Bola from "../../../components/TVcomponent/Bola";
import TuboBola from "../../../components/TVcomponent/TuboBola";
import { cartSelector } from "../../../hooks/cartSelector";
import { useClick } from "../../../hooks/useClick";
import useWindowSize from "../../../hooks/useWindowSize";
import ReplacePadStart from "../../../Utils/ReplacePadStart";
import * as L from "../../Styles/GameLargeScreen";

export type Cartela = IPartida["cartelas"][0] & IPartida["turnos"][0];

interface IGameProps {
  sorteio: IPartida;
  turnoAtual: Cartela[];
  turno: number;
  timeUpdate: number;
  isKeno: boolean;
  ativar_audio: () => void;
}

const GameLargeScreen: React.FC<IGameProps> = ({
  sorteio,
  turnoAtual,
  turno,
  timeUpdate,
  isKeno,
  ativar_audio,
}) => {
  const [isOpenMyCards, setIsOpenMyCards] = useState<boolean>(false);
  const [CartList, setCartList] = useState<ICartelas[]>();
  const [TurnoCartelasIni, setTurnoCartelasIni] = useState(0);
  const [data, setdata] = useState<ICart>();
  const [muted, setMuted] = useState(false);

  const CartVisible = async (data: ICart) => {
    const res = await cartSelector(data);
    setCartList(res.cart);
    setTurnoCartelasIni(res.TurnoCartelasIni);
    setdata({
      cartCompradas: sorteio.cartelas_compradas,
      turnos: sorteio.turnos,
      carts: sorteio.cartelas,
      turno: turno,
      bolasSorteadas: sorteio.bolas_sorteadas,
      kina: sorteio.bola_kina,
      turnoCartelasIni: TurnoCartelasIni,
    });
  };

  const mutedButtonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    CartVisible({
      cartCompradas: sorteio.cartelas_compradas,
      turnos: sorteio.turnos,
      carts: sorteio.cartelas,
      turno: turno,
      bolasSorteadas: sorteio.bolas_sorteadas,
      kina: sorteio.bola_kina,
      turnoCartelasIni: TurnoCartelasIni,
    });
  }, [turno]);

  useClick(mutedButtonRef);

  const { width } = useWindowSize();

  function SizeLogo() {
    if (width <= 1024) {
      return "3.8rem";
    } else {
      return "5rem";
    }
  }

  return (
    <L.Container>
      <L.GroupLeft>
        <L.ContentLogo>
          <GetLogoServer tamanho={SizeLogo()} />
        </L.ContentLogo>
        <L.BoxAcumulado>
          <L.TextRow>
            {sorteio?.numero_bolas_acumulado ? (
              <>
                <span>ACUMULADO:</span>
                <span style={{ color: "#F0D50C" }}>
                  {" " + sorteio.numero_bolas_acumulado}
                </span>
                <span>BOLAS</span>
              </>
            ) : (
              <span> {window.location.host}</span>
            )}
          </L.TextRow>
          <L.TextRowValue>
            <span style={{ color: "#F0D50C" }}>{sorteio.valor_acumulado}</span>
          </L.TextRowValue>
        </L.BoxAcumulado>
      </L.GroupLeft>
      <L.BoxSorteio>
        <L.ContentAudio
          ref={mutedButtonRef}
          onClick={() => {
            ativar_audio();
            setMuted(!muted);
          }}
        >
          {muted ? <L.GoMuteIcon /> : <L.VolumeMuteIcon />}
        </L.ContentAudio>
        <span>SORTEIO: </span>
        <h3>{sorteio.codigo}</h3>
      </L.BoxSorteio>
      <L.GroupRight>
        <Award
          doacao={sorteio.valor_cartela}
          kuadra={sorteio.valor_kuadra}
          Kina={sorteio.valor_kina}
          Keno={sorteio.valor_keno}
        />
      </L.GroupRight>

      <L.GroupLeftContent>
        <Table dados={turnoAtual} keno={isKeno} />
      </L.GroupLeftContent>

      <L.CartHolder>
        <Cart
          cartelas={CartList}
          numerosSorteados={sorteio.bolas_sorteadas}
          turno={turno + 1}
        />
      </L.CartHolder>
      {/* <L.ContainerTubeBall>
                <TubeBall ballKeno={sorteio.bola_keno} ball={sorteio.bolas_sorteadas} turno={turno} tempoUpdate={timeUpdate} />
            </L.ContainerTubeBall> */}
      <div style={{ width: "30%", height: "83%" }}>
        <div
          style={{
            gridArea: "globo",
            padding: "1rem 1rem",
            width: "100%",
            height: "55%",
          }}
        >
          <Bola numero={sorteio.bolas_sorteadas[turno]} />
        </div>

        <div
          style={{
            gridArea: "number",
            padding: "0rem 1rem",
            width: "100%",
            height: "45%",
          }}
        >
          <L.ContainerBolasSorteadas>
            <L.TittleBalls>
              <p>Bolas Sorteadas</p>
              <L.ContentBolas>
                {sorteio.bolas_sorteadas.slice(0, turno).length}
              </L.ContentBolas>
            </L.TittleBalls>
            <L.BlocoBalls>
              {[...Array(90)].map((x, index) => {
                if (
                  sorteio?.bolas_sorteadas
                    .slice(0, turno)
                    .find((sorteio) => sorteio === index + 1) !== undefined
                ) {
                  return (
                    <L.ContentBalls className="BallsDrawed" key={index}>
                      <L.Balls Ativo={true}>
                        {ReplacePadStart((index + 1).toString(), 2)}
                      </L.Balls>
                    </L.ContentBalls>
                  );
                } else {
                  return (
                    <L.ContentBalls className="BallsDrawed" key={index}>
                      <L.Balls Ativo={false}>
                        {ReplacePadStart((index + 1).toString(), 2)}
                      </L.Balls>
                    </L.ContentBalls>
                  );
                }
              })}
            </L.BlocoBalls>
          </L.ContainerBolasSorteadas>
        </div>
      </div>

      <div
        style={{
          gridArea: "tubo",
          padding: "1rem 1rem 0rem",
          width: "10%",
          height: "83%",
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-end",
        }}
      >
        <TuboBola bolas={sorteio.bolas_sorteadas} turno={turno} />
      </div>
    </L.Container>
  );
};

export default GameLargeScreen;
