import styled from "styled-components";
import {} from "./Vencedor";

export const Container = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 1rem;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  padding: 0.5rem 1rem;
  background: #ffffff;
  border-radius: 6px;

  @media (min-width: 900px) {
    width: 65%;
    margin-top: 1rem;
    padding: 2rem 1.3rem 1.3rem;
  }
`;

export const Header = styled.h1<{ tipo: string }>`
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  letter-spacing: -1px;
  font-weight: 900;
  padding-bottom: 0.5rem;

  color: ${(props) =>
    props.tipo === "kuadra"
      ? "#12104E"
      : props.tipo === "kina"
      ? "#122E11"
      : "#565419"};
`;

export const HeaderImg = styled.img`
  position: absolute;
  top: -2.5rem;
`;

export const StartLeft = styled.img`
  position: absolute;
  height: 19rem;
  left: 8rem;
  z-index: 0;
  left: -10.5rem;
`;

export const StartRight = styled.img`
  position: absolute;
  height: 19rem;
  right: 8rem;
  z-index: 0;
  right: -10.5rem;
`;

export const Boby = styled.div<{ tipo: string; tvMode?: string }>`
  width: 100%;
  padding: 0.2rem;
  border-radius: 6px;
  padding: 0.5rem 0rem 0rem;

  ${(props) =>
    props.tvMode &&
    `
  border: 0.4rem solid
    ${
      props.tipo === "acumulado"
        ? "#602E13"
        : props.tipo === "keno"
        ? "#A4460B"
        : props.tipo === "kuadra"
        ? "#174F14"
        : "#130D88"
    };
    `}

  background: ${(props) =>
    props.tipo === "acumulado"
      ? "#F8DC23"
      : props.tipo === "keno"
      ? "#C1B409"
      : props.tipo === "kuadra"
      ? "radial-gradient(79.29% 79.29% at 49.87% 45.36%, #1FA71C 0%, #0D3107 100%)"
      : "radial-gradient(79.29% 79.29% at 49.87% 45.36%, #1B10B1 0%, #111040 100%)"};
  ${(props) => props.tvMode && `height: 50vh;`}
  display: flex;
  justify-content: center;
  align-content: flex-start;
  flex-wrap: wrap;
  z-index: 2;
`;

export const Line = styled.div`
  width: 95%;
  height: 2.2rem;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const LineOver = styled(Line)`
  border: 0.1rem solid #ffffff;
  border-radius: 0.2rem;
  height: 3.7rem;
`;

export const Bloco = styled.div`
  height: 2.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #12121280;
  color: #ffffff;
  font-size: 1.1rem;
`;

export const BlocoPremios = styled(Bloco)<{ active: boolean }>`
  background: #776e12;
  border-radius: 0.3rem;
  border: 0.1rem solid #ffffff;
  color: ${(props) => (props.active ? "#ffffff" : "#827A2F")};
`;

export const Footer = styled.div`
  width: 100%;
`;
