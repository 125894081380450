import React from "react";
import { formatMoney } from "../hooks/formatNumber";
import * as V from "../pages/Styles/Vencedor";
import Header from "./Header";
import BordaDourada from "../images/Borda_dourada_Vencedores_PNG.png";

type quadroVencedor = {
  bolas?: number[];
  vencedor: number[];
  cartelas: ICartelas[];
  linhas_vencedor: number[];
  premio: number;
  tipo: string;
};
export const QuadroVencedores: React.FC<quadroVencedor> = ({
  tipo,
  premio,
  vencedor,
  linhas_vencedor,
  cartelas,
  bolas,
}) => {
  return (
    <V.ConatinerModal>
      <V.ContentHeader tipo={tipo} style={{ width: "100%" }}>
        <Header tipo="limpoHome" />
        <V.TabelaWinner>
          <V.TabelaHeader>
            {("vencedores " + tipo).toUpperCase()}
          </V.TabelaHeader>
          <V.TabelaBoby tipo={tipo}>
            <V.PremiacaoContent widthTitle={50} widthValor={50}>
              <h2>PREMIAÇÃO</h2>
              <p>{formatMoney(premio)}</p>
            </V.PremiacaoContent>
            <V.PremiacaoContent widthTitle={50} widthValor={50}>
              <h2>VENCEDORES</h2>
              <p>{vencedor.length}</p>
            </V.PremiacaoContent>
            <V.PremiacaoContent widthTitle={50} widthValor={50}>
              <h2>POR VENCEDOR</h2>
              <p>{formatMoney(premio / vencedor.length)}</p>
            </V.PremiacaoContent>
          </V.TabelaBoby>
        </V.TabelaWinner>
      </V.ContentHeader>
      <V.ContentBoby>
        {vencedor.map((vencedor, index) =>
          cartelas
            .filter((x) => x.codigo === vencedor)
            .map((cartela) => {
              return (
                <div
                  key={`cartela_div_${cartela.codigo}`}
                  style={{
                    width: "46.5%",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                >
                  <V.ConatinerCard style={{ width: "100%" }}>
                    <V.HeaderCard tipo={tipo}>
                      <V.BlocoNumber>{cartela.codigo}</V.BlocoNumber>
                      <h1>{cartela.estabelecimento?.toUpperCase()}</h1>
                    </V.HeaderCard>
                    <V.BobyCard>
                      <V.LineCard>
                        {cartela.linha1_lista.map((bola) => {
                          return (
                            <V.BlocoCard
                              key={`bola_bloco_${bola}_linha1`}
                              style={{
                                width: "calc(100% * (1/5))",
                              }}
                            >
                              {(linhas_vencedor[index] === 0 &&
                                bolas?.includes(bola)) ||
                              linhas_vencedor[index] === 3 ? (
                                <div
                                  style={{
                                    backgroundImage: `url(${BordaDourada})`,
                                    backgroundSize: "100% 100%",
                                    backgroundPosition: "left top",
                                  }}
                                >
                                  {bola}
                                </div>
                              ) : (
                                <div
                                  style={{
                                    background:
                                      "radial-gradient(79.29% 79.29% at 49.87% 45.36%, #F9F9F9 0%, #9D9D9D 100%)",
                                  }}
                                >
                                  {bola}
                                </div>
                              )}
                            </V.BlocoCard>
                          );
                        })}
                        {cartela.linha2_lista.map((bola) => {
                          return (
                            <V.BlocoCard
                              key={`bola_bloco_${bola}_linha2`}
                              style={{
                                width: "calc(100% * (1/5))",
                              }}
                            >
                              {(linhas_vencedor[index] === 1 &&
                                bolas?.includes(bola)) ||
                              linhas_vencedor[index] === 3 ? (
                                <div
                                  style={{
                                    backgroundImage: `url(${BordaDourada})`,
                                    backgroundSize: "100% 100%",
                                    backgroundPosition: "left top",
                                  }}
                                >
                                  {bola}
                                </div>
                              ) : (
                                <div
                                  style={{
                                    background:
                                      "radial-gradient(79.29% 79.29% at 49.87% 45.36%, #F9F9F9 0%, #9D9D9D 100%)",
                                  }}
                                >
                                  {bola}
                                </div>
                              )}
                            </V.BlocoCard>
                          );
                        })}
                        {cartela.linha3_lista.map((bola) => {
                          return (
                            <V.BlocoCard
                              key={`bola_bloco_${bola}_linha3`}
                              style={{
                                width: "calc(100% * (1/5))",
                              }}
                            >
                              {(linhas_vencedor[index] === 2 &&
                                bolas?.includes(bola)) ||
                              linhas_vencedor[index] === 3 ? (
                                <div
                                  style={{
                                    backgroundImage: `url(${BordaDourada})`,
                                    backgroundSize: "100% 100%",
                                    backgroundPosition: "left top",
                                  }}
                                >
                                  {bola}
                                </div>
                              ) : (
                                <div
                                  style={{
                                    background:
                                      "radial-gradient(79.29% 79.29% at 49.87% 45.36%, #F9F9F9 0%, #9D9D9D 100%)",
                                  }}
                                >
                                  {bola}
                                </div>
                              )}
                            </V.BlocoCard>
                          );
                        })}
                      </V.LineCard>
                      <V.ContentLine>
                        <V.LineCart />
                      </V.ContentLine>
                    </V.BobyCard>
                  </V.ConatinerCard>
                </div>
              );
            })
        )}
      </V.ContentBoby>
    </V.ConatinerModal>
  );
};
