import { format } from "date-fns";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useInterval from "../hooks/useIntervals";
import * as S from "../pages/Styles/MeuSorteioStyle";
import { formatDate, timerSort, verifyTime } from "../hooks/formatTime";
import AudioHome from "../hooks/AudioHome";

interface ISorteioProps {
  datahora: string;
  titulo: string;
  cor: string;
  bilheteInicio?: boolean;
}

interface IBilheteProps {
  bilhete: IBilhete;
}

const CardSorteioBilhete: React.FC<ISorteioProps> = ({
  datahora,
  titulo,
  cor,
  bilheteInicio,
}) => {
  const bilhete: IBilheteProps = useSelector((state: any) => state.bilhete);
  const history = useHistory();
  const [time, setTime] = useState(Date.now());
  const [secondsCountdown, setSecondsCountdown] = useState(0);
  const [count, setcount] = useState(0);

  useInterval(() => {
    setcount(count + 1);
  }, 1000);

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const playAudio = (type: number) => {
    return <AudioHome type={type} />
  }

  //console.log(datahora)
  return (
    <>
      <S.TitleContainer>
        <S.TittleMeuBilhete>{titulo.toUpperCase()}</S.TittleMeuBilhete>
      </S.TitleContainer>
      <S.ContainerCard>
        {datahora && (
          <motion.div
            style={{
              width: "85%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            initial={{ x: -100 }}
            animate={{ x: 0 }}
            transition={{ delay: 1 }}
          >
            {bilhete?.bilhete ? (
              <S.ListContainer>
                {bilhete?.bilhete?.sorteio && (
                  <S.BoxContainer>
                    <S.TextTop>{"Número do Sorteio".toUpperCase()}</S.TextTop>
                    <S.TextMid>{bilhete?.bilhete.sorteio}</S.TextMid>
                    <S.containerHour>
                      <p>
                        {format(
                          new Date(bilhete?.bilhete?.data_hora_sorteio),
                          "dd/MM/yyyy"
                        )}
                      </p>
                      <p>
                        {format(
                          new Date(bilhete?.bilhete.data_hora_sorteio),
                          "HH:mm:ss"
                        )}
                      </p>
                    </S.containerHour>

                    <S.BoxBottomText style={{ background: cor }}>
                      {bilheteInicio
                        ? "COMEÇA EM: " +
                        timerSort(
                          formatDate(bilhete?.bilhete.data_hora_sorteio),
                          formatDate(datahora),
                          count
                        )
                        : timerSort(
                          formatDate(bilhete?.bilhete.data_hora_sorteio),
                          formatDate(datahora),
                          count
                        ) == "PREPARANDO"
                          ? history.push(`/${bilhete.bilhete.sorteio}`)
                          : timerSort(
                            formatDate(bilhete?.bilhete.data_hora_sorteio),
                            formatDate(datahora),
                            count
                          )}
                      {verifyTime(
                        formatDate(bilhete?.bilhete.data_hora_sorteio),
                        formatDate(datahora),
                        count
                      ) == 1 && (
                          <>
                            {playAudio(1)}
                          </>
                        )}

                      {verifyTime(
                        formatDate(bilhete?.bilhete.data_hora_sorteio),
                        formatDate(datahora),
                        count
                      ) == 2 && (
                          <>
                            {playAudio(2)}
                          </>
                        )}
                      {verifyTime(
                        formatDate(bilhete?.bilhete.data_hora_sorteio),
                        formatDate(datahora),
                        count
                      ) == 3 && (
                          <>
                            {playAudio(3)}
                          </>
                        )}
                    </S.BoxBottomText>
                  </S.BoxContainer>
                )}
              </S.ListContainer>
            ) : (
              <S.BoxContainer>
                <S.ButtonBack>{"<"}</S.ButtonBack>
                <S.TextTop>{"Sorteio não encontrado".toUpperCase()}</S.TextTop>
                <S.TextMid>{"x"}</S.TextMid>

                <S.BoxBottomText style={{ background: cor }}>
                  {"Sorteio não encontrado"}
                </S.BoxBottomText>
              </S.BoxContainer>
            )}
          </motion.div>
        )}
      </S.ContainerCard>
    </>
  );
};

export default CardSorteioBilhete;
