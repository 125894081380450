import React, { useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import "../../App.css";
import { CardWinner } from "../../components/cartelasVencedores";
import NomeServidor from "../../components/NomeServidor";
import { TabelaVencedores } from "../../components/TabelaVencedores";
import PlayAudio from "../../hooks/Audio";
import useInterval from "../../hooks/useIntervals";
import useWindowDimensions from "../../hooks/useWindowSize";
import * as S from "../../style";
import { GetPartida } from "../../WebRequests/requestsJson";
import BufferLoader from "./BufferLoader";
import GameDefault from "./GameDefault/GameDefault";
import GameLargeScreen from "./GameLargeScreen/GameLargeScreen";

type Settings = {
  qtd: number;
};

enum ErrorType {
  Invalid = -1,
  Waiting = 1,
  Sorteado = 2,
  Sorteando = 3,
}

declare global {
  interface Window {
    webkitAudioContext: typeof AudioContext;
  }
}

export type Cartela = IPartida["cartelas"][0] & IPartida["turnos"][0];
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
function Game() {
  const { width } = useWindowDimensions();

  const history = useHistory();
  const { partida } = useParams();
  const [click, setClick] = useState<number>(0);
  const [error, setError] = useState<ErrorType>();
  const [sorteio, setSorteio] = useState<IPartida>();
  const [settings, setSettings] = useState<Settings>({ qtd: 15 });
  const [isLoading, setIsLoading] = useState(false);
  const [turno, setTurno] = useState<number>(0);

  const [timeUpdate, settimeUpdate] = useState(3000);
  const [canUpdate, setcanUpdate] = useState(false);
  const [kenoAparece, setKenoAparece] = useState(false);
  const [Mute, setMute] = useState(false);
  const [tvMode, setTvmode] = useState("");
  const [modalLoading, setmodalLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [initialIndex, setInitialIndex] = useState(0);

  var context: AudioContext;

  const percenteRef = useRef<HTMLDivElement>(null);
  let [array_source, setArraySource] = useState<Array<AudioBufferSourceNode>>(
    []
  );
  const [audiosArray, setaudiosArray] = useState<HTMLAudioElement[]>();
  let query = useQuery();

  let tvQuery = useQuery().get("tv");

  useEffect(() => {
    if (tvQuery) {
      setTvmode(tvQuery);
      console.log("tv");
    }
  }, [tvQuery]);

  function refresh() {
    window.location.reload();
  }

  useEffect(() => {
    if (query.get("redirect") === "true") {
      setIsLoading(true);
    }
  }, [query.get("redirect")]);

  // function playSound(audio:number) {
  //   var source = audioCtx.createBufferSource(); // creates a sound source
  //   source.buffer = buffer;                    // tell the source which sound to play
  //   source.connect(audioCtx.destination);       // connect the source to the context's destination (the speakers)
  //   source.start(0);                           // play the source now
  //                                              // note: on older systems, may have to use deprecated noteOn(time);
  // }
  var mostrarPorcentatem = 0;

  const playSound = () => {
    if (!Mute) {
      setmodalLoading(true);
    }

    context = new (window.AudioContext || window.webkitAudioContext)();
    context.resume();
    let array = new Array();
    sorteio?.bolas_sorteadas.map((num, i) => {
      array[i] = `/assets/audios/Numero_${num}.wav`;
    });
    array.push(`/assets/audios/Kuadra.wav`);
    array.push(`/assets/audios/Kina.wav`);
    array.push(`/assets/audios/Keno.wav`);
    array.push(`/assets/audios/Acumulado.wav`);
    if (array) {
      let bufferLoader = new BufferLoader(context, array, finishedLoading);
      bufferLoader.load();
    }
  };

  const finishedLoading = (bufferList: Array<AudioBuffer>) => {
    // Create two sources and play them both together.
    let array = new Array();
    percenteRef.current ? (percenteRef.current.innerText = `zero`) : null;

    bufferList.forEach((buffer) => {
      let source = context.createBufferSource();

      source.connect(context.destination);
      source.buffer = buffer;
      array.push(source);
      var porcentagem = (array.length / bufferList.length) * 100;
      // setprogressAudio(porcentagem);
      if (porcentagem <= 100) {
        setmodalLoading(false);
      }
    });

    setArraySource(array);
  };

  const turnoAtual: Cartela[] = useMemo(() => {
    if (!sorteio) return [];
    //console.log(settings.qtd);

    let tData = [];
    if (sorteio.cartelas_compradas >= 15) {
      const sIndex = turno * settings.qtd;
      tData = sorteio?.turnos.slice(sIndex, sIndex + settings.qtd);
    } else {
      if (sorteio.cartelas_compradas < 15 && sorteio.cartelas_compradas >= 5) {
        if (
          sorteio.bolas_sorteadas
            .slice(0, turno)
            .find((x) => x === sorteio.bola_kina) === undefined
        ) {
          tData = sorteio?.turnos.slice(
            initialIndex,
            initialIndex + settings.qtd
          );
          setInitialIndex(initialIndex + settings.qtd);
        } else {
          tData = sorteio?.turnos.slice(
            initialIndex,
            initialIndex + sorteio.cartelas_compradas
          );
          setInitialIndex(initialIndex + sorteio.cartelas_compradas);
        }
      } else {
        tData = sorteio?.turnos.slice(
          initialIndex,
          initialIndex + sorteio.cartelas_compradas
        );
        setInitialIndex(initialIndex + sorteio.cartelas_compradas);
      }
    }

    if (!tData || tData.length == 0) return [];
    return tData.map((cart) => {
      return {
        ...sorteio.cartelas.find((x) => x.codigo === cart.codigo),
        ...cart,
      } as Cartela;
    });
  }, [settings.qtd, sorteio, turno]);

  const partidaAtual = async () => {
    setIsLoading(true);
    const { data, error } = await GetPartida(partida, tvQuery);
    if (data) {
      if (data.data.bolas_sorteadas) {
        setSorteio(data?.data);
        // settimeUpdate(data.);
        // setSettings({
        //   qtd: Math.min(data.data.cartelas.length, 15),
        // });
        setIsLoading(false);
      }
      // else {
      //   setIsLoading(true);
      // }
    } else {
      setError(ErrorType.Invalid);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    partidaAtual();
    if (sorteio) {
      return settimeUpdate(
        sorteio?.velocidade_sorteio_online
          ? sorteio.velocidade_sorteio_online
          : 3000
      );
    }
  }, []);

  const timeJogo = () => {
    if (
      sorteio?.bolas_sorteadas[length - 1] == sorteio?.bolas_sorteadas[turno]
    ) {
      // final do jogo
    }
    if (
      sorteio?.bola_kuadra === sorteio?.bolas_sorteadas[turno + 1] ||
      sorteio?.bola_kina === sorteio?.bolas_sorteadas[turno + 1] ||
      sorteio?.bola_keno === sorteio?.bolas_sorteadas[turno + 1]
    ) {
      return settimeUpdate(7000);
    } else if (sorteio) {
      return settimeUpdate(
        sorteio?.velocidade_sorteio_online
          ? sorteio.velocidade_sorteio_online
          : 4000
      );
    }
  };

  const focusAplication = () => {
    window.onblur = () => {
      setMute(false);
    };

    window.onfocus = () => {
      setMute(true);
    };
  };

  useEffect(() => {
    focusAplication();
  });

  const execJogo = () => {
    PlayAudio(array_source, sorteio, turno, setTurno, Mute);
    setTurno(turno + 1);
    setcanUpdate(true);
    timeJogo();
  };

  useInterval(
    () => {
      execJogo();
    },
    !modalLoading ? timeUpdate : 100000000
  );

  if (sorteio) {
    if (turno >= sorteio.bolas_sorteadas.length + 1) {
      return (
        <TabelaVencedores
          cartelas={sorteio.cartelas}
          acumulado={
            sorteio.numero_bolas_acumulado >= sorteio.bolas_sorteadas.length
              ? true
              : false
          }
          vencedoresKuadra={sorteio.vencedores_kuadra}
          vencedoresKina={sorteio.vencedores_kina}
          vencedoresKeno={sorteio.vencedores_keno}
          sorteio={sorteio}
        />
      );
    }
    if (sorteio?.bola_keno === sorteio?.bolas_sorteadas[turno]) {
      setInterval(() => {
        setKenoAparece(true);
      }, 2000);
    }
  }
  const isLargeScreen = width >= 900 || tvMode ? true : false;
  // const isLargeScreen = width >= 1024 || tvMode ? true : false;

  return (
    <>
      {!isLoading ? (
        <S.Container style={{ paddingBottom: "0" }}>
          <>
            <S.ModalAudio
              style={modalLoading ? { display: "flex" } : { display: "none" }}
            >
              <S.TextLoading>CARREGANDO ÁUDIO</S.TextLoading>
              <S.TextAnimationPoints className="loading">
                .
              </S.TextAnimationPoints>
            </S.ModalAudio>
          </>

          {sorteio ? (
            <>
              {sorteio?.bola_kuadra === sorteio?.bolas_sorteadas[turno] && (
                <CardWinner
                  bolas={sorteio?.bolas_sorteadas.slice(0, turno + 1)}
                  vencedor={sorteio?.vencedores_kuadra}
                  cartelas={sorteio?.cartelas}
                  linhas_vencedor={sorteio?.linhas_vencedoras_kuadra}
                  premio={sorteio?.valor_kuadra}
                  tipo="kuadra"
                />
              )}

              {sorteio?.bola_kina === sorteio?.bolas_sorteadas[turno] && (
                <CardWinner
                  bolas={sorteio?.bolas_sorteadas.slice(0, turno + 1)}
                  vencedor={sorteio?.vencedores_kina}
                  cartelas={sorteio?.cartelas}
                  linhas_vencedor={sorteio.linhas_vencedoras_kina}
                  premio={sorteio.valor_kina}
                  tipo="kina"
                />
              )}

              {kenoAparece === true &&
                (sorteio.numero_bolas_acumulado >=
                sorteio.bolas_sorteadas.length ? (
                  <CardWinner
                    vencedor={sorteio?.vencedores_keno}
                    cartelas={sorteio?.cartelas}
                    linhas_vencedor={sorteio.linhas_vencedoras_keno}
                    premio={sorteio.valor_keno}
                    tipo="acumulado"
                  />
                ) : (
                  <CardWinner
                    vencedor={sorteio?.vencedores_keno}
                    cartelas={sorteio?.cartelas}
                    linhas_vencedor={sorteio.linhas_vencedoras_keno}
                    premio={sorteio.valor_keno}
                    tipo="keno"
                  />
                ))}
              <>
                {isLargeScreen ? (
                  <GameLargeScreen
                    sorteio={sorteio}
                    turnoAtual={turnoAtual}
                    turno={turno}
                    timeUpdate={timeUpdate}
                    isKeno={
                      sorteio?.bola_kina === sorteio?.bolas_sorteadas[turno]
                    }
                    ativar_audio={() => {
                      playSound();
                      setMute(!Mute);
                    }}
                  />
                ) : (
                  <>
                    <GameDefault
                      sorteio={sorteio}
                      turnoAtual={turnoAtual}
                      turno={turno}
                      timeUpdate={timeUpdate}
                      isKeno={
                        sorteio?.bola_kina === sorteio?.bolas_sorteadas[turno]
                      }
                      ativar_audio={() => {
                        playSound();
                        setMute(!Mute);
                      }}
                    />
                  </>
                )}
              </>
            </>
          ) : (
            <>
              {error && error === -1 ? (
                <div
                  style={{
                    marginTop: "2rem",
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <h1
                    style={{
                      textAlign: "center",
                      justifyContent: "center",
                      transition: "ease-in-out",
                      color: "white",
                    }}
                  >
                    Sorteio não encontrado
                  </h1>
                  <S.ButtonBack onClick={() => history.push("/")}>
                    {"Voltar"}
                  </S.ButtonBack>
                </div>
              ) : (
                <h1
                  style={{
                    textAlign: "center",
                    justifyContent: "center",
                    transition: "ease-in-out",
                  }}
                >
                  {error}
                </h1>
              )}
            </>
          )}

          <NomeServidor />
        </S.Container>
      ) : (
        <S.ContainerError>
          <S.TittleWhite> Carregando informações de partida... </S.TittleWhite>
          {/* <S.ButtonBack
            style={{ width: "10rem", marginTop: "2rem", height: "4rem" }}
            onClick={() => window.location.reload()}
          >
            Forçar Carregamento
          </S.ButtonBack> */}
        </S.ContainerError>
      )}
    </>
  );
}

export default Game;
