import React from "react";
import * as S from "../style";

type Acumulados = {
  doacao: Number;
  kuadra: Number;
  Kina: Number;
  Keno: Number;
};

export const Award = ({ doacao, kuadra, Kina, Keno }: Acumulados) => {
  function formatNumberToReal(numero: Number) {
    return numero
      ? numero
          .toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          })
          .replace("R$", "")
      : 0;
  }

  return (
    <S.ContainerAward>
      {/* <motion.div style={{
          width:'100%',
          display: 'flex',
          gap: '5px',
          }}>
          <motion.div style={{width:'calc(100% * (1/4))'}} 
            // animate={animacao}
            // transition={transicao}
            > */}
      <S.CardAward>
        <S.HeadAward bg="radial-gradient(79.29% 79.29% at 49.87% 45.36%, #A71C99 0%, #310723 100%);">
          DOAÇÃO
        </S.HeadAward>
        <S.BodyAward>{formatNumberToReal(doacao)}</S.BodyAward>
      </S.CardAward>
      {/* </motion.div>
          <motion.div style={{width:'calc(100% * (1/4))'}} 
            //  animate={animacao}
            //  transition={transicao}
             > */}
      <S.CardAward>
        <S.HeadAward bg="radial-gradient(79.29% 79.29% at 49.87% 45.36%, #1FA71C 0%, #0D3107 100%);">
          KUADRA
        </S.HeadAward>
        <S.BodyAward>{formatNumberToReal(kuadra)}</S.BodyAward>
      </S.CardAward>
      {/* </motion.div>
          <motion.div style={{width:'calc(100% * (1/4))'}} 
            //  animate={animacao}
            //  transition={transicao}
            > */}
      <S.CardAward>
        <S.HeadAward bg="radial-gradient(79.29% 79.29% at 49.87% 45.36%, #203D88 0%, #080E5A 100%);)">
          KINA
        </S.HeadAward>
        <S.BodyAward>{formatNumberToReal(Kina)}</S.BodyAward>
      </S.CardAward>
      {/* </motion.div>
          <motion.div style={{width:'calc(100% * (1/4))'}} 
            //  animate={animacao}
            //  transition={transicao}
             > */}
      <S.CardAward>
        <S.HeadAward bg="radial-gradient(79.29% 79.29% at 49.87% 45.36%, #E8D316 0%, #A69F00 100%);">
          KENO
        </S.HeadAward>
        <S.BodyAward>{formatNumberToReal(Keno)}</S.BodyAward>
      </S.CardAward>
      {/* </motion.div>
        </motion.div> */}
    </S.ContainerAward>
  );
};
