import React, { useState } from "react";
import { useHistory } from "react-router";
import * as S from "../pages/Styles/MeuSorteioStyle";
import ModalQRCODE from "./ModalQRCODE";

type modal = {
  openCam: boolean;
  OpenCamera: () => void;
};

export const ModalQrCodeComponent = ({ openCam, OpenCamera }: modal) => {
  const [query, setQuery] = useState("");
  const history = useHistory();

  return openCam ? (
    <div
      style={{
        width: "100%",
        height: "100vh",
        position: "fixed",
        zIndex: "21",
        display: "flex",
        justifyContent: "center",
        alignItems:"center",
        background: "#0c0c0cad",
        top:0,
      }}
    >
      <S.ConteinerCamera>
        <S.InsideContainerCamera>
          <S.ContainerButtonCC>
            <S.BtnCloseCamera onClick={() => OpenCamera()}>
              {"<"}
            </S.BtnCloseCamera>
            <S.TextBilhete>LER QRCODE</S.TextBilhete>
            <div style={{ width: "(1/3)*100%" }}></div>
          </S.ContainerButtonCC>

          <ModalQRCODE />
        </S.InsideContainerCamera>
      </S.ConteinerCamera>
    </div>
  ) : (
    <></>
  );
};

export default ModalQrCodeComponent;
