/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useEffect, useState } from "react";
import { Offline } from "react-detect-offline";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import "../../App.css";
import CardSorteioBilhete from "../../components/CardSorteioBilhete";
import CheckNetwork from "../../components/CheckNetwork";
import Header from "../../components/Header";
import ModalQrCodeComponent from "../../components/ModalQrCodeComponent";
import NomeServidor from "../../components/NomeServidor";
import SearchGroup from "../../components/SearchGroup";
import TabelaComTituloSwipe from "../../components/TabelaComTituloSwipe";
import { CardSorteTv } from "../../components/TVcomponent/CardSorteTv";
import useInterval from "../../hooks/useIntervals";
import useWindowSize from "../../hooks/useWindowSize";
import { reset } from "../../store/modules/bilhete/actions";
import { webSocketService } from "../../WebRequests/requestWebSocket";
import * as S from "../Styles/MeuSorteioStyle";

function useQueryTV() {
  return new URLSearchParams(useLocation().search);
}

export function MeuSorteio() {
  const [nextSorts, setNextSorts] = useState<ISorteio[]>();
  const [nextNormal, setNormal] = useState<ISorteio[]>();
  const [nextSpecials, setSpecials] = useState<ISorteio[]>();
  const [NextSuper, setSuper] = useState<ISorteio[]>();
  const [Vencedores, setVencedores] = useState<IVencedoresHistorico[]>();

  const [DataHora, setDataHora] = useState("");
  const [query, setQuery] = useState("");
  const [openCamera, setOpenCamera] = useState(false);
  const [cartelas, setCartelas] = useState<IMinhasCartelas>();
  const history = useHistory();
  const [timeUpdate, settimeUpdate] = useState(3000);
  const [isOpenMyCards, setIsOpenMyCards] = useState<boolean>(false);
  const { width, height } = useWindowSize();
  const [bilhete, setBilhete] = useState<string | null>("");
  const [queryTV, setQueryTV] = useState("");
  const [connectServer, setConnectServer] = useState(true);

  let modeTV = useQueryTV();

  let tvQuery = useQuery().get("tv");

  useEffect(() => {
    if (tvQuery) {
      setQueryTV(tvQuery);
    }
  }, [tvQuery]);
  let { getWebSocket, lastMessage, readyState } = webSocketService(
    tvQuery ? tvQuery : ""
  );

  //const bilhetes: IBilheteProps[] = useSelector((state: any) => state.bilhetes);

  const dispatch = useDispatch();

  const bilhetes: IBilheteSorteio[] = useSelector(
    (state: any) => state.bilhetes.lista
  );

  if (bilhetes === undefined) {
    dispatch(reset());
  }

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let bilheteQuery = useQuery().get("bilhete");
  let puleQuery = useQuery().get("pule");

  useEffect(() => {
    if (bilheteQuery) {
      // setBilhete(bilheteQuery);
      // listaCartelas(bilheteQuery);
      // dispatch(getTicketRequest(bilheteQuery));
      history.push("/sorteiousuario?codigo=" + bilheteQuery);
    }
    if (puleQuery) {
      //redirecionamento para página de SorteioUsuario

      history.push("/sorteiousuario?codigo=" + puleQuery);
    }
  }, [bilheteQuery, puleQuery]);

  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };

  // const listaCartelas = async (bilhete: string) => {
  //   const { data, error } = await GetMinhasCartelas(bilhete);
  //   if (data) {
  //     setCartelas(data.data);
  //     console.log(data.data);
  //   } else console.log(error);
  // };

  useEffect(() => {
    clearCacheData();
    getWebSocket();

    if (window.matchMedia("(display-mode: standalone)").matches) {
      // window.navigator.standalone;
    }
    window.matchMedia("(display-mode: standalone)").matches;
  }, []);

  useEffect(() => {
    if (readyState == 1 || readyState == 0) {
      setConnectServer(true);
    } else {
      setConnectServer(false);
    }
  }, [readyState]);

  useEffect(() => {
    formatMensage();
  }, [lastMessage]);

  const reconectWebsocket = () => {
    if (Offline) return getWebSocket();
  };

  useInterval(() => {
    reconectWebsocket();
  }, 10000);

  async function formatMensage() {
    if (!lastMessage) {
      return;
    }
    const mensage = await JSON.parse(lastMessage?.data);
    if (mensage.type == "proximos_sorteios") {
      if (mensage?.sorteios.length > 0) {
        setNormal(mensage?.sorteios);
        setDataHora(mensage?.datahora);
      }
    }
    if (mensage.type == "proximos_especiais") {
      if (mensage?.sorteios.length > 0) {
        setSpecials(mensage?.sorteios);
        setDataHora(mensage?.datahora);
      }
    }
    if (mensage.type == "proximos_s_especiais") {
      if (mensage?.sorteios.length > 0) {
        setSuper(mensage?.sorteios);
        setDataHora(mensage?.datahora);
      }
    }
    if (mensage.type == "vencedores") {
      if (mensage?.vencedores.length > 0) {
        setVencedores(mensage?.vencedores);
      }
    }
    if (mensage.type == "sorteio") {
      if (queryTV) {
        history.push(`/${mensage.id_sorteio}?tv=${queryTV}`);
      }
    }
  }

  useInterval(() => {}, timeUpdate);

  // const mostrarMinhasCartelas = () => {
  //   setIsOpenMyCards(!isOpenMyCards);
  // };

  const AccSorteio = (
    normal?: ISorteio[],
    especial?: ISorteio[],
    superEspecial?: ISorteio[]
  ) => {
    let Sorteios: ISorteio[] = [];
    if (normal)
      normal.map((val) => {
        Sorteios.push(val);
      });

    if (especial)
      especial.map((val) => {
        Sorteios.push(val);
      });

    if (superEspecial)
      superEspecial.map((val) => {
        Sorteios.push(val);
      });
    return Sorteios;
  };

  return (
    <S.Conteiner style={{ position: "absolute", minWidth: "100vw" }}>
      {!queryTV ? (
        <Header tipo="menuMain" setModal={() => setOpenCamera(!openCamera)} />
      ) : (
        <SearchGroup
          width="8rem"
          tv={true}
          useQueryTVParams={tvQuery ? tvQuery : ""}
        />
      )}

      {width >= 900 ? (
        <>
          {bilhete ? (
            <>
              <CardSorteioBilhete
                datahora={DataHora}
                titulo={`Sorteio do Bilhete - ${bilhete} `}
                cor={"#F0D50C"}
                bilheteInicio={true}
              />
            </>
          ) : (
            <>
              <CardSorteTv
                sortList={AccSorteio(nextNormal, nextSpecials, NextSuper)}
                vencedores={Vencedores}
                datahora={DataHora}
              />
            </>
          )}
        </>
      ) : (
        <S.MidContainer style={{ flexDirection: "column" }}>
          {bilhete ? (
            <>
              <CardSorteioBilhete
                datahora={DataHora}
                titulo={`Sorteio do Bilhete - ${bilhete} `}
                cor={"#F0D50C"}
                bilheteInicio={true}
              />
            </>
          ) : (
            <>
              <ModalQrCodeComponent
                openCam={openCamera}
                OpenCamera={() => setOpenCamera(!openCamera)}
              />

              <TabelaComTituloSwipe
                lista={nextNormal}
                titulo={"NORMAL"}
                cor={"#F0D50C"}
                datahora={DataHora}
              />
              <TabelaComTituloSwipe
                lista={nextSpecials}
                titulo={"ESPECIAL"}
                cor={"#16890C"}
                datahora={DataHora}
              />
              <TabelaComTituloSwipe
                lista={NextSuper}
                titulo={"SUPER ESPECIAL"}
                cor={"#C32424"}
                datahora={DataHora}
              />
            </>
          )}
        </S.MidContainer>
      )}

      {/* {width < 1024 && (
        <MinhasCartelas
          isOpenMyCards={isOpenMyCards}
          setIsOpenMyCards={setIsOpenMyCards}
          MinhasCartelas={cartelas}
        />
      )} */}
      <NomeServidor />
      <CheckNetwork connectServer={connectServer} />
    </S.Conteiner>
  );
}

export default MeuSorteio;
