import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import * as Style from "../componentsStyle/CardComponentStyle";
import { currentValueFormated } from "../hooks/anticipatedValue";
import { formatDate, timerSort } from "../hooks/formatTime";
import useInterval from "../hooks/useIntervals";

interface  CardMeuSorteioProps {
  sorteio: ISorteio;
  dataServidor?: string;
  dataPartida?: string;
  bilheteRequest?: string;
  cartelas: ICartelas[];
  vencedora: boolean;
}

const CardMeuSorteio: React.FC<CardMeuSorteioProps> = ({
  sorteio,
  dataServidor,
  dataPartida,
  bilheteRequest,
  cartelas,
  vencedora,
}) => {
  var template = "primaryNormal";
  const [count, setcount] = useState(0);
  const [codigo, setCodigo] = useState<number | undefined>(
    sorteio ? sorteio?.codigo : 0
  );

  useInterval(() => {
    setcount(count + 1);
  }, 1000);

  const history = useHistory();

  const formatLocalePrice = (value: string) => {
    const valueInt: number = +value;
    return valueInt.toLocaleString("pt-br", { minimumFractionDigits: 2 });
  };

  useEffect(() => {}, []);
  if (codigo && sorteio) {
    if (sorteio?.tipo_rodada === 1) {
      if (codigo % 2 === 0) {
        template = "primaryNormal";
      }
      if (codigo % 2 !== 0) {
        template = "secondaryNormal";
      }
      // if (codigo % 3 === 0) {
      //   template = "tertiaryNormal";
      // }
    }
    if (sorteio?.tipo_rodada === 2) {
      if (codigo % 2 === 0) {
        template = "primarySpecial";
      }
      if (codigo % 2 !== 0) {
        template = "secondarySpecial";
      }
    }
    if (sorteio?.tipo_rodada === 3) {
      template = "primarySuperSpecial";
    }
  }

  return (
    <Style.Container className={template}>
      <Style.Header>
        <Style.HeaderLeft>
          <span>SORTEIO:</span>
          <h3>{sorteio?.codigo}</h3>
        </Style.HeaderLeft>

        {vencedora && (
          <Style.HeaderCenter>
            <span>BILHETE PREMIADO</span>
          </Style.HeaderCenter>
        )}

        <Style.HeaderRight>
          <h5>CARTELAS DE:</h5>
          <h5>
            {cartelas[0].codigo + " À " + cartelas[cartelas.length - 1].codigo}{" "}
          </h5>
        </Style.HeaderRight>
      </Style.Header>
      <Style.Content>
        <Style.ContentTop>
          <Style.Award>
            <span>KUADRA</span>
            <h3>{formatLocalePrice(sorteio ? sorteio.valor_kuadra : "")}</h3>
          </Style.Award>
          <Style.Award className="keno">
            <span>KENO</span>
            <h3>{formatLocalePrice(sorteio ? sorteio.valor_keno : "")}</h3>
          </Style.Award>
          <Style.Award>
            <span>KINA</span>
            <h3>{formatLocalePrice(sorteio ? sorteio.valor_kina : "")}</h3>
          </Style.Award>
        </Style.ContentTop>
        <Style.ContentBottom>
          <span>
            {"DATA: " +
              format(new Date(sorteio?.data_partida), "dd/MM") +
              " - HORA: " +
              format(new Date(sorteio?.data_partida), "HH:mm:ss")}
          </span>
          <span>{"DOAÇÃO: R$ " + currentValueFormated(sorteio)}</span>
        </Style.ContentBottom>
      </Style.Content>
      <Style.Footer>
        <span>
          {dataServidor && dataPartida && (
            <>
              {sorteio.status == "agora" || sorteio.status == "replay"
                ? "SORTEIO PRONTO"
                : timerSort(
                  formatDate(dataPartida),
                  formatDate(dataServidor),
                  count
                )}
            </>
          )}
        </span>

        {sorteio.status == "agora" || sorteio.status == "replay" ? (
          <button onClick={() => history.push(`/${sorteio.codigo}`)}>
            ASSISTIR AGORA
          </button>
        ) : (
          <button
            className="green"
            onClick={() =>
              history.push(
                `/sorteionaoaconteceu/?bilhete=${bilheteRequest}&data_partida=${sorteio.data_partida}&sorteio=${sorteio.codigo}`
              )
            }
          >
            VER SORTEIO
          </button>
        )}
      </Style.Footer>
    </Style.Container>
  );
};

export default CardMeuSorteio;
