interface ISorteiosSelecionados {
  sorteio: ISorteio;
  quantidade: number;
}

export function addToCart(item: ISorteiosSelecionados) {
  return {
    type: "@cart/ADD",
    payload: { item }
  }
}

export function cleanCart() {
  return {
    type: '@cart/CLEAN_CART',
  }
}

export function updateAmount(codigo: number, quantidade: number) {
  return {
    type: '@cart/UPDATE_AMOUNT',
    codigo,
    quantidade
  }
}

export function removeToCart(sorteios: string[]) {
  return {
    type: "@cart/REMOVE_TO_CART",
    payload: { sorteios }
  }
}

export function remove(id: number) {
  return {
    type: "@cart/REMOVE",
    payload: { id }
  }
}