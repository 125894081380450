/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-redeclare */

import React, { useEffect, useState } from "react";
import * as S from "../style";
import ReplacePadStart from "../Utils/ReplacePadStart";

type Cart = {
  cartelas?: ICartelas[] | undefined;
  numerosSorteados: number[];
  turno: number;
};

export const Cart = ({ cartelas, numerosSorteados, turno }: Cart) => {
  const [CartList, setCartList] = useState<ICartelas[]>();

  const estabelecimentoNome = (nome?: string) => {
    if (nome) return nome.length > 12 ? nome.substring(0, 13) + "..." : nome;
    else "";
  };

  useEffect(() => {}, [turno]);

  return (
    <>
      {cartelas &&
        cartelas?.slice(0, 4).map((cart, index) => (
          <S.Cart key={index}>
            <S.CartContent>
              <S.CartHeader>
                <S.CartCodigo>{cart.codigo}</S.CartCodigo>
                <S.CartEstab>
                  {estabelecimentoNome(cart.estabelecimento)}
                </S.CartEstab>
              </S.CartHeader>
              <S.CartRow>
                {cart.linha1_lista?.map((x, index) => (
                  <S.ContentCartNumber key={`linha1_lista${x}_${index}`}>
                    {numerosSorteados
                      .slice(0, turno)
                      .find((bolas) => bolas === x) === undefined ? (
                      <S.CartNumber>
                        {ReplacePadStart(x.toString(), 2)}
                      </S.CartNumber>
                    ) : (
                      <S.CartNumberMarked>
                        {ReplacePadStart(x.toString(), 2)}
                      </S.CartNumberMarked>
                    )}
                  </S.ContentCartNumber>
                ))}
              </S.CartRow>
              <S.CartRow>
                {cart.linha2_lista?.map((x, index) => (
                  <S.ContentCartNumber key={`linha2_lista${x}_${index}`}>
                    {numerosSorteados
                      .slice(0, turno)
                      .find((bolas) => bolas === x) === undefined ? (
                      <S.CartNumber>
                        {ReplacePadStart(x.toString(), 2)}
                      </S.CartNumber>
                    ) : (
                      <S.CartNumberMarked>
                        {ReplacePadStart(x.toString(), 2)}
                      </S.CartNumberMarked>
                    )}
                  </S.ContentCartNumber>
                ))}
              </S.CartRow>
              <S.CartRow>
                {cart.linha3_lista?.map((x, index) => (
                  <S.ContentCartNumber key={`linha3_lista${x}_${index}`}>
                    {numerosSorteados
                      .slice(0, turno)
                      .find((bolas) => bolas === x) === undefined ? (
                      <S.CartNumber>
                        {ReplacePadStart(x.toString(), 2)}
                      </S.CartNumber>
                    ) : (
                      <S.CartNumberMarked>
                        {ReplacePadStart(x.toString(), 2)}
                      </S.CartNumberMarked>
                    )}
                  </S.ContentCartNumber>
                ))}
              </S.CartRow>
              <S.ContentLineCart>
                <S.LineCart />
              </S.ContentLineCart>
            </S.CartContent>
          </S.Cart>
        ))}
    </>
  );
};
