import styled from 'styled-components';

interface VisibleModalProps {
    visibleModalConfirm: boolean;
  }

export const Container = styled.div<VisibleModalProps>`
   width: 90%;
  z-index: 100;
  top: 13rem;
  position: absolute;
  height: auto;
  display: flex;
  flex-direction: column;
  background: radial-gradient(
    116.41% 62.81% at 50% 50%,
    #8f94ce 0%,
    #282a81 100%
  );
  padding: 10px;
  transition: transform 0.2s ease-out;
  border: 2px solid #fff;
  border-radius: 4px;
`;

export const Header = styled.div`
    font-size: 1.4rem;
    color: #fff;
    display: flex;
    align-self: center;
    font-weight: bold;
`;

export const Content = styled.div`
    color: #fff;
`;

export const Footer = styled.div`
    display: flex;
    width: 100%;
    
    button {
    margin: 5px;
    background: radial-gradient(
      79.29% 79.29% at 49.87% 45.36%,
      #1fa71c 0%,
      #0d3107 100%
    );
    border: 2px solid#fff;
    border-radius: 4px;
    width: 100%;
    color: #fff;
    text-align: center;
    height: 3rem;
    font-size: 1.2rem;
    cursor: pointer;
    :hover {
      opacity: 0.9;
    }
  }
`;