import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "../../../App.css";
import { Award } from "../../../components/award";
import { Cart } from "../../../components/cart";
import MinhasCartelas from "../../../components/Cartelas";
import HeaderGame from "../../../components/HeaderGame";
import MeusSorteiosModal from "../../../components/MeusSorteiosModal";
import { Table } from "../../../components/Table";
import { TubeBall } from "../../../components/tubeBall";
import { cartSelector } from "../../../hooks/cartSelector";
import * as S from "../../../style";
import ReplacePadStart from "../../../Utils/ReplacePadStart";
import * as B from "../../Styles/BlocoBolasSorteadas";

export type Cartela = IPartida["cartelas"][0] & IPartida["turnos"][0];

interface IGameProps {
  sorteio: IPartida;
  turnoAtual: Cartela[];
  turno: number;
  timeUpdate: number;
  isKeno: boolean;
  ativar_audio: () => void;
}

const GameDefault: React.FC<IGameProps> = ({
  sorteio,
  turnoAtual,
  turno,
  timeUpdate,
  isKeno,
  ativar_audio,
}) => {
  const [isOpenMyCards, setIsOpenMyCards] = useState<boolean>(false);
  const [isOpenMySorts, setIsOpenMySorts] = useState<boolean>(true);
  const [muted, setMuted] = useState(false);

  const ReplayComponent = () => {
    if (sorteio.status.charAt(0).trim() === "r") {
      return (
        <S.DivReplay>
          <S.ContainerReplay>
            <S.TextReplay>REPLAY</S.TextReplay>
          </S.ContainerReplay>
        </S.DivReplay>
      );
    } else {
      return <S.TextReplay></S.TextReplay>;
    }
  };
  const history = useHistory();
  const [CartList, setCartList] = useState<ICartelas[]>();
  const [CartTurno, setCartTurno] = useState<ICartResp[]>();
  const [TurnoCartelasIni, setTurnoCartelasIni] = useState(0);
  const [data, setdata] = useState<ICart>();

  const CartVisible = async (data: ICart) => {
    const res = await cartSelector(data);
    setCartList(res.cart);
    setTurnoCartelasIni(res.TurnoCartelasIni);
    setdata({
      cartCompradas: sorteio.cartelas_compradas,
      turnos: sorteio.turnos,
      carts: sorteio.cartelas,
      turno: turno,
      bolasSorteadas: sorteio.bolas_sorteadas,
      kina: sorteio.bola_kina,
      turnoCartelasIni: TurnoCartelasIni,
    });
  };

  useEffect(() => {
    CartVisible({
      cartCompradas: sorteio.cartelas_compradas,
      turnos: sorteio.turnos,
      carts: sorteio.cartelas,
      turno: turno,
      bolasSorteadas: sorteio.bolas_sorteadas,
      kina: sorteio.bola_kina,
      turnoCartelasIni: TurnoCartelasIni,
    });
  }, [turno]);

  return (
    <S.Container>
      <HeaderGame
        valor_acumulado={sorteio.valor_acumulado}
        numero_bolas_acumulado={sorteio.numero_bolas_acumulado}
        sorteio_codigo={sorteio.codigo}
        numero_bolas={turno}
        AbrirMinhasCartelas={() => {
          setIsOpenMyCards(!isOpenMyCards);
          !isOpenMySorts && setIsOpenMySorts(!isOpenMySorts);
        }}
        AbrirMeusSorteios={() => {
          setIsOpenMySorts(!isOpenMySorts);
          !isOpenMyCards && setIsOpenMyCards(false);
        }}
      />
      <S.ContentAudio
        onClick={() => {
          ativar_audio();
          setMuted(!muted);
        }}
      >
        {muted ? <S.GoMuteIcon /> : <S.VolumeMuteIcon />}
      </S.ContentAudio>
      <TubeBall
        ballKeno={sorteio.bola_keno}
        ball={sorteio.bolas_sorteadas}
        turno={turno}
        tempoUpdate={timeUpdate}
      />
      <ReplayComponent />
      <MinhasCartelas
        sorteio={sorteio}
        vencedores={[
          ...sorteio.vencedores_keno,
          ...sorteio.vencedores_kina,
          ...sorteio.vencedores_kuadra,
        ]}
        isOpenMyCards={isOpenMyCards}
        setIsOpenMyCards={() => setIsOpenMyCards(!isOpenMyCards)}
        bolasTurno={sorteio.bolas_sorteadas.slice(0, turno)}
        turno={turno}
      />
      <MeusSorteiosModal
        isOpenMySorts={isOpenMySorts}
        setIsOpenMySorts={() => setIsOpenMySorts(!isOpenMySorts)}
        showButton={false}
      />

      {!isOpenMyCards && (
        <>
          {" "}
          <Award
            doacao={sorteio.valor_cartela}
            kuadra={sorteio.valor_kuadra}
            Kina={sorteio.valor_kina}
            Keno={sorteio.valor_keno}
          />
          <S.CartHolder>
            {/* {turnoAtual.slice(0, 4).map((cart, index) => {
          return ( */}
            <Cart
              cartelas={CartList}
              numerosSorteados={sorteio.bolas_sorteadas}
              turno={turno + 1}
            />
            {/* );
        })} */}
          </S.CartHolder>
          <div
            style={{ width: "100%", padding: "0 0.5rem", margin: "0.5rem 0" }}
          >
            <Table dados={turnoAtual} keno={isKeno} />
          </div>
          {/*Parte para Tabela de bolas já sorteadas*/}
          <B.ContainerBolasSorteadas>
            <h3 style={{ textAlign: "center" }}>Bolas Sorteadas</h3>
            <B.BlocoBalls>
              {[...Array(90)].map((x, index) => {
                if (
                  sorteio?.bolas_sorteadas
                    .slice(0, turno)
                    .find((sorteio) => sorteio === index + 1) !== undefined
                ) {
                  return (
                    <B.ContentBalls className="BallsDrawed" key={index}>
                      <B.Balls Ativo={true}>
                        {ReplacePadStart((index + 1).toString(), 2)}
                      </B.Balls>
                    </B.ContentBalls>
                  );
                } else {
                  return (
                    <B.ContentBalls className="BallsDrawed" key={index}>
                      <B.Balls Ativo={false}>
                        {ReplacePadStart((index + 1).toString(), 2)}
                      </B.Balls>
                    </B.ContentBalls>
                  );
                }
              })}
              {/* {sorteio.bolas_sorteadas.slice(0, turno).map((x, index) =>{
                return (
                    <li className='BallsDrawed' key={index}>
                      <h2>{sorteio.bolas_sorteadas}</h2> 
                    </li>
                )
            })} */}
            </B.BlocoBalls>
          </B.ContainerBolasSorteadas>
        </>
      )}
    </S.Container>
  );
};

export default GameDefault;
