import React, { Dispatch, SetStateAction, useState } from "react";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";
import * as Style from "../componentsStyle/ModalCodigoConfirmacao";

interface CodigoConfirmacaoProps {
  codigo: string | undefined;
  visibleModal: boolean;
  mensagemSorteio: string | undefined;
  setVisibleModal: Dispatch<SetStateAction<boolean>>;
  setVisibleShare: Dispatch<SetStateAction<boolean>>;
  setTelefone_digitado: Dispatch<SetStateAction<number>>;
}
const InputMaskTel = (props: any) => {
  return (
    <InputMask
      mask="(99) 99999-9999"
      value={props.value}
      onChange={props.onChange}
      type="tel"
      placeholder={"(99) 99999-9999"}
    >
      {(inputProps: any) => <Style.Input {...inputProps} />}
    </InputMask>
  );
};
const ModalCodigoConfirmacao: React.FC<CodigoConfirmacaoProps> = ({
  codigo,
  visibleModal,
  mensagemSorteio,
  setVisibleModal,
  setVisibleShare,
  setTelefone_digitado,
}) => {
  const [telefone, settelefone] = useState("");
  return (
    <Style.ContainerModal visibleModal={visibleModal}>
      <Style.Container>
        <Style.Header>
          <button onClick={() => setVisibleModal(false)}>
            <Style.ArrowBackIosNewIcon />
          </button>
          <span>CÓDIGO DE CONFIRMAÇÃO:</span>
        </Style.Header>
        <Style.Content>
          {codigo ? (
            <div
              className="box"
              onClick={() => {
                navigator.clipboard.writeText(codigo);
                toast.success("Código copiado");
              }}
            >
              {" "}
              {codigo}{" "}
            </div>
          ) : (
            <Style.SSkeletonPulse style={{ height: "3rem", width: "100%" }} />
          )}
          <Style.Title>Número de telefone:</Style.Title>
          {codigo ? (
            <>
              <InputMaskTel
                onChange={(e: any) => {
                  setTelefone_digitado(e.target.value);
                  settelefone(e.target.value);
                }}
              />
              <button
                onClick={() => {
                  if (telefone.replace(/[^\d]/g, "").length == 11) {
                    setVisibleShare(true);
                  } else {
                    toast.error("Digite um número de telefone válido");
                  }
                }}
              >
                COMPARTILHAR
              </button>
            </>
          ) : (
            <button style={{ background: "gray" }}>COMPARTILHAR</button>
          )}

          {codigo ? (
            <div className="box2">{mensagemSorteio}</div>
          ) : (
            <Style.SSkeletonPulse
              style={{ height: "8rem", width: "100%", marginTop: "1rem" }}
            />
          )}
        </Style.Content>
      </Style.Container>
    </Style.ContainerModal>
  );
};

export default ModalCodigoConfirmacao;
