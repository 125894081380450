import styled from "styled-components";

export const Container = styled.div`
  width: 20rem;
  height: 14rem;
  align-content: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: radial-gradient(
    116.41% 62.81% at 50% 50%,
    #111040 100%,
    #1b10b1 100%
  );
  padding: 10px;
  transition: transform 0.2s ease-out;
  border: 5px solid #ffff;
  border-radius: 5px;
  position: fixed;
  align-self: center;
  top: 10rem;
  z-index: 100000;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    margin: 10px;
    color: #fff;
    font-size: 1.5rem;
    margin-left: 5px;
    font-weight: bold;
    width: 100%
    
  }
`;
