import React, { useEffect, useState } from "react";
import { Dispatch, SetStateAction } from "react";
import {
  Container,
  Title,
  Content,
  Card,
  HeaderCard,
  CardNumber,
  NomeEstabelecimento,
  ContainerNumbers,
  Number,
  Footer,
  Scroll,
  Icon,
  WhiteBox,
  TitleWhite,
} from "../pages/Styles/MinhasCartelasBottom";
import { reset } from "../store/modules/bilhete/actions";

import { estabelecimentoNome } from "../hooks/formatString";
import { useSelector, useDispatch } from "react-redux";
import { CardPremiacao } from "./MinhasCartelas";
import useInterval from "../hooks/useIntervals";
import vericationPosicao from "../hooks/sortCartelas";


interface IMinhasCartelasProps {
  isOpenMyCards: boolean;
  setIsOpenMyCards: Dispatch<SetStateAction<boolean>>;
  MinhasCartelas?: IMinhasCartelas;
  bolasTurno?: number[];
  vencedores?: number[];
  sorteio?: IPartida;
  codigoSorteio?: string | null;
  codigoBilhete?: string | null;
}

export default function MinhasCartelasBottom({
  isOpenMyCards,
  setIsOpenMyCards,
  MinhasCartelas,
  bolasTurno,
  sorteio,
  codigoSorteio,
  codigoBilhete,
}: IMinhasCartelasProps) {
  const [numerosCartelas, setNumerosCartelas] = useState<number[]>();
  

  const vencedorCartelaKuadra = (codigo: number) => {
    if (bolasTurno?.find((val) => sorteio?.bola_kuadra == val) !== undefined)
      if (sorteio?.vencedores_kuadra.find((val) => codigo == val)) return true;
      else return false;
    else return false;
  };

  const vencedorCartelaKina = (codigo: number) => {
    if (bolasTurno?.find((val) => sorteio?.bola_kina == val) !== undefined)
      if (sorteio?.vencedores_kina.find((val) => codigo == val)) return true;
      else return false;
    else return false;
  };

  const vencedorCartelaKeno = (codigo: number) => {
    if (bolasTurno?.find((val) => sorteio?.bola_keno == val) !== undefined)
      if (sorteio?.vencedores_keno.find((val) => codigo == val)) return true;
      else return false;
    else return false;
  };

  const dispatch = useDispatch();
  const [bilhete, setBilhete] = useState<IBilheteSorteio>();
  const bilhetes: IBilheteSorteio[] = useSelector((state: any) => state.bilhetes.lista);
  

  if (bilhetes === undefined) {
    dispatch(reset());
  } 

  useEffect(() => {
    if (bilhetes.length > 0) {
      if (codigoSorteio) {
        const bilheteSorteio = bilhetes.filter(
          (item: any) => item.hash === codigoBilhete
        );
        setBilhete(bilheteSorteio[0]);
      }
    }
  }, [bilhetes]);

  const showCards = () => {
    setIsOpenMyCards(!isOpenMyCards);
  };

  const vericacaoPosicao = (
    bolasTurno: number[],
    linha1: number[],
    linha2: number[],
    linha3: number[],
    kina?: number,
    kuadra?: number
  ) => {
    let num: number = 0;
    let linha_1: number = 0;
    let linha_2: number = 0;
    let linha_3: number = 0;

    if (kina && kuadra && bolasTurno) {
      if (
        bolasTurno.find((x) => x == kuadra) === undefined ||
        bolasTurno.find((x) => x == kina) === undefined
      ) {
        linha1.map(
          (val) =>
            bolasTurno.find((bola) => bola == val) !== undefined &&
            (linha_1 = linha_1 + 1)
        );
        linha2.map(
          (val) =>
            bolasTurno.find((bola) => bola == val) !== undefined &&
            (linha_2 = linha_2 + 1)
        );
        linha3.map(
          (val) =>
            bolasTurno.find((bola) => bola == val) !== undefined &&
            (linha_3 = linha_3 + 1)
        );
        if (linha_1 > linha_2 && linha_1 > linha_3) return (num = linha_1);
        if (linha_2 > linha_1 && linha_2 > linha_3) return (num = linha_2);
        if (linha_3 > linha_1 && linha_3 > linha_2) return (num = linha_3);
      } else {
        [...linha1, ...linha2, ...linha3].map(
          (val) =>
            bolasTurno.find((bola) => bola == val) !== undefined &&
            (num = num + 1)
        );
      }
    }
    // if(bolasTurno){
    //     [...linha1,...linha2,...linha3].map((val)=>
    //         bolasTurno.find(bola=> bola == val) !== undefined && (num = num + 1)
    //     )
    // }
    return num;
  };

  return (
    <Container isOpen={isOpenMyCards}>
      <Title>Minhas Cartelas: {bilhete?.cartelas?.length}</Title>

      <WhiteBox>
        <TitleWhite>{"SORTEIO: "}</TitleWhite>
        <Title style={{ fontSize: "1.5rem", lineHeight: "1.1" }}>
          {bilhete?.sorteio.codigo}
        </Title>
      </WhiteBox>
      {!bolasTurno && (
        <Content>
          {bilhete?.cartelas ? (
            <>
              {bilhete?.cartelas?.map((cartela) => (
                <Card key={`cartela_div_${cartela.codigo}`}>
                  <HeaderCard>
                    <CardNumber>{cartela.codigo}</CardNumber>
                    <NomeEstabelecimento>
                      {cartela?.estabelecimento
                        ? estabelecimentoNome(cartela?.estabelecimento, 15)
                        : ""}
                    </NomeEstabelecimento>
                  </HeaderCard>
                  <ContainerNumbers>
                    {cartela.linha1_lista.map((number, index) => (
                      <Number
                        key={`cartela_${cartela.linha1_lista}_number_${number}`}
                      >
                        {number}
                      </Number>
                    ))}
                    {cartela.linha2_lista.map((number, index) => (
                      <Number
                        key={`cartela_${cartela.linha1_lista}_number_${number}`}
                      >
                        {number}
                      </Number>
                    ))}
                    {cartela.linha3_lista.map((number, index) => (
                      <Number
                        key={`cartela_${cartela.linha1_lista}_number_${number}`}
                      >
                        {number}
                      </Number>
                    ))}
                  </ContainerNumbers>
                  <div id="line" />
                </Card>
              ))}
            </>
          ) : (
            <>
              <span style={{ color: "white" }}>Cartela vazia</span>
            </>
          )}
        </Content>
      )}
      {bolasTurno && (
        <Content>
          {bilhete?.cartelas ? (
            <Scroll>
              {bilhete?.cartelas
                ?.sort((a, b) => {
                  return (
                    vericacaoPosicao(
                      bolasTurno,
                      b.linha1_lista,
                      b.linha2_lista,
                      b.linha3_lista,
                      sorteio?.bola_kina,
                      sorteio?.bola_kuadra
                    ) -
                    vericacaoPosicao(
                      bolasTurno,
                      a.linha1_lista,
                      a.linha2_lista,
                      a.linha3_lista,
                      sorteio?.bola_kina,
                      sorteio?.bola_kuadra
                    )
                  );
                })
                .map((cartela) => (
                  <Card key={`cartela_div_${cartela.codigo}`}>
                    <HeaderCard>
                      <CardNumber>{cartela.codigo}</CardNumber>
                      <NomeEstabelecimento>
                        {cartela?.estabelecimento
                          ? estabelecimentoNome(cartela?.estabelecimento, 15)
                          : ""}
                      </NomeEstabelecimento>
                    </HeaderCard>
                    {}
                    <ContainerNumbers>
                      {cartela.linha1_lista.map((number, index) =>
                        bolasTurno?.find((bolas) => bolas == number) ===
                        undefined ? (
                          <Number
                            key={`cartela_${cartela.linha1_lista}_number_${number}`}
                          >
                            {number}
                          </Number>
                        ) : (
                          <Number
                            style={{ background: "#008000" }}
                            key={`cartela_${cartela.linha1_lista}_number_${number}`}
                          >
                            {number}
                          </Number>
                        )
                      )}
                      {cartela.linha2_lista.map((number, index) =>
                        bolasTurno?.find((bolas) => bolas == number) ==
                        undefined ? (
                          <Number
                            key={`cartela_${cartela.linha3_lista}_number_${number}`}
                          >
                            {number}
                          </Number>
                        ) : (
                          <Number
                            style={{ background: "#008000" }}
                            key={`cartela_${cartela.linha2_lista}_number_${number}`}
                          >
                            {number}
                          </Number>
                        )
                      )}
                      {cartela.linha3_lista.map((number, index) =>
                        bolasTurno?.find((bolas) => bolas == number) ===
                        undefined ? (
                          <Number
                            key={`cartela_${cartela.linha3_lista}_number_${number}`}
                          >
                            {number}
                          </Number>
                        ) : (
                          <Number
                            style={{ background: "#008000" }}
                            key={`cartela_${cartela.linha3_lista}_number_${number}`}
                          >
                            {number}
                          </Number>
                        )
                      )}
                    </ContainerNumbers>
                    <div id="line" />
                    {cartela.codigo ? (
                      <div style={{ display: "flex", padding: "0.2rem" }}>
                        <CardPremiacao
                          tipo="kuadra"
                          isPremio={vencedorCartelaKuadra(cartela.codigo)}
                        />
                        <CardPremiacao
                          tipo="kina"
                          isPremio={vencedorCartelaKina(cartela.codigo)}
                        />
                        <CardPremiacao
                          tipo="keno"
                          isPremio={vencedorCartelaKeno(cartela.codigo)}
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                  </Card>
                ))}
            </Scroll>
          ) : (
            <>
              <span style={{ color: "white" }}>Cartela vazia</span>
            </>
          )}
        </Content>
      )}
    </Container>
  );
}
