import styled from "styled-components";
import { TriangleLeft } from "@styled-icons/octicons/TriangleLeft";
import { TriangleRight } from "@styled-icons/octicons/TriangleRight";

export const Conteiner = styled.div`
  font-size: 1rem;
  border-radius: 0 0;
  background: radial-gradient(
    79.29% 79.29% at 49.87% 45.36%,
    #8f94ce 0%,
    #282a81 100%
  );
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-bottom: 4rem;
`;

export const TopConteiner = styled.div`
  padding: 1rem;
  background: radial-gradient(
    79.29% 79.29% at 49.87% 45.36%,
    #1b10b1 0%,
    #111040 100%
  );
  display: flex;
  justify-content: space-between;

  @media (min-width: 1024px) {
  }
`;

export const ContainerCard = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;

export const ConteinerCamera = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 95%;
  height: 35rem;
  z-index: 2;
  border-radius: 15px;
`;

export const InsideContainerCamera = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 4px solid white;
  flex-direction: column;
  background: radial-gradient(
    116.41% 62.81% at 50% 50%,
    #5993d4 0%,
    #203d88 100%
  );
  width: 95%;
  border-radius: 15px;
`;

export const ContainerButtonCC = styled.div`
  width: 90%;
  border-radius: 15px;
  justify-content: space-between;
  color: white;
  outline: none;
  border: none;
  display: flex;
  margin-top: 1rem;
`;

export const BtnCloseCamera = styled.button`
  width: 3rem;
  height: 2.5rem;
  background: radial-gradient(
    397.62% 397.62% at 51.13% -283.33%,
    #1b10b1 0%,
    #111040 100%
  );
  border-radius: 10px;
  border: 0.5px solid white;
  align-items: center;
  justify-content: center;
  color: white;
  outline: none;
  display: flex;
  font-weight: 900;
  font-size: 1.4rem;
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 1rem 0.5rem;
  grid-area: logo;
`;

export const SearchContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const FormContainer = styled.div`
  grid-area: search;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f0d50c;
  border-radius: 5px 5px 5px 5px;
  border: none;
  width: 100%;
  height: 2.5rem;
  border: white solid 1px;
  @media (min-width: 1024px) {
    margin-left: 5rem;
    margin-top: 1rem;
    width: 16rem;
  }
`;

export const TextBilhete = styled.text`
  color: white;
  font-size: 1.3rem;
  font-weight: 900;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 0.1rem;
  text-align: center;
`;

export const ButtonComprar = styled.button`
  background: radial-gradient(
    79.29% 79.29% at 49.87% 45.36%,
    #1fa71c 0%,
    #0d3107 100%
  );
  height: 2.5rem;
  grid-area: comprar;
  border: solid white 1px;
  border-radius: 5px;
  color: white;
  font-size: 1.2rem;
`;

export const buttonMeuSorteio = styled.div`
  grid-area: contSort;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;

  @media (min-width: 1024px) {
    margin-right: 5rem;
    margin-top: 1rem;
    width: 16rem;
  }
`;

export const FormContainer2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  border: none;
`;

export const FormContainerInputs = styled.div`
  padding-top: 1rem;
  width: 100%;
  display: grid;
  grid-template-areas:
    "logo contSort"
    "logo search"
    "logo comprar";
  grid-template-columns: minmax(auto, 9rem);

  @media (min-width: 1024px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 2.5rem;
  letter-spacing: 0.05rem;
  padding-left: 0.5rem;
  border-radius: 5px;
  border: none;
  outline: none;
`;

export const ButtonMeuSort = styled.button`
  border: 1px solid white;
  line-height: 100%;
  border-radius: 5px;
  font-weight: 500;
  color: white;
  outline: none;
  width: 40%;
  height: 100%;
  min-height: 3rem;
  text-align: center;
  font-size: 1.1rem;
  line-height: 1rem;
  cursor: pointer;
  margin-right: 5px;
  background: radial-gradient(
    79.29% 79.29% at 49.87% 45.36%,
    #1fa71c 0%,
    #0d3107 100%
  );
  transition: opacity ease-in-out 1s all;
  :hover {
    opacity: 0.9;
  }
`;

export const ButtonPad = styled.button`
  width: 100%;
  flex-grow: 1;
  background: radial-gradient(
    79.29% 79.29% at 49.87% 45.36%,
    #1fa71c 0%,
    #0d3107 100%
  );
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 5px;
  color: #ffffff;
  height: 2.5rem;
  font-size: 1rem;
  line-height: 1rem;
  cursor: pointer;
  transition: opacity ease-in-out 1s all;
  :hover {
    opacity: 0.9;
  }
`;

export const LinkContainer = styled.div`
  width: 15%;
  height: 100%;
  font-size: 1.5rem;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const MidContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContainerADS = styled.div`
  margin: 3.5rem 0;
  width: 75%;
  min-height: 65vh;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: space-between;

  flex-direction: row;
  flex-wrap: wrap;
  background: radial-gradient(
    79.29% 79.29% at 49.87% 45.36%,
    #1e1a59 0%,
    #080720 100%
  );
  border-radius: 1rem;
  border: 0.2rem solid #f6f7f2;
  position: relative;
`;

export const HeaderADS = styled.div`
  padding: 2rem 5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;

  flex-wrap: wrap;
  border-radius: 1rem;

  h1 {
    position: absolute;
    top: -1rem;
    width: 15rem;
    font-size: 1.5rem;
    text-align: center;
    background: #ffffff;
    border-radius: 0.5rem;
    color: #120f3a;
  }

  h2 {
    width: 24rem;
    text-align: center;
    font-size: 2.7rem;
    color: #f0d50c;
  }

  div {
    width: 24rem;
    padding-left: 0.4rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    h3 {
      width: 30%;
      font-size: 2rem;
      color: #f6f7f2;
    }

    p {
      margin: 0;
      text-align: end;
      padding-right: 0.4rem;
      width: 70%;
      font-size: 2rem;
      color: #f6f7f2;
      font-weight: 700;
      span {
        padding: 0 0.8rem;
        font-size: 1.5rem;
        color: #f0d50c;
      }
    }
  }
`;

export const ValorADS = styled.div`
  padding: 2rem 2rem 3rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    width: calc(100% * (1 / 3));
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h4 {
      line-height: 1.7rem;
      display: flex;
      font-size: 1.9rem;
      color: #f0d50c;
      span {
        color: #f6f7f2;
      }
    }
  }
`;

export const TitleContainer = styled.div`
  margin-top: 1rem;
  background: radial-gradient(
    79.29% 79.29% at 49.87% 45.36%,
    #1b10b1 0%,
    #111040 100%
  );
  display: flex;
  height: auto;
  justify-content: center;
  align-items: center;
  font-weight: 900;
`;

export const ListContainer = styled.div`
  width: 100%;
`;

export const Tittle = styled.h4`
  font-size: 1.7rem;
  color: #f0d50c;
`;

export const TittleMeuBilhete = styled.text`
  font-size: 1.2rem;
  color: #f0d50c;
`;
export const BoxContainer = styled.div`
  list-style: none;
  background: radial-gradient(
    79.29% 79.29% at 49.87% 45.36%,
    #1e1a59 0%,
    #080720 100%
  );
  padding-top: 5px;
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;
  border-radius: 10px;
  border: 2px solid white;
`;

export const BoxBottomText = styled.div`
  padding: 0.2rem 0;
  background: #f0d50c;
  cursor: pointer;
  width: 100%;
  border-radius: 0px 0px 7px 7px;
  display: flex;
  justify-content: center;
  font-size: 1.7rem;
  font-weight: 800;
`;

export const TextTop = styled.h2`
  padding: 0.2rem 0;
  margin: 0rem;
  font-size: 1.7rem;
  font-style: initial;
  color: #fcfd05;
  margin-top: 0px;
  line-height: 100%;
  font-weight: 700;
`;

export const TextMid = styled(TextTop)`
  padding: 0.2rem 0;
  color: #fcfdfa;
  font-size: 1.8rem;
  font-weight: 800;
`;

export const TextBot = styled(TextTop)`
  color: #fcfdfa;
  font-size: 1.3rem;
`;

export const containerHour = styled.div`
  padding: 0.2rem 1.5rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  p {
    display: flex;
    font-size: 1.4rem;
    color: #fcfdfa;
  }
`;

export const ButtonBack = styled.button`
  width: 3rem;
  height: 2.5rem;
  background: radial-gradient(
    397.62% 397.62% at 51.13% -283.33%,
    #1b10b1 0%,
    #111040 100%
  );
  border-radius: 10px;
  border: 0.5px solid white;
  align-items: center;
  justify-content: center;
  color: white;
  outline: none;
  display: flex;
  font-weight: 900;
  font-size: 1.4rem;
  margin-left: 1rem;
`;

export const BannerConteiner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1rem 0rem 1rem;
`;
export const TextMeuSort = styled.h4`
  color: white;
  text-align: center;
  align-self: center;
  padding-left: 1.2rem;
  width: 40%;
`;

export const ButtonQrCode = styled.button`
  display: flex;
  border-radius: 5px;
  grid-area: contSort;
  width: 40%;
  color: transparent;
  border: white 1px solid;
  background: radial-gradient(
    79.29% 79.29% at 49.87% 45.36%,
    #1fa71c 0%,
    #0d3107 100%
  );
`;

export const TriangleLeftIcon = styled(TriangleLeft)`
  height: 1rem;
  width: 1rem;
`;

export const TriangleRightIcon = styled(TriangleRight)`
  height: 1rem;
  width: 1rem;
`;
