import React from "react";
import { format } from "date-fns";
import { motion } from "framer-motion";
import { formatMoneyNotSDec } from "../../hooks/formatNumber";
import { formatDate } from "../../hooks/formatTime";
import * as S from "../../pages/Styles/AnuncioVencedor";

declare type anuncioProximos = {
    sorteio?: ISorteio[];
}

export const AnuncioProximos: React.FC<anuncioProximos> = ({ sorteio }) => {
    return (
        <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, rotate: 0, scale: 1 }}
            style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column"
            }}
        >
            <S.TitleContent>PRÓXIMOS SORTEIOS</S.TitleContent>
            <S.Container tipo={1}>
                <div>
                    <S.HeaderCard>
                        <S.BlocoHeader t="30%" jC="left">{("data-hora").toUpperCase()}</S.BlocoHeader>
                        <S.BlocoHeader t="40%" jC="center">{("premiação").toUpperCase()}</S.BlocoHeader>
                        <S.BlocoHeader t="30%" jC="right">{("doação").toUpperCase()}</S.BlocoHeader>
                    </S.HeaderCard>
                    <S.BodyCard>
                        {sorteio?.map((val, index) => (
                            <S.LineCard key={index + val.codigo}>
                                <S.BlocoCard t="30%" jC="left">{format(formatDate(val.data_partida), "dd/MM")} ás {format(formatDate(val.data_partida), "HH:mm")}</S.BlocoCard>
                                <S.BlocoCard t="40%" jC="center" tipo={val.tipo_rodada}>
                                    <span>{val.tipo_rodada === 1 ? "SN" : val.tipo_rodada === 2 ? "SE" : "SS"}</span>
                                    {" "}{formatMoneyNotSDec(parseFloat(val.valor_keno))}
                                </S.BlocoCard>
                                <S.BlocoCard t="30%" jC="right">{formatMoneyNotSDec(parseFloat(val.valor_cartela))}</S.BlocoCard>
                            </S.LineCard>
                        ))}
                    </S.BodyCard>
                </div>
            </S.Container>
            <S.CardSecondary tipo={1} />
            <S.Cardthird tipo={1} />
        </motion.div>
    )
}

declare type anuncioVencedores = {
    vencedores?: IVencedoresHistorico[];
}

export const AnuncioVencedores: React.FC<anuncioVencedores> = ({ vencedores }) => {
    return (
        <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, rotate: 0, scale: 1 }}
            style={{
                width: "100%",
                height: "calc(100vh - 3rem)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column"
            }}
        >
            <S.TitleContent>{vencedores && 'SORTEIOS ANTERIORES'}</S.TitleContent>
            <S.Container tipo={1}>
                {vencedores ? (
                    <div>
                        <S.HeaderCard>
                            <S.BlocoHeader t="30%" jC="left">{("data-hora").toUpperCase()}</S.BlocoHeader>
                            <S.BlocoHeader t="40%" jC="center">{("premiação").toUpperCase()}</S.BlocoHeader>
                            <S.BlocoHeader t="30%" jC="right">{("doação").toUpperCase()}</S.BlocoHeader>
                        </S.HeaderCard>
                        <S.BodyCard>
                            {vencedores?.map((val, index) => (
                                <S.LineCard key={index + val.cartela}>
                                    <S.BlocoCard style={{ fontSize: "1.5rem" }} t="25%" jC="left">{val.sorteio}</S.BlocoCard>
                                    <S.BlocoCard style={{ fontSize: "1.5rem" }} t="50%" jC="center" >
                                        {val.nome}
                                    </S.BlocoCard>
                                    <S.BlocoCard style={{ fontSize: "1.5rem" }} t="25%" jC="right" tipoPremio={val.tipo}>
                                        <span>{val.tipo === 1 ? "KU" : val.tipo === 2 ? "KI" : val.tipo === 3 ? "KE" : "AC"}</span>
                                        {formatMoneyNotSDec(val.premio)}
                                    </S.BlocoCard>
                                </S.LineCard>
                            ))}
                        </S.BodyCard>
                    </div>
                ) : (
                    <h1>AGUARDANDO NOVOS SORTEIOS</h1>
                )}

            </S.Container>
        </motion.div>
    )
}