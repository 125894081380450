import { AxiosResponse } from "axios";

export function getTicketRequest(bilhete: string | undefined) {
  return {
    type: "@ticket/GET_TICKET_REQUEST",
    payload: { bilhete },
  };
}

export function getTicketSuccess(data: any) {
  return {
    type: "@ticket/GET_TICKET_SUCCESS",
    payload: { data },
  };
}

export function getTicketSuccessPule(data: any) {
  return {
    type: "@ticket/GET_TICKET_SUCCESS_PULE",
    payload: { data },
  };
}

export function getTicketRequestPule(bilhetes: IBilhete[]) {
  return {
    type: "@ticket/GET_TICKET_REQUEST_PULE",
    payload: { bilhetes },
  };
}

export function updateSorteio( sorteio : ISorteio) {
  return {
    type: "@ticket/UPDATE_SORTEIO",
    payload: { sorteio},
  };
}

export function updateStatusSorteio(id: number){
  return {
    type: "@ticket/UPDATE_STATUS_SORTEIO",
    payload: { id},
  };
}

export function reset() {
  return {
    type: "@ticket/RESET",
  };
}

export function clean() {
  return {
    type: "@ticket/CLEAN",
  };
}

export function remove(id: any) {
  return {
    type: "@ticket/REMOVE",
    payload: { id },
  };
}

export function getAllTickets(bilhetes: any) {
  return {
    type: "@ticket/GET_ALL_TICKETS_REQUEST",
    payload: { bilhetes },
  };
}

export function getAllTicketsSuccess(bilhetes: any) {
  return {
    type: "@ticket/GET_ALL_TICKETS_SUCCESS",
    payload: { bilhetes },
  };
}

export function getAllTicketsError() {
  return {
    type: "@ticket/GET_ALL_TICKETS_ERROR",
   
  };
}

export function getTicketError() {
  return {
    type: "@ticket/GET_TICKET_ERROR",
   
  };
}




