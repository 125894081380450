export const ballColors = (ball: number) => {
    const colorBalls = {
        blue: ["#0016D9", "#0013BA", "#000D82"],
        red: ["#D90000", "#BA0000", "#820000"],
        yellow: ["#D4D900", "#BAA700", "#827C00"],
        green: ["#11D900", "#0FBA00", "#00820D"],
        cyan: ["#00BFD9", "#00AFBA", "#008282"],
        gray: ["#c0c0c0", "#a3a3a3", "#9e9e9e"],
    };

    if (ball < 18) return colorBalls.blue;
    else if (ball > 19 && ball < 36) return colorBalls.red;
    else if (ball > 37 && ball < 54) return colorBalls.yellow;
    else if (ball > 55 && ball < 72) return colorBalls.green;
    else return colorBalls.cyan;
}