import styled from "styled-components";
import { Search } from '@styled-icons/boxicons-regular/Search'
interface ModProps {
  isTv: boolean;
  width?: string;
}

export const FormContainer = styled.div<ModProps>`
  grid-area: search;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f0d50c;
  border-radius: 5px 5px 5px 5px;
  border: none;
  width: 100%;
  padding: 3px 3px;
  height: 2.5rem;
  align-self: ${(props) => (props.isTv ? "" : "auto")};
  position: ${(props) => (props.isTv ? "absolute" : "relative")};
  margin-top: ${(props) => (props.isTv ? "2rem" : "0rem")};
  width: ${(props) => (props.isTv ? "16rem" : "100%")};
  right: ${(props) => (props.isTv ? "8rem" : "0rem")};
  z-index: 20;

  @media (min-width: 900px) {
    width: 8rem;
  }

  @media (min-width: 1024px) {
    margin-left: 5rem;
    margin-top: 1rem;
    right: ${(props) => (props.isTv ? "8rem" : "0rem")};
    position: ${(props) => (props.isTv ? "absolute" : "relative")};
    width: ${(props) => (props.width ? props.width : `16rem`)};
  }
`;

export const Input = styled.input`
  height: 100%;
  min-width: 3rem;
  letter-spacing: 0.05rem;
  padding-left: 0.5rem;
  border-radius: 5px;
  border: none;
  outline: none;
  font-size: 1rem;
  z-index: 2;
  -webkit-user-select: text;
`;

export const LinkContainer = styled.div`
  height: 100%;
  font-size: 2rem;

  border: none;
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-user-select: initial;
  }
`;

export const SearchIcon = styled(Search)`
  height: 1rem;
  width: 1rem;
`;