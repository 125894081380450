import react, { useEffect } from "react"

export const cartSelector =(data: ICart)=>{


    let resp: ICartResp={
        cart: [],
        TurnoCartelasIni: 0,
        TurnoCartelasFim: 0
    }

    if(data.cartCompradas >= 15){
        data.turnos.slice(data.turnoCartelasIni,15 + data.turnoCartelasIni).map((value)=>{
            data.carts.filter(x => x.codigo === value.codigo).map((cart)=>{
                // if(resp.cart.find(x => x.codigo === cart.codigo) == undefined){
                    resp.TurnoCartelasIni = data.turnoCartelasIni + 15;
                    resp.cart.push(cart);
                    return resp;
                // }
            })
        })
    }
    
    if(data.cartCompradas < 15 && data.cartCompradas >= 5){
        if (data.bolasSorteadas.slice(0,data.turno).find(x => x === data.kina) === undefined) {
            data.turnos.slice(data.turnoCartelasIni,15 + data.turnoCartelasIni).map((value)=>{
                data.carts.filter(x => x.codigo === value.codigo).map((cart)=>{
                        resp.TurnoCartelasIni = data.turnoCartelasIni + 15;
                        resp.cart.push(cart);
                        return resp;
                    // }
                })
            })
        }
        if(data.bolasSorteadas.slice(0,data.turno).find(x => x === data.kina) !== undefined){
            data.turnos.slice(data.turnoCartelasIni,data.cartCompradas + data.turnoCartelasIni).map((value)=>{
                data.carts.filter(x => x.codigo === value.codigo).map((cart)=>{
                    // if(resp.cart.find(x=>x.codigo === cart.codigo) == undefined){
                        resp.TurnoCartelasIni = data.turnoCartelasIni + data.cartCompradas;
                        resp.cart.push(cart);
                        return resp;
                    // }
                })
            })
        }
    }

    if(data.cartCompradas < 5){
        data.turnos.slice(data.turnoCartelasIni,data.cartCompradas + data.turnoCartelasIni).map((value)=>{
            data.carts.filter(x => x.codigo === value.codigo).map((cart)=>{
                // if(resp.cart.find(x=>x.codigo === cart.codigo) == undefined){
                    resp.TurnoCartelasIni = data.turnoCartelasIni + data.cartCompradas;
                    resp.cart.push(cart);
                    return resp;
                // }
            })
        })
    }
    return resp;
}
