/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import BallC from "../images/ballC";
import Ball1C from "../images/ball1C";
import Ball2C from "../images/ball2C";
import Ball3C from "../images/ball3C";
import Ball4C from "../images/ball4C";
import Ball5C from "../images/ball5C";
import Ball6C from "../images/ball6C";
import * as S from "../style";
import { motion } from "framer-motion";

import useInterval from "../hooks/useIntervals";
import useWindowSize from "../hooks/useWindowSize";

type Balls = {
  ballKeno: number;
  ball: number[];
  turno: number;
  tempoUpdate: number;
};

export const TubeBall = ({ ball, turno, tempoUpdate, ballKeno }: Balls) => {
  const [ampliar, setAmpliar] = useState(true);
  const [turnoAnterior, setTurnoAnterior] = useState(turno);
  const { width, height } = useWindowSize();
  const isLargeScreen = width >= 1028 ? true : false;
  const largura = !isLargeScreen ? width / 2 - 80 : -300;
  const altura = !isLargeScreen ? width / 2 : 100;
  const sizeBall = isLargeScreen ? "14rem" : "14.5rem";

  const colorBalls = {
    blue: ["#0016D9", "#0013BA", "#000D82"],
    red: ["#D90000", "#BA0000", "#820000"],
    yellow: ["#D4D900", "#BAA700", "#827C00"],
    green: ["#11D900", "#0FBA00", "#00820D"],
    cyan: ["#00BFD9", "#00AFBA", "#008282"],
    gray: ["#c0c0c0", "#a3a3a3", "#9e9e9e"],
  };

  const TuboWidth = () => {
    const TamanhoTubo = width * (95 / 100) - 16 * 7 - 3.2;
    return TamanhoTubo;
  };

  function ballColors(ball: number) {
    if (ball < 18) return colorBalls.blue;
    else if (ball > 19 && ball < 36) return colorBalls.red;
    else if (ball > 37 && ball < 54) return colorBalls.yellow;
    else if (ball > 55 && ball < 72) return colorBalls.green;
    else return colorBalls.cyan;
  }

  interface CustomItemProps {
    podeAmpliar: boolean;
    tempoUp: number;
  }

  const Bola: React.FC<CustomItemProps> = ({ podeAmpliar, tempoUp }) => {
    const kenoNumber = () => {
      setTimeout((numberKeno: number) => {
        return numberKeno;
      }, 2000);
    };

    return (
      <div>
        {ballKeno === ball[turno] ? (
          <div
            style={{
              width: sizeBall,
              height: sizeBall,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // height:'100%'
            }}
          >
            <Ball6C />
            <S.NumberBall>{ball[turno]}</S.NumberBall>
          </div>
        ) : (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <BallC color={ballColors(ball[turno])} />
            <S.NumberBall>{ball[turno]}</S.NumberBall>
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    setAmpliar(true);
  }, []);
  useEffect(() => {}, [turno]);

  return (
    <S.ContainerTubeBall>
      <S.ContentTubeBall>
        <S.GloboContainer>
          <Bola podeAmpliar={ampliar} tempoUp={tempoUpdate} />
        </S.GloboContainer>
        <S.RepositorioBalls
          style={
            turno >= 4 && TuboWidth() / (3.2 * 16) >= 3
              ? {
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }
              : {}
          }
        >
          <S.BallContainerMin
            style={turno === 0 ? { display: "none" } : { display: "flex" }}
          >
            <Ball1C color={ballColors(ball[turno - 1])} />
            {/* <S.Ball dangerouslySetInnerHTML={Ball1(ballColors1(ball[turno-1]),"medium")}/> */}
            <S.NumberBallSmaller>{ball[turno - 1]}</S.NumberBallSmaller>
          </S.BallContainerMin>
          <S.BallContainerMin
            style={turno <= 1 ? { display: "none" } : { display: "flex" }}
          >
            <Ball2C color={ballColors(ball[turno - 2])} />
            {/* <S.Ball dangerouslySetInnerHTML={Ball2(ballColors(ball[turno-2]),"big")}/> */}
            <S.NumberBallSmaller>{ball[turno - 2]}</S.NumberBallSmaller>
          </S.BallContainerMin>
          <S.BallContainerMin
            style={turno <= 2 ? { display: "none" } : { display: "flex" }}
          >
            <Ball3C color={ballColors(ball[turno - 3])} />
            {/* <S.Ball dangerouslySetInnerHTML={Ball3(ballColors(ball[turno-3]),"big")}/> */}
            <S.NumberBallSmaller>{ball[turno - 3]}</S.NumberBallSmaller>
          </S.BallContainerMin>
          {TuboWidth() / (3.2 * 16) >= 5 && (
            <S.BallContainerMin
              style={turno <= 3 ? { display: "none" } : { display: "flex" }}
            >
              <Ball4C color={ballColors(ball[turno - 4])} />
              {/* <S.Ball dangerouslySetInnerHTML={Ball4(ballColors(ball[turno-4]),"big")}/> */}
              <S.NumberBallSmaller>{ball[turno - 4]}</S.NumberBallSmaller>
            </S.BallContainerMin>
          )}
          {TuboWidth() / (3.2 * 16) >= 6 && (
            <S.BallContainerMin
              style={turno <= 4 ? { display: "none" } : { display: "flex" }}
            >
              <Ball5C color={ballColors(ball[turno - 5])} />
              {/* <S.Ball dangerouslySetInnerHTML={Ball4(ballColors(ball[turno-4]),"big")}/> */}
              <S.NumberBallSmaller>{ball[turno - 5]}</S.NumberBallSmaller>
            </S.BallContainerMin>
          )}
        </S.RepositorioBalls>
      </S.ContentTubeBall>
    </S.ContainerTubeBall>
  );
};
