import styled from "styled-components";
import { Trash } from "@styled-icons/foundation/Trash";
import { Add } from "@styled-icons/fluentui-system-filled/Add";
import { Subtract } from "@styled-icons/remix-line/Subtract";
import { TriangleLeft } from "@styled-icons/octicons/TriangleLeft";
import { TriangleRight } from "@styled-icons/octicons/TriangleRight";

interface Props {
  isKeno: boolean;
}

export const Container = styled.div`
  border-radius: 4px;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  width: 90%;
  padding: 6px;
  min-height: 10rem;
  overflow-y: hidden;
  margin-right: 0rem;
  &.primaryNormal {
    background: #fff;
  }
  &.secondaryNormal {
    background: #fff;
  }

  &.tertiaryNormal {
    background: #f0d50c;
  }

  &.primarySpecial {
    background: linear-gradient(
        89.48deg,
        #cd911b -9.2%,
        #f8b123 14.81%,
        #fefc37 26.55%,
        #fffe8e 44.15%,
        #fefd5a 62.3%,
        #f8b125 79.37%,
        #cd911b 93.24%
      ),
      linear-gradient(
        89.48deg,
        #978a32 6.65%,
        #b2a129 27.17%,
        #c5b023 47.43%,
        #d7bf1d 69.71%,
        #bdaa2a 93.24%
      ),
      linear-gradient(
        89.48deg,
        #978a32 6.65%,
        #b2a129 27.17%,
        #c5b023 47.43%,
        #d7bf1d 69.71%,
        #bdaa2a 93.24%
      ),
      linear-gradient(
        89.48deg,
        #978a32 6.65%,
        #b2a129 27.17%,
        #c5b023 47.43%,
        #d7bf1d 69.71%,
        #bdaa2a 93.24%
      );
  }
  &.secondarySpecial {
    background: linear-gradient(
        89.48deg,
        #aaa293 -9.2%,
        #d2c9b6 5.74%,
        #ececde 18.54%,
        #ffffff 26.55%,
        #fffffc 59.63%,
        #ececde 68.7%,
        #d2c9b6 79.37%,
        #aaa293 93.24%
      ),
      linear-gradient(
        89.48deg,
        #978a32 6.65%,
        #b2a129 27.17%,
        #c5b023 47.43%,
        #d7bf1d 69.71%,
        #bdaa2a 93.24%
      ),
      linear-gradient(
        89.48deg,
        #978a32 6.65%,
        #b2a129 27.17%,
        #c5b023 47.43%,
        #d7bf1d 69.71%,
        #bdaa2a 93.24%
      ),
      linear-gradient(
        89.48deg,
        #978a32 6.65%,
        #b2a129 27.17%,
        #c5b023 47.43%,
        #d7bf1d 69.71%,
        #bdaa2a 93.24%
      );
  }

  &.primarySuperSpecial {
    background: linear-gradient(
      92.18deg,
      #5993d4 -8.3%,
      #afe2ff 16.91%,
      #ffedf7 46.1%,
      #fdcde1 74.65%,
      #fe7cec 96.4%
    );
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 1.5rem;
  width: 100%;
  color: #121049;
  font-weight: bold;
  text-align: center;
  margin-right: 0px;

  h3 {
    margin-left: 3px;
    font-size: 1.3rem;
    margin-bottom: 3px;
  }
  h1 {
    margin-left: 3px;
    font-size: 1rem;
    margin-bottom: 3px;
  }

  @media (max-width: 340px) {
  }
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  min-height: 7rem;
`;

export const DivRodape = styled.div`
  display: flex;
  width: 100%;
  padding: 0.5rem;
  justify-content: space-between;
`;

export const ButtonRed = styled.button`
  border: solid 1px #111040;
  color: white;
  background: radial-gradient(
    79.29% 79.29% at 49.87% 45.36%,
    #f73434 0%,
    #6c1c11 100%
  );
  border-radius: 5px;
  width: calc((1 / 3) * 100%);
  height: 1.6rem;
`;

export const ButtonGreen = styled(ButtonRed)`
  background: radial-gradient(
    79.29% 79.29% at 49.87% 45.36%,
    #23a830 0%,
    #116c1a 100%
  );
`;

export const Values = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5px;
  width: 100%;
`;

export const ContentLeftFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: #fff;
  font-size: 0.6rem;
`;

export const ContentLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 70%;
  padding: 5px;
  &.primaryNormal {
    background: radial-gradient(
      706.7% 704.76% at 50% -590.48%,
      #1b10b1 0%,
      #111040 100%
    );
  }
  &.secondaryNormal {
    background: radial-gradient(
      79.29% 79.29% at 49.87% 45.36%,
      #862088 0%,
      #000b01 100%
    );
  }

  &.tertiaryNormal {
    background: radial-gradient(
      79.29% 79.29% at 49.87% 45.36%,
      #1fa71c 0%,
      #0d3107 100%
    );
  }

  &.primarySpecial {
    background: radial-gradient(
      706.7% 704.76% at 50% -590.48%,
      #1b10b1 0%,
      #111040 100%
    );
  }
  &.secondarySpecial {
    background: radial-gradient(
      706.7% 704.76% at 50% -590.48%,
      #1b10b1 0%,
      #111040 100%
    );
  }
  &.primarySuperSpecial {
    background: radial-gradient(
      706.7% 704.76% at 50% -590.48%,
      #1b10b1 0%,
      #111040 100%
    );
  }
`;

export const ContentRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  width: 30%;
  align-items: center;
  padding: 0.2rem 0.2rem;
  button {
    background: radial-gradient(
      79.29% 79.29% at 49.87% 45.36%,
      #1fa71c 0%,
      #0d3107 100%
    );
    border-radius: 4px;
    color: #fff;
    font-size: 0.6rem;
    padding: 4px;
    border: none;
    width: 80%;
    align-self: center;
    cursor: pointer;
  }
  span {
    font-size: 0.7rem;
    text-align: center;
    font-weight: bold;
  }

  @media (max-width: 360px) {
    span {
      font-size: 0.65rem;
    }
  }
  @media (max-width: 336px) {
    span {
      font-size: 0.5rem;
    }
  }
`;
export const ContentFooterBox = styled.div`
  display: flex;
  width: 100%;
  height: 2rem;
  flex-direction: column;
  align-items: center;
`;

export const FooterBox = styled.div`
  background: white;
  width: 80%;
  height: 50%;
  opacity: 0.6;
  border-radius: 0px 0px 5px 5px;
  &.primarySpecial {
    background: linear-gradient(
        89.48deg,
        #cd911b -9.2%,
        #f8b123 14.81%,
        #fefc37 26.55%,
        #fffe8e 44.15%,
        #fefd5a 62.3%,
        #f8b125 79.37%,
        #cd911b 93.24%
      ),
      linear-gradient(
        89.48deg,
        #978a32 6.65%,
        #b2a129 27.17%,
        #c5b023 47.43%,
        #d7bf1d 69.71%,
        #bdaa2a 93.24%
      ),
      linear-gradient(
        89.48deg,
        #978a32 6.65%,
        #b2a129 27.17%,
        #c5b023 47.43%,
        #d7bf1d 69.71%,
        #bdaa2a 93.24%
      ),
      linear-gradient(
        89.48deg,
        #978a32 6.65%,
        #b2a129 27.17%,
        #c5b023 47.43%,
        #d7bf1d 69.71%,
        #bdaa2a 93.24%
      );
  }
  &.secondarySpecial {
    background: linear-gradient(
        89.48deg,
        #aaa293 -9.2%,
        #d2c9b6 5.74%,
        #ececde 18.54%,
        #ffffff 26.55%,
        #fffffc 59.63%,
        #ececde 68.7%,
        #d2c9b6 79.37%,
        #aaa293 93.24%
      ),
      linear-gradient(
        89.48deg,
        #978a32 6.65%,
        #b2a129 27.17%,
        #c5b023 47.43%,
        #d7bf1d 69.71%,
        #bdaa2a 93.24%
      ),
      linear-gradient(
        89.48deg,
        #978a32 6.65%,
        #b2a129 27.17%,
        #c5b023 47.43%,
        #d7bf1d 69.71%,
        #bdaa2a 93.24%
      ),
      linear-gradient(
        89.48deg,
        #978a32 6.65%,
        #b2a129 27.17%,
        #c5b023 47.43%,
        #d7bf1d 69.71%,
        #bdaa2a 93.24%
      );
  }
  &.primarySuperSpecial {
    background: linear-gradient(
      92.18deg,
      #5993d4 -8.3%,
      #afe2ff 16.91%,
      #ffedf7 46.1%,
      #fdcde1 74.65%,
      #fe7cec 96.4%
    );
  }
`;

export const FooterBox2 = styled(FooterBox)`
  width: 50%;
  height: 50%;
  opacity: 0.3;
`;

export const Value = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  font-weight: bold;
  width: 100%;
  span {
    font-size: 1.3rem;
    text-align: center;
    margin-top: 1rem;
    width: 100%;
    &.primaryNormal {
      color: #f7b634;
    }
    &.secondaryNormal {
      color: #f7b634;
    }
    &.tertiaryNormal {
      color: #f7b634;
    }

    &.primarySpecial {
      background: -webkit-linear-gradient(
          89.48deg,
          #cd911b -9.2%,
          #f8b123 14.81%,
          #fefc37 26.55%,
          #fffe8e 44.15%,
          #fefd5a 62.3%,
          #f8b125 79.37%,
          #cd911b 93.24%
        ),
        linear-gradient(
          89.48deg,
          #978a32 6.65%,
          #b2a129 27.17%,
          #c5b023 47.43%,
          #d7bf1d 69.71%,
          #bdaa2a 93.24%
        ),
        linear-gradient(
          89.48deg,
          #978a32 6.65%,
          #b2a129 27.17%,
          #c5b023 47.43%,
          #d7bf1d 69.71%,
          #bdaa2a 93.24%
        ),
        linear-gradient(
          89.48deg,
          #978a32 6.65%,
          #b2a129 27.17%,
          #c5b023 47.43%,
          #d7bf1d 69.71%,
          #bdaa2a 93.24%
        );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &.secondarySpecial {
      background: -webkit-linear-gradient(
          89.48deg,
          #aaa293 -9.2%,
          #d2c9b6 5.74%,
          #ececde 18.54%,
          #ffffff 26.55%,
          #fffffc 59.63%,
          #ececde 68.7%,
          #d2c9b6 79.37%,
          #aaa293 93.24%
        ),
        linear-gradient(
          89.48deg,
          #978a32 6.65%,
          #b2a129 27.17%,
          #c5b023 47.43%,
          #d7bf1d 69.71%,
          #bdaa2a 93.24%
        ),
        linear-gradient(
          89.48deg,
          #978a32 6.65%,
          #b2a129 27.17%,
          #c5b023 47.43%,
          #d7bf1d 69.71%,
          #bdaa2a 93.24%
        ),
        linear-gradient(
          89.48deg,
          #978a32 6.65%,
          #b2a129 27.17%,
          #c5b023 47.43%,
          #d7bf1d 69.71%,
          #bdaa2a 93.24%
        );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    &.primarySuperSpecial {
      background: -webkit-linear-gradient(
        92.18deg,
        #5993d4 -8.3%,
        #afe2ff 16.91%,
        #ffedf7 46.1%,
        #fdcde1 74.65%,
        #fe7cec 96.4%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &.textKeno {
      font-size: 2.8rem;
      margin-top: -0.9rem;
    }
  }

  h4 {
    font-size: 1.4rem;
    color: #fff;
    margin-top: -8px;
    text-align: center;

    &.textKeno {
      font-size: 2rem;
      margin-top: -15px;
      line-height: 1;
    }
  }
`;

export const AmountGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    background: none;
    border: none;
    font-size: 1.5rem;
    color: #000;
    cursor: pointer;
  }
  input {
    width: 2rem;
    border-radius: 4px;
    border: 1px solid #000;
    font-size: 1rem;
    text-align: center;
    padding: 0.1rem;
    height: 1.5rem;
  }
  input:focus {
    outline: none;
  }
  @media (max-width: 300px) {
    button {
      font-size: 1rem;
    }
  }
`;

export const InfoSorteio = styled.div`
  color: white;
  font-size: 0.8rem;
  font-weight: 900;
`;

export const ConteinerInfoSorteio = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 0.5rem;
`;

export const TextCronometro = styled.div`
  color: #111040;
  font-size: 1.5rem;
  font-weight: 900;
  line-height: 0.7;
  margin-top: 0.5rem;
  display: flex;
`;

export const TitleTop = styled.div`
  justify-content: space-around;
  color: white;
  text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.81);
  text-align: center;
  font-size: 2.2rem;
  font-weight: 900;
  line-height: 0.9;
  margin-top: 2rem;
`;

export const SSkeletonPulse = styled.div`
  margin-bottom: 1rem;
  border-radius: 3px;
  display: inline-block;
  height: 2rem;
  width: 90%;
  background: linear-gradient(
    -90deg,
    #ffffff 0%,
    transparent 50%,
    #ffffff 100%
  );
  background-size: 400% 400%;
  animation: pulse 1.2s ease-in-out infinite;
  @keyframes pulse {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
`;

export const SSkeletonLine = styled(SSkeletonPulse)`
  width: 5.5em;
  border-radius: 5px;

  &::before {
    content: "\00a0";
  }
`;

export const DataAndTrashGroup = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  svg {
    color: #ff0000;
  }
  h1 {
    margin-right: 10px;
  }
`;

export const TextAnimationPoints = styled.p`
  color: #111040;
  animation: dots 1s steps(5, end) infinite;
  margin: 0rem;
  font-size: 1.5rem;
  @keyframes dots {
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }
    40% {
      color: #111040;
      text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }
    60% {
      text-shadow: 0.25em 0 0 #111040, 0.5em 0 0 rgba(0, 0, 0, 0);
    }
    80%,
    100% {
      text-shadow: 0.25em 0 0 #111040, 0.5em 0 0 #111040;
    }
  }
`;

// Ícones: 👇🏼👇🏼
export default styled(Trash)`
  height: 1rem;
  width: 1rem;
`;

export const AddIcon = styled(Add)`
  height: 1rem;
  width: 1rem;
  margin-bottom: 0.5rem;
`;

export const SubtractIcon = styled(Subtract)`
  height: 1rem;
  width: 1rem;
  margin-bottom: 0.5rem;
`;

export const TriangleLeftIcon = styled(TriangleLeft)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const TriangleRightIcon = styled(TriangleRight)`
  height: 1.5rem;
  width: 1.5rem;
`;
