import React, { useEffect, useState } from "react";
import { Cartela } from "../pages/Game/Game";
import * as S from "../style";

type TableProps = {
  dados: Cartela[];
  keno: boolean;
};

type CartelaProps = {
  numeros: number[];
  linha1_lista: number[];
  linha2_lista: number[];
  linha3_lista: number[];
};

export const Table: React.FC<TableProps> = ({ dados, keno }) => {
  const [isKeno, setIsKeno] = useState(false);
  const estabelecimentoNome = (nome: string) => {
    if (nome) {
      return nome.length > 12 ? nome.substring(0, 12) + "..." : nome;
    } else {
      ("sem nome");
    }
  };

  const shortName = {
    superior: "SU",
    centro: "CE",
    inferior: "IN",
  };

  useEffect(() => {
    if (keno) {
      setIsKeno(true);
    }
  }, [keno]);

  const tablePosition = (data: CartelaProps) => {
    const linha1 = data.numeros.filter((numero) =>
      data.linha1_lista.includes(numero)
    );
    if (linha1.length > 0) {
      return shortName.superior;
    }

    const linha2 = data.numeros.filter((numero) =>
      data.linha2_lista.includes(numero)
    );
    if (linha2.length > 0) {
      return shortName.centro;
    }

    const linha3 = data.numeros.filter((numero) =>
      data.linha3_lista.includes(numero)
    );
    if (linha3.length > 0) {
      return shortName.inferior;
    }
  };

  return (
    <S.DivTabela>
      <S.TopoTabela>
        <S.TextTopoTabela width="23%">CARTELA</S.TextTopoTabela>
        <S.TextTopoTabela width="35%">JOGADORES</S.TextTopoTabela>
        <S.TextTopoTabela width="42%">FALTA PARA VITÓRIA</S.TextTopoTabela>
      </S.TopoTabela>
      <S.BottomTabela>
        <S.ContUl>
          {dados?.map((dado) => (
            <S.ContDivTable key={"li" + dado?.codigo + dado?.posicao}>
              <S.LiLine width="23%">{dado?.codigo} </S.LiLine>
              <S.LiLine width="35%">
                {estabelecimentoNome(dado?.estabelecimento)}
              </S.LiLine>
              <S.LiNumbers width="42%">
                {dado?.numeros.length > 4 || isKeno ? (
                  <>
                    <S.LiNumber> {dado?.numeros?.[0]} </S.LiNumber>
                    <S.LiNumber> {dado?.numeros?.[1]} </S.LiNumber>
                    <S.LiNumber> {dado?.numeros?.[2]} </S.LiNumber>
                    <S.LiNumber> {dado?.numeros?.[3]} </S.LiNumber>
                    <S.LiNumber> {dado?.numeros?.[4]} </S.LiNumber>
                  </>
                ) : (
                  <>
                    <S.LiNumber> {tablePosition(dado)} </S.LiNumber>
                    <S.LiNumber> {dado?.numeros?.[0]} </S.LiNumber>
                    <S.LiNumber> {dado?.numeros?.[1]} </S.LiNumber>
                    <S.LiNumber> {dado?.numeros?.[2]} </S.LiNumber>
                    <S.LiNumber> {dado?.numeros?.[3]} </S.LiNumber>
                  </>
                )}
              </S.LiNumbers>
            </S.ContDivTable>
          ))}
        </S.ContUl>
      </S.BottomTabela>
    </S.DivTabela>
  );
};
