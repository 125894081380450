import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import CardCompraSorteio from "../../../components/CardCompraSorteio";
import CheckNetwork from '../../../components/CheckNetwork';
import Header from "../../../components/Header";
import ModalCodigoConfirmacao from "../../../components/ModalCodigoConfirmacao";
import ModalConfirmarCompra from '../../../components/ModalConfirmarCompra';
import Share from "../../../components/Share";
import { currentValue } from "../../../hooks/anticipatedValue";
import { cleanCart, removeToCart } from "../../../store/modules/carrinho/actions";
import { GetProximosSorteiosCompra, PreCompra } from "../../../WebRequests/requestsJson";
import * as Style from "../../Styles/ComprarStyle";

interface ISorteiosSelecionados {
  sorteio: ISorteio;
  quantidade: number;
}

const EscolherSorteios: React.FC = () => {
  const history = useHistory();
  const [sorteios, setSorteios] = useState<ISorteio[]>();
  const [title, setTitle] = useState("SORTEIOS");
  const [sorteiosSelecionados, setSorteiosSelecionados] =
    useState<ISorteiosSelecionados[]>();
  const [quantidade, setQuantidade] = useState<number>(0);
  const [valorTotal, setValorTotal] = useState<string>();
  const [confirm, setConfirm] = useState<boolean>(false);
  const [visibleModal, setVisibleModal] = useState<boolean>(false);
  const [codigoConfirmacao, setCodigoConfirmacao] = useState<string>();
  const [visibleShare, setVisibleShare] = useState<boolean>(false);
  const dispatch = useDispatch();
  const carrinho = useSelector((state: any) => state.carrinho);
  const [preCompraTxt, setPreCompraTxt] = useState<string>();
  const [telefone_digitado, setTelefone_digitado] = useState(0);
  const [visibleModalConfirm, setVisibleModalConfirm] = useState(false);
  const [sorteiosNaoDisponiveis, setSorteiosNaoDisponiveis] = useState<string []>();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery().get("type");
  let reload = useQuery().get("reload");

  const myRef = useRef<null | HTMLDivElement>(null);

  const executeScroll = () => {
    if (myRef.current) {
      myRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  };


  useEffect(() => {
    if (carrinho.length > 0) {
      let count = 0;
      let total = 0;
      for (let item of carrinho) {
        count += item.quantidade;
        total += item.quantidade * parseFloat(currentValue(item.sorteio));

        setQuantidade(count);
        setValorTotal(formatLocaleStringNumber(total));
      }
    } else {
      setQuantidade(0);
      setValorTotal("");
    }
  }, [carrinho]);

  useEffect(() => {
    async function fetch() {
      loadData();

    }
    fetch();
  }, [query]);

  useEffect(() => {
    async function fetch() {
      if (reload == "true") {
        loadData();
      }

    }
    fetch();

  }, [reload]);

  const loadData = async () => {
    const { data, error } = await GetProximosSorteiosCompra();

    if (data) {
      if (query === "normal") {
        setConfirm(false);
        setSorteios(data.sorteios.filter((x) => x.tipo_rodada === 1));
        setTitle("NORMAIS");
      }
      if (query === "special") {
        setConfirm(false);
        setSorteios(data.sorteios.filter((x) => x.tipo_rodada === 2));
        setTitle("ESPECIAIS");
      }
      if (query === "super") {
        setConfirm(false);
        setSorteios(data.sorteios.filter((x) => x.tipo_rodada === 3));
        setTitle("SUPER ESPECIAIS");
      }
      if (query === "confirm") {
        if (carrinho.length === 0) {
          toast.error("Carrinho vazio.");
        } else {
          handleConfirm();
        }

      }
      if (query === "success") {
        history.push("/comprar/sorteios?type=normal");

      }
    }
  }

  const formatLocaleStringNumber = (value: number) => {
    return value.toLocaleString("pt-br", { minimumFractionDigits: 2 });
  };

  const handleNext = () => {
    history.push("/comprar/sorteios?type=confirm");
  }

  const handleConfirm = () => {
    if (carrinho.length === 0) {
      toast.error("Adicione pelo menos um sorteio.");
      return;
    }
    setConfirm(true);
    executeScroll();
    setTitle("CONFIRMAÇÃO");
  };

  const handleSend = async () => {
    if(carrinho.length == 0) return;
    let list = [];
    if (carrinho) {
      for (let data of carrinho) {
        list.push({
          sorteio: data.sorteio.codigo,
          qtd_cartelas: data.quantidade,
          pre_compra_txt: data.pre_compra_txt,
        });
      }
      const res = await PreCompra(list);

      if (res.error) {
        let listaSorteiosIndisponiveis = [];
        if (res.error.response?.data) {          
          for (let item of res.error.response?.data) {
            if(item.sorteio){
              listaSorteiosIndisponiveis.push(item.sorteio.id);
            }
            
          }
        }
        if(listaSorteiosIndisponiveis.length > 0){
          dispatch(removeToCart(listaSorteiosIndisponiveis));
          setSorteiosNaoDisponiveis(listaSorteiosIndisponiveis);
          setVisibleModalConfirm(true);
        }else{
          toast.error("Erro ao finalizar pré compra.");
        }
                
        return;
      }

      setPreCompraTxt(res.data.data.pre_compra_txt);
      setCodigoConfirmacao(res.data.data.codigo);
      setVisibleModal(true);
      dispatch(cleanCart());
      history.push("/comprar/sorteios?type=success");
      //handleCleanCart();
    }
  };

  const handleCleanCart = () => {
    dispatch(cleanCart());
    //setConfirm(false);
    //history.push("/comprar");

  };

  return (
    <Style.Container onMouseUp={() => setVisibleShare(false)}>
      <Header tipo="loja" />
      <Style.ContentMeio>
        <Style.Title>
          {confirm ? (
            title
          ) : (
            <>
              {sorteios && sorteios?.length > 0 ? title : "SEM " + title}
            </>
          )}

        </Style.Title>

        {confirm ? (
          <Style.Scroll>
            {carrinho && carrinho.length > 0 ? (
              carrinho.map((carrinho: any, index: any) => (
                <CardCompraSorteio
                  key={index}
                  sorteio={carrinho.sorteio}
                  quantidade={carrinho.quantidade}
                  confirm={confirm}
                  fullSize={false}
                  query={query}
                />
              ))
            ) : (
              <>Sem sorteios</>
            )}
          </Style.Scroll>
        ) : (
          <Style.Scroll>
            {sorteios && sorteios.length >= 0 ? (
              sorteios.map((sorteio, index) => (
                <CardCompraSorteio
                  key={index}
                  sorteio={sorteio}
                  confirm={confirm}
                  fullSize={false}
                  query={query}
                />
              ))
            ) : (
              <CardCompraSorteio fullSize={true} />
            )}
          </Style.Scroll>
        )}

        <div
          style={{
            width: "90",            
            bottom: "0",
            paddingBottom: "2rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Style.PurchaseResume>
            <span>QUANTIDADE: </span>
            <h5 className="amount"> {quantidade} </h5>
            <span>TOTAL:</span>
            <h5 className="totalValue"> {valorTotal} </h5>
            <Style.TrashIcon onClick={() => handleCleanCart()} />
          </Style.PurchaseResume>
          <Style.ButtonGroupFooter>
            {confirm ? (
              <>
                <Style.ButtonMore onClick={() => history.push("/comprar")}>
                  COMPRAR MAIS
                </Style.ButtonMore>
                <Style.ButtonSend onClick={() => handleSend()}>
                  FINALIZAR
                </Style.ButtonSend>
              </>
            ) : (
              <>
                {sorteios && sorteios?.length >= 0 ? (
                  <>
                    <Style.ButtonMore onClick={() => history.push("/comprar")}>
                      COMPRAR MAIS
                    </Style.ButtonMore>

                    <Style.ButtonSend onClick={() => handleConfirm()}>
                      CONCLUIR
                    </Style.ButtonSend>
                  </>
                ) : (
                  <>
                    <Style.ButtonMore onClick={() => history.push("/comprar")}>
                      COMPRAR MAIS
                    </Style.ButtonMore>
                    <Style.ButtonSend style={{ background: "gray" }}>
                      CONCLUIR
                    </Style.ButtonSend>
                  </>
                )}
              </>
            )}
          </Style.ButtonGroupFooter>
        </div>
      </Style.ContentMeio>

      <ModalCodigoConfirmacao
        codigo={codigoConfirmacao}
        visibleModal={visibleModal}
        setVisibleModal={setVisibleModal}
        setVisibleShare={setVisibleShare}
        setTelefone_digitado={setTelefone_digitado}
        mensagemSorteio={preCompraTxt}
      />
      {codigoConfirmacao && (
        <Share
          isVisible={visibleShare}
          setVisibleShare={setVisibleShare}
          code={codigoConfirmacao}
          phone={telefone_digitado}
        />
      )}

      {visibleModalConfirm && (
        <ModalConfirmarCompra
          setPreCompraTxt = {setPreCompraTxt}
          setCodigoConfirmacao={setCodigoConfirmacao}
          setVisibleModal ={setVisibleModal}
          setVisibleModalConfirm = {setVisibleModalConfirm}
          visibleModalConfirm ={visibleModalConfirm}
          sorteiosNaoDisponiveis={sorteiosNaoDisponiveis}
        />
      )
      }
      <CheckNetwork />
    </Style.Container>
  );
};

export default EscolherSorteios;
