import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Style from "../pages/Styles/MeusSorteiosModalStyle";
import { reset } from "../store/modules/bilhete/actions";
import CardCompraSorteio from "./CardCompraSorteio";

interface IMinhasCartelasProps {
  isOpenMySorts: boolean;
  setIsOpenMySorts: Dispatch<SetStateAction<boolean>>;
  MinhasCartelas?: IMinhasCartelas;
  showButton?: boolean;
}

export default function MeusSorteiosModal({
  isOpenMySorts,
  setIsOpenMySorts,
  MinhasCartelas,
  showButton,
}: IMinhasCartelasProps) {
  const [sorteios, setSorteios] = useState<ISorteio[]>();

  const dispatch = useDispatch();
  const bilhetes: IBilheteSorteio[] = useSelector(
    (state: any) => state.bilhetes.lista
  );

  if (bilhetes === undefined) {
    dispatch(reset());
  }

  useEffect(() => {
    async function fetch() {
      //Const { data, error } = await GetProximosSorteios();
      let listSorteios = [];
      for (let bilhete of bilhetes) {
        const isExist = listSorteios.some(
          (item) => item.codigo === bilhete.sorteio.codigo
        );
        if (!isExist) {
          listSorteios.push(bilhete.sorteio);
        }
      }

      if (listSorteios.length > 0) {
        setSorteios(listSorteios);
      }
    }
    fetch();
  }, []);

  return (
    <Style.Container isOpen={isOpenMySorts} className={"Meus Sorteios"}>
      {/* <Icon isOpen={isOpenMySorts} onClick={() => showSorts()}>
        <AiOutlineDoubleLeft />
      </Icon> */}
      <Style.GroupTop>
        <Style.ButtonBack onClick={() => setIsOpenMySorts(!isOpenMySorts)}>
          {"<"}
        </Style.ButtonBack>
        <Style.Title style={{ fontSize: "1.4rem" }}>Meus Sorteios</Style.Title>
      </Style.GroupTop>

      <Style.Content>
        {sorteios ? (
          <Style.Scroll>
            {sorteios && sorteios.length > 0 ? (
              sorteios.map((sorteio, index) => (
                //<></>
                // setSorteiosSelecionados,
                // setQuantidade, setValorTotal,
                // confirm, quantidade, fullSize
                <CardCompraSorteio
                  key={index}
                  sorteio={sorteio}
                  fullSize={true}
                  showButtonCard={showButton}
                />
              ))
            ) : (
              <>Sem sorteios</>
            )}
          </Style.Scroll>
        ) : (
          <>
            <span style={{ color: "white" }}>Cartela vazia</span>
          </>
        )}
      </Style.Content>
      <Style.Footer />
    </Style.Container>
  );
}
