import React from "react";
import * as S from "../../pages/Styles/MinhasCartelas";

type CardPremiacao = {
  tipo: string;
  isPremio: boolean;
};
export const CardPremiacao = ({ tipo, isPremio }: CardPremiacao) => {
  return (
    <S.CardPremiacao isPremio={isPremio} tipo={tipo}>
      <p>{tipo}</p>
    </S.CardPremiacao>
  );
};
