import styled from 'styled-components';

export const Container = styled.div`
    color: #fff;  
    bottom: 0;
    right: 3px;
    padding: 1rem 0;
    text-transform: uppercase;

    background: radial-gradient(
        79.29% 79.29% at 49.87% 45.36%,
        #8f94ce 0%,
        #282a81 100%
    );

    display: flex;
    align-items: center;
    justify-content:center;
    width: 100%;

    position: fixed;
    bottom: 0;
    span{
        font-size:1rem;
      
    }

    @media(min-width: 1024px){
        height:1rem;
    }
`;

