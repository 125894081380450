import React, { useEffect, useState } from "react";
import { Dispatch, SetStateAction } from "react";
import { useDispatch } from "react-redux";
import { reset } from "../store/modules/bilhete/actions";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import {
  Container,
  Title,
  Content,
  Header,
  Footer,
  WhiteBox,
  TitleWhite,
  ButtonBack,
  Box,
} from "../pages/Styles/MinhasCartelas";
import Cartela from './Cartela';
import { useSelector } from "react-redux";


interface RowProps {
  index: number;
  style: React.CSSProperties
}

interface IMinhasCartelasProps {
  isOpenMyCards: boolean;
  setIsOpenMyCards: Dispatch<SetStateAction<boolean>>;
  MinhasCartelas?: IMinhasCartelas;
  bolasTurno?: number[];
  vencedores?: number[];
  sorteio?: IPartida;
  turno?: number;
}

export default function MinhasCartelas({
  isOpenMyCards,
  setIsOpenMyCards,
  MinhasCartelas,
  bolasTurno,
  sorteio,
  turno,
}: IMinhasCartelasProps) {
  const [bilhete, setBilhete] = useState<IBilheteSorteio>();
  const [cartelas, setCartelas] = useState<ICartelas[]>([]);

  const dispatch = useDispatch();


  const bilhetes: IBilheteSorteio[] = useSelector(
    (state: any) => state.bilhetes.lista
  );

  if (bilhetes === undefined) {
    dispatch(reset());
  }

  useEffect(() => {

    if (bilhetes.length > 0 && cartelas.length === 0) {
      const bilheteSorteio = bilhetes.filter(
        (item: any) => item.sorteio.codigo === sorteio?.codigo
      );
      if (bilheteSorteio.length > 0) {
        setBilhete(bilheteSorteio[0]);
        for (let item of bilheteSorteio) {
          for (let cartela of item.cartelas)
            setCartelas((prevState) => [...prevState, cartela]);
        }
      }
    }
  }, [bilhetes]);

  useEffect(() => {
    if (turno && bolasTurno && isOpenMyCards && cartelas.length > 0) {
      setCartelas(cartelas.sort((a, b) => {
        return (
          vericacaoPosicao(
            bolasTurno,
            b.linha1_lista,
            b.linha2_lista,
            b.linha3_lista,
            sorteio?.bola_kina,
            sorteio?.bola_kuadra
          ) -
          vericacaoPosicao(
            bolasTurno,
            a.linha1_lista,
            a.linha2_lista,
            a.linha3_lista,
            sorteio?.bola_kina,
            sorteio?.bola_kuadra
          )
        );
      }))

    }
  }, [turno]);

  const vericacaoPosicao = (
    bolasTurno: number[],
    linha1: number[],
    linha2: number[],
    linha3: number[],
    kina?: number,
    kuadra?: number
  ) => {
    let num: number = 0;
    let linha_1: number = 0;
    let linha_2: number = 0;
    let linha_3: number = 0;

    if (kina && kuadra && bolasTurno) {
      if (
        bolasTurno.find((x) => x == kuadra) === undefined ||
        bolasTurno.find((x) => x == kina) === undefined
      ) {
        linha1.map(
          (val) =>
            bolasTurno.find((bola) => bola == val) !== undefined &&
            (linha_1 = linha_1 + 1)
        );
        linha2.map(
          (val) =>
            bolasTurno.find((bola) => bola == val) !== undefined &&
            (linha_2 = linha_2 + 1)
        );
        linha3.map(
          (val) =>
            bolasTurno.find((bola) => bola == val) !== undefined &&
            (linha_3 = linha_3 + 1)
        );
        if (linha_1 > linha_2 && linha_1 > linha_3) return (num = linha_1);
        if (linha_2 > linha_1 && linha_2 > linha_3) return (num = linha_2);
        if (linha_3 > linha_1 && linha_3 > linha_2) return (num = linha_3);
      } else {
        [...linha1, ...linha2, ...linha3].map(
          (val) =>
            bolasTurno.find((bola) => bola == val) !== undefined &&
            (num = num + 1)
        );
      }
    }
    // if(bolasTurno){
    //     [...linha1,...linha2,...linha3].map((val)=>
    //         bolasTurno.find(bola=> bola == val) !== undefined && (num = num + 1)
    //     )
    // }
    return num;
  };


  const Row = ({ index, style }: RowProps) => (
    <Cartela index={index} style={style} cartelas={cartelas} bolasTurno={bolasTurno} sorteio={sorteio} />
  )

  return (
    <Container isOpen={isOpenMyCards} className={"Minhas cartelas"}>
      {/* <Icon isOpen={isOpenMyCards}>
        <AiOutlineDoubleLeft onClick={() => showCards()} />
      </Icon> */}
      <Header>
        <ButtonBack onClick={() => setIsOpenMyCards(!isOpenMyCards)}>
          {">"}
        </ButtonBack>
        <Title style={{ display: "flex", alignItems: "center" }}>
          Minhas Cartelas
          <Box>{cartelas?.length}</Box>
        </Title>
      </Header>
      {isOpenMyCards && (
        <>
          <WhiteBox>
            <TitleWhite>{"SORTEIO: "}</TitleWhite>
            <Title style={{ fontSize: "1.5rem", lineHeight: "1.1" }}>
              {bilhete?.sorteio.codigo}
            </Title>
          </WhiteBox>
          <Content>
            <AutoSizer>
              {({ height, width }: any) => (
                <List
                  className="List"
                  height={height}
                  itemCount={cartelas.length}
                  itemSize={180}
                  width={width}
                >
                  {Row}
                </List>
              )}
            </AutoSizer>

          </Content>{" "}
        </>
      )}
      <Footer />
    </Container>
  );
}
