import PerfectScrollbar from "react-perfect-scrollbar";
import styled, { css } from "styled-components";

interface Props {
  isOpen: boolean;
}

export const Container = styled.div<Props>`
  background: #141061;
  height: auto;
  width: 90%;
  border-radius: 10px;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 5px;
  transform: ${(props) =>
    props.isOpen ? "translateX(0vw)" : "translateX(65vw)"};
  transition: transform 0.4s ease-out;

  &::after {
    content: "";
    position: absolute;
    left: calc(0% - 17px);
    top: calc(30% + 20px);
    width: 0;
    height: 60px;
    z-index: -10000000;
    border-radius: 30px;

    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    transform: rotate(180deg);
  }

  @media (min-width: 1028px) {
    margin-top: 0rem;
  }
`;

export const Icon = styled.div<Props>`
  position: absolute;
  left: calc(0% - 18px);
  top: calc(30% + 55px);
  svg {
    color: #ffff;
    font-size: 1.2rem;
    cursor: pointer;
    transform: ${(props) => (props.isOpen ? "rotate(180deg)" : "rotate(0deg)")};
    transition: transform 0.7s ease-out;

    /* transform: rotate(0deg);
        transition: transform .2s ease-out; */

    /* ${(props) =>
      props.isOpen &&
      css`
        transform: rotate(45deg);
      `};  */
  }
`;

export const Title = styled.h4`
  font-size: 1.2rem;
  color: #ffff00;
  align-self: center;
`;
export const TitleWhite = styled.h4`
  font-size: 1.5rem;
  color: white;
  align-self: center;
  //-webkit-text-stroke-width: 1px;
  //-webkit-text-stroke-color: #000000;
  font-weight: 900;
`;
export const WhiteBox = styled.div`
  //background: radial-gradient(79.29% 79.29% at 49.87% 45.36%, #F9F9F9 0%, #9D9D9D 100%);
  border-radius: 10px;
  //border: solid black 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Content = styled.div`
  border: 2px solid #000;
  border-radius: 10px;
  width: 100%;
  background: radial-gradient(
    79.29% 79.29% at 49.87% 45.36%,
    #656cd5 0%,
    #1a1b43 100%
  );
  padding: 10px;
  height: 85%;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  background-color: #e5e5e5;
  border-radius: 10px;

  #line {
    width: 100%;
    height: 4px;
    background: radial-gradient(
      79.29% 79.29% at 49.87% 45.36%,
      #1b10b1 0%,
      #111040 100%
    );
  }
`;

export const HeaderCard = styled.div`
  background: radial-gradient(
    79.29% 79.29% at 49.87% 45.36%,
    #1b10b1 0%,
    #111040 100%
  );
  padding: 4px;
  display: flex;
  border-radius: 4px;
`;

export const CardNumber = styled.div`
  box-sizing: border-box;
  border-radius: 4px;
  background: #ffff;
  width: 3rem;
  height: 1.7rem;
  text-align: center;
  font-weight: bold;
  font-size: larger;
  align-content: center;
`;
export const NomeEstabelecimento = styled.div`
  padding-left: 0.3rem;
  color: white;
  font-size: larger;
`;

export const ContainerNumbers = styled.div`
  display: grid;
  grid-gap: 2px;
  grid-template-columns: repeat(5, auto);
  grid-auto-flow: row;
  padding: 4px;
  background: radial-gradient(
    79.29% 79.29% at 49.87% 45.36%,
    #ffffff 0%,
    #acacac 100%
  );
`;

export const Number = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  justify-content: space-around;
  height: 30px;
  padding: 0.1rem;
  border-radius: 0.3rem;
  font-weight: 900;

  div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #838383;
    border-radius: 0.2rem;
    background-color: #f2ff00;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  span {
    font-size: 0.85rem;
    color: #ffff00;
    font-weight: bold;
  }

  div {
    background: #fff;
    border-radius: 4px;
    padding: 2px 7px;
    font-size: 0.9rem;
    width: auto;
    margin: 5px;
  }
`;

export const Scroll = styled(PerfectScrollbar)`
  padding-right: 20px;
`;

export const CardPremiacao = styled.div<{ tipo: string; isPremio: boolean }>`
  padding: 0;
  margin-left: 2px;
  width: calc((100% * (1 / 3)) - 4px);
  background: ${(props) =>
    props.isPremio
      ? props.tipo === "kuadra"
        ? "radial-gradient(79.29% 79.29% at 49.87% 45.36%, #1FA71C 0%, #0D3107 100%)"
        : props.tipo === "kina"
        ? "radial-gradient(79.29% 79.29% at 49.87% 45.36%, #203D88 0%, #080720 100%)"
        : props.tipo === "keno" &&
          "radial-gradient(79.29% 79.29% at 49.87% 45.36%, #E8D316 0%, #A69F00 100%)"
      : "#0B0E1A;"};
  color: ${(props) => (props.isPremio ? "#FFFFFF" : "#313131")};
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    padding: 0;
    margin: 0;
    font-size: 1.2rem;
  }
`;
