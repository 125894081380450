import styled from "styled-components";

export const ContainerBolasSorteadas = styled.div`
  width: 95%;
  display: flex;
  justify-content: start;
  align-items: flex-start;
  flex-wrap: wrap;
  border-radius: 0.1rem;
  h3 {
    width: 100%;
    background: radial-gradient(
      79.29% 79.29% at 49.87% 45.36%,
      #1b10b1 0%,
      #111040 100%
    );
    border-radius: 0.1rem 0.1rem 0 0;
    font-size: 1.3rem;
    color: #ffffff;
    font-weight: 100;
  }
  @media (min-width: 1028px) {
    width: 64%;
  }
  @media (min-width: 1366px) {
    width: 77%;
  }
`;

export const BlocoBalls = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  background: #0c0c0c;
  padding: 0.4rem 0.2rem;

  margin-bottom: 0.7rem;
  @media (min-width: 1028px) {
    padding: 0 0;
  }
  @media (min-width: 1366px) {
    padding: 0.4rem 0.2rem;
  }
  @media (min-width: 2048px) {
    height: 92%;
    width: 100%;
    border-radius: 0rem 0rem 2rem 2rem;
  }
`;
export const TittleBalls = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  font-weight: 600;
  text-align: "center";
  background: #1f1978;
  width: 100%;
  color: white;
  @media (min-width: 2048px) {
    border-radius: 2rem 2rem 0rem 0rem;
    font-size: 5rem;
  }
`;

export const ContentBalls = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  width: calc(100% * (1 / 10));
`;

export const Balls = styled.p<{ Ativo: boolean }>`
  font-size: 1.1rem;
  font-weight: 300;
  color: ${(props) => (props.Ativo ? "#09d335" : "#bbbbbb")};

  @media (min-width: 1028px) {
    font-size: 1rem;
  }
  @media (min-width: 2048px) {
    font-size: 2rem;
  }
`;
