import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 8px;
  background: #fff;
  border-radius: 4px;
  width: 100%;
  margin-top: 20px;

  &.primaryNormal {
    background: #ffff;
  }
  &.secondaryNormal {
    background: #ffff;
  }

  &.tertiaryNormal {
    background: #f0d50c;
  }

  &.primarySpecial {
    background: linear-gradient(
        89.48deg,
        #cd911b -9.2%,
        #f8b123 14.81%,
        #fefc37 26.55%,
        #fffe8e 44.15%,
        #fefd5a 62.3%,
        #f8b125 79.37%,
        #cd911b 93.24%
      ),
      linear-gradient(
        89.48deg,
        #978a32 6.65%,
        #b2a129 27.17%,
        #c5b023 47.43%,
        #d7bf1d 69.71%,
        #bdaa2a 93.24%
      ),
      linear-gradient(
        89.48deg,
        #978a32 6.65%,
        #b2a129 27.17%,
        #c5b023 47.43%,
        #d7bf1d 69.71%,
        #bdaa2a 93.24%
      ),
      linear-gradient(
        89.48deg,
        #978a32 6.65%,
        #b2a129 27.17%,
        #c5b023 47.43%,
        #d7bf1d 69.71%,
        #bdaa2a 93.24%
      );
  }
  &.secondarySpecial {
    background: linear-gradient(
        89.48deg,
        #aaa293 -9.2%,
        #d2c9b6 5.74%,
        #ececde 18.54%,
        #ffffff 26.55%,
        #fffffc 59.63%,
        #ececde 68.7%,
        #d2c9b6 79.37%,
        #aaa293 93.24%
      ),
      linear-gradient(
        89.48deg,
        #978a32 6.65%,
        #b2a129 27.17%,
        #c5b023 47.43%,
        #d7bf1d 69.71%,
        #bdaa2a 93.24%
      ),
      linear-gradient(
        89.48deg,
        #978a32 6.65%,
        #b2a129 27.17%,
        #c5b023 47.43%,
        #d7bf1d 69.71%,
        #bdaa2a 93.24%
      ),
      linear-gradient(
        89.48deg,
        #978a32 6.65%,
        #b2a129 27.17%,
        #c5b023 47.43%,
        #d7bf1d 69.71%,
        #bdaa2a 93.24%
      );
  }

  &.primarySuperSpecial {
    background: linear-gradient(
      92.18deg,
      #5993d4 -8.3%,
      #afe2ff 16.91%,
      #ffedf7 46.1%,
      #fdcde1 74.65%,
      #fe7cec 96.4%
    );
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #121049;
  font-weight: bold;

  height: 2.2rem;

  span {
    font-size: 1rem;
  }

  h3 {
    margin-top: -3px;
    font-size: 1.3rem;
    margin-left: 5px;
  }
  h5 {
    font-size: 0.8rem;
    color: #111040;
    margin-bottom: -3px;
  }
  @media (max-width: 330px) {
    height: 1.6rem;
    span {
      font-size: 0.8rem;
    }
    h3 {
      margin-top: -3px;
      font-size: 1.1rem;
      margin-left: 5px;
    }
    h5 {
      font-size: 0.6rem;
      color: #111040;
      margin-bottom: -3px;
    }
  }
`;

export const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderCenter = styled.div`
  display: flex;
  border-radius: 4px;
  align-items: center;
  padding: 5px;
  color: #fff;
  background: #111040;
  height: 80%;
  span {
    font-size: 10px;
  }
`;

export const HeaderRight = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  background: radial-gradient(
    706.7% 704.76% at 50% -590.48%,
    #1b10b1 0%,
    #111040 100%
  );
  box-shadow: 0px 3.95556px 3.95556px rgba(0, 0, 0, 0.25),
    inset -3.95556px -3.95556px 3.95556px rgba(0, 0, 0, 0.25),
    inset 0px 3.95556px 3.95556px rgba(0, 0, 0, 0.25);
  padding: 3px 8px;
  width: 100%;
`;

export const ContentTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
`;

export const Award = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: bold;
  align-items: center;
  span {
    color: #f7b634;
    font-size: 1.3rem;
  }
  h3 {
    color: #fff;
    font-size: 1.2rem;
    margin-top: -10px;
  }
  &.keno {
    span {
      font-size: 1.8rem;
      margin-top: -8px;
    }
    h3 {
      font-size: 1.5rem;
      margin-top: -12px;
    }
  }
  @media (max-width: 290px) {
    span {
      font-size: 1.1rem;
    }
    h3 {
      font-size: 1rem;
    }
    &.keno {
      span {
        font-size: 1.6rem;
      }
      h3 {
        font-size: 1.3rem;
      }
    }
  }
`;

export const ContentBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  font-size: 0.8rem;
  margin-top: 5px;
  @media (max-width: 290px) {
    font-size: 0.6rem;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  padding: 0px 8px;
  span {
    color: #121049;
    font-size: 1.2rem;
    font-weight: bold;
  }
  button {
    border: solid 1px #111040;
    color: white;
    background: radial-gradient(
      79.29% 79.29% at 49.87% 45.36%,
      #f73434 0%,
      #6c1c11 100%
    );
    border-radius: 5px;
    width: calc((1 / 3) * 100%);
    height: 1.6rem;
    &.green {
      background: radial-gradient(
        79.29% 79.29% at 49.87% 45.36%,
        #23a830 0%,
        #116c1a 100%
      );
      border: none;
    }
  }
`;
