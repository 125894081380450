import styled from "styled-components";
import Modal from "react-modal";

export const Container = styled(Modal)`
  display: flex;
  flex-direction: column;
  height: 13rem;
  background: radial-gradient(
    116.41% 62.81% at 50% 50%,
    #111040 100%,
    #1b10b1 100%
  );
  padding: 10px;
  transition: transform 0.2s ease-out;
  border: 5px solid #ffff;
  border-radius: 4px;
  justify-content: space-around;
`;

export const Header = styled.div`
  font-size: 1.4rem;
  color: #ffff;
  display: flex;
  align-self: center;
  font-weight: bold;
`;

export const Footer = styled.div`
  display: flex;
  width: 100%;
  button {
    margin: 10px;
    background: #ffff;
    border: 2px solid #ffff;
    border-radius: 6.91px;
    width: 100%;
    color: #100e36;
    text-align: center;
    font-weight: bold;
    height: 3rem;
    font-size: 1.8rem;
    cursor: pointer;
    :hover {
      opacity: 0.5;
    }
    :focus {
      opacity: 0.5;
    }
  }
`;
