import React from "react";
import { formatMoney, formatMoneyNotSDec } from "../../hooks/formatNumber";
import * as V from "../../pages/Styles/Vencedor";
import Header from "../Header";
import BordaDourada from "../../images/Borda_dourada_Vencedores_PNG.png";

import Kuadra from "../../images/kuadraQuadro.svg";
import StarsKuVencedores from "../../images/starsKuVencedores.svg";
import StarRightKu from "../../images/starsKuVencedores.svg";

import Kina from "../../images/kinaQuadro.svg";
import StarsKiVencedores from "../../images/starsKiVencedores.svg";
import StarRightKi from "../../images/starsKiVencedores.svg";

import Keno from "../../images/kenoQuadro.svg";
import StarsKeVencedores from "../../images/starsKeVencedores.svg";
import StarRightKe from "../../images/starsKeVencedores.svg";

import Acumulado from "../../images/kuadraQuadro.svg";
import StarLeftAc from "../../images/starsLeftAc.svg";
import StarRightAc from "../../images/starsRightAc.svg";

type quadroVencedor = {
  bolas?: number[];
  vencedor: number[];
  cartelas: ICartelas[];
  linhas_vencedor: number[];
  premio: number;
  tipo: string;
};
export const QuadroVencedoresTV: React.FC<quadroVencedor> = ({
  tipo,
  premio,
  vencedor,
  linhas_vencedor,
  cartelas,
  bolas,
}) => {
  return (
    <V.ConatinerModal style={{ height: "100vh" }}>
      {/* <V.ContentHeader tipo={tipo} style={{ width: "100%" }}>
            <Header tipo="limpo" />
          </V.ContentHeader> */}
      <V.ContentBoby>
        <V.QuadroVencedor tipo={tipo}>
          <V.TipoVencedores
            src={
              tipo === "kuadra"
                ? Kuadra
                : tipo === "kina"
                ? Kina
                : tipo === "keno"
                ? Keno
                : Acumulado
            }
          />
          <V.Stars
            style={{ left: "-7rem" }}
            src={
              tipo === "kuadra"
                ? StarsKuVencedores
                : tipo === "kina"
                ? StarsKiVencedores
                : tipo === "keno"
                ? StarsKeVencedores
                : StarLeftAc
            }
          />
          <V.Stars
            style={{ right: "-7rem", transform:"rotate(180deg)" }}
            src={
              tipo === "kuadra"
                ? StarRightKu
                : tipo === "kina"
                ? StarRightKi
                : tipo === "keno"
                ? StarRightKe
                : StarRightAc
            }
          />
          <V.BlocoQuadro tipo={tipo}>
            <V.BlocoInfoPremio>
              <div>
                <h2>PREMIAÇÃO</h2>
                <p>{formatMoney(premio)}</p>
              </div>
              <div>
                <h2>VENCEDORES</h2>
                <p>{vencedor.length}</p>
              </div>
              <div>
                <h2>POR VENCEDOR</h2>
                <p>{formatMoney(premio / vencedor.length)}</p>
              </div>
            </V.BlocoInfoPremio>
            <div
              style={{
                width: "90%",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              {vencedor.slice(0,4).map((vencedor, index) =>
                cartelas
                  .filter((x) => x.codigo === vencedor)
                  .map((cartela) => {
                    return (
                      <div
                        style={{
                          width: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center",
                        }}
                        key={`cartela_div_${cartela.codigo}`}
                      >
                        <div
                          style={{
                            width: "96%",
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                          }}
                        >
                          <V.ConatinerCard style={{ width: "100%" }}>
                            <V.HeaderCard tipo={tipo}>
                              <V.BlocoNumber>{cartela.codigo}</V.BlocoNumber>
                              <h1>{cartela.estabelecimento?.toUpperCase()}</h1>
                            </V.HeaderCard>
                            <V.BobyCard>
                              <V.LineCard>
                                {cartela.linha1_lista.map((bola) => {
                                  return (
                                    <V.BlocoCard
                                      key={`bola_bloco_${bola}_linha1`}
                                      style={{
                                        width: "calc(100% * (1/5))",
                                      }}
                                    >
                                      {(linhas_vencedor[index] === 0 &&
                                        bolas?.includes(bola)) ||
                                      linhas_vencedor[index] === 3 ? (
                                        <div
                                          style={{
                                            backgroundImage: `url(${BordaDourada})`,
                                            backgroundSize: "100% 100%",
                                            backgroundPosition: "left top",
                                          }}
                                        >
                                          {bola}
                                        </div>
                                      ) : (
                                        <div
                                          style={{
                                            background:
                                              "radial-gradient(79.29% 79.29% at 49.87% 45.36%, #F9F9F9 0%, #9D9D9D 100%)",
                                          }}
                                        >
                                          {bola}
                                        </div>
                                      )}
                                    </V.BlocoCard>
                                  );
                                })}
                                {cartela.linha2_lista.map((bola) => {
                                  return (
                                    <V.BlocoCard
                                      key={`bola_bloco_${bola}_linha2`}
                                      style={{
                                        width: "calc(100% * (1/5))",
                                      }}
                                    >
                                      {(linhas_vencedor[index] === 1 &&
                                        bolas?.includes(bola)) ||
                                      linhas_vencedor[index] === 3 ? (
                                        <div
                                          style={{
                                            backgroundImage: `url(${BordaDourada})`,
                                            backgroundSize: "100% 100%",
                                            backgroundPosition: "left top",
                                          }}
                                        >
                                          {bola}
                                        </div>
                                      ) : (
                                        <div
                                          style={{
                                            background:
                                              "radial-gradient(79.29% 79.29% at 49.87% 45.36%, #F9F9F9 0%, #9D9D9D 100%)",
                                          }}
                                        >
                                          {bola}
                                        </div>
                                      )}
                                    </V.BlocoCard>
                                  );
                                })}
                                {cartela.linha3_lista.map((bola) => {
                                  return (
                                    <V.BlocoCard
                                      key={`bola_bloco_${bola}_linha3`}
                                      style={{
                                        width: "calc(100% * (1/5))",
                                      }}
                                    >
                                      {(linhas_vencedor[index] === 2 &&
                                        bolas?.includes(bola)) ||
                                      linhas_vencedor[index] === 3 ? (
                                        <div
                                          style={{
                                            backgroundImage: `url(${BordaDourada})`,
                                            backgroundSize: "100% 100%",
                                            backgroundPosition: "left top",
                                          }}
                                        >
                                          {bola}
                                        </div>
                                      ) : (
                                        <div
                                          style={{
                                            background:
                                              "radial-gradient(79.29% 79.29% at 49.87% 45.36%, #F9F9F9 0%, #9D9D9D 100%)",
                                          }}
                                        >
                                          {bola}
                                        </div>
                                      )}
                                    </V.BlocoCard>
                                  );
                                })}
                              </V.LineCard>
                              <V.ContentLine>
                                <V.LineCart />
                              </V.ContentLine>
                            </V.BobyCard>
                          </V.ConatinerCard>
                        </div>
                      </div>
                    );
                  })
              )}
            </div>
          </V.BlocoQuadro>
        </V.QuadroVencedor>
      </V.ContentBoby>
    </V.ConatinerModal>
  );
};
