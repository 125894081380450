import React from "react";
import { Online, Offline } from "react-detect-offline";
import * as Style from "../componentsStyle/CheckNetwork";
import Alert from "../images/alert";

interface CheckNetworkProps {
  connectServer?: boolean;
}

const CheckNetwork = ({ connectServer }: CheckNetworkProps) => {

  return (
    <>
      <Offline>
        <Style.Container>
          <Alert />
          <Style.Row>
            <span>SEM CONEXÃO COM A INTERNET</span>
          </Style.Row>
        </Style.Container>
      </Offline>
      <Online>
        {connectServer != undefined && !connectServer && (
          <Style.Container>
            <Alert />
            <Style.Row>
              <span>SEM CONEXÃO COM O SERVIDOR</span>
            </Style.Row>
          </Style.Container>
        )}
      </Online>
    </>
  );
};

export default CheckNetwork;
