import React, { useState } from "react";
import backgroundImg from "../../images/JOGOSDASORTE-BACKGROUND-INSTA.svg";
import * as Style from "./style";

const Instagran = () =>{

    const [Passo,setPasso] = useState<1|2|3>(1)
    return(
        <Style.Container>
            {Passo == 1 && 
                <>
                    <Style.ImgBackground src={backgroundImg}/>
                    <Style.CelContainerCut>
                        <Style.CelBody>
                            <Style.CelBodyContent>
                                <Style.Navigation>Passo 1</Style.Navigation>
                                <Style.TitleCel>
                                    <span className="title">SIGA</span>
                                    <span className="title">O INSTAGRAM</span>
                                    <span className="nameInsta">@JOGOSDASORTEBR</span>
                                </Style.TitleCel>
                                <button style={{
                                    width:"13rem",
                                    fontSize:"1.1rem",
                                    borderRadius: "2rem",
                                    fontWeight:"bold",
                                    padding:"0.1rem 1rem",
                                    marginTop:"1rem",
                                    border: "0.2rem solid #FFFFFF",
                                    background: "#000000",
                                    color: "#ffffff"
                                }} onClick={()=>{setPasso(2)}}>SEGUIR AGORA</button>
                                <button style={{
                                    width:"13rem",
                                    fontSize:"0.9rem",
                                    borderRadius: "2rem",
                                    fontWeight:"bold",
                                    padding:"0.1rem 1rem",
                                    marginTop:"0.5rem",
                                    border: "0.2rem solid #FFFFFF",
                                    background: "#000000",
                                    color: "#ffffff"
                                }} onClick={()=>{setPasso(2)}}>JÁ SIGO O JOGOSDASORTE</button>
                            </Style.CelBodyContent>
                        </Style.CelBody>
                    </Style.CelContainerCut>
                </>
            }
            {Passo == 2 && 
                <>
                    <Style.ImgBackground src={backgroundImg}/>
                    <Style.CelContainerCut>
                        <Style.CelBody>
                            <Style.CelBodyContent>
                                <Style.Navigation>Passo 2</Style.Navigation>
                                <Style.TitleCel style={{margin:"0"}}>
                                    <span className="title" style={{margin:"0.5rem 0"}}>DIGITE SEU @</span>
                                </Style.TitleCel>
                                <Style.Input placeholder="@MEUINSTAGRAM"/>
                                <Style.ArrowDropDownIcon style={{marginTop:"0.5rem"}} size="1.5rem" color="#7BE548"/>
                                <button onClick={()=>{setPasso(3)}} style={{
                                    width:"13rem",
                                    fontSize:"1.1rem",
                                    borderRadius: "2rem",
                                    fontWeight:"500",
                                    padding:"0.1rem 1rem",
                                    background: "#000000",
                                    color:"#7BE548",
                                    border:"0.1rem solid #7BE548"
                                }}>GERAR BILHETE</button>
                            </Style.CelBodyContent>
                        </Style.CelBody>
                    </Style.CelContainerCut>
                </>
            }
            {Passo == 3 && 
                <>
                    <Style.ImgBackground src={backgroundImg}/>
                    <Style.CelContainerCut>
                        <Style.CelBody>
                            <Style.CelBodyContent>
                                <Style.Navigation>Passo 3</Style.Navigation>
                                <Style.TextCel>SEU BILHETE ESTÁ PRONTO</Style.TextCel>
                                <Style.Input placeholder="@MEUINSTAGRAM"/>
                                <Style.ArrowDropDownIcon style={{marginTop:"0.5rem"}} size="1.5rem" color="#7BE548"/>
                                <button style={{
                                    width:"13rem",
                                    fontSize:"1.1rem",
                                    borderRadius: "2rem",
                                    fontWeight:"500",
                                    padding:"0.1rem 1rem",
                                    background: "#7BE548",
                                    color:"#000000",
                                    border:"0.1rem solid #7BE548"
                                }}>PEGAR BILHETE</button>
                                <Style.ArrowDropUpIcon size="1.5rem" color="#7BE548"/>
                            </Style.CelBodyContent>
                        </Style.CelBody>
                    </Style.CelContainerCut>
                </>
            }
        </Style.Container>
    )
}

export default Instagran;