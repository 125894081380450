import React from 'react';

import {
    Card,
    HeaderCard,
    CardNumber,
    NomeEstabelecimento,
    ContainerNumbers,
    Number,
} from "../pages/Styles/MinhasCartelas";
import { estabelecimentoNome } from "../hooks/formatString";
import { CardPremiacao } from "../components/MinhasCartelas";


interface CartelaProps {
    index: number;
    style: React.CSSProperties;
    cartelas: ICartelas[];
    bolasTurno?: number[];
    sorteio?: IPartida;
}
const Cartela: React.FC<CartelaProps> = ({ index, style, cartelas, bolasTurno, sorteio }) => {
    let height = 0;
    let top = 0;
    if (typeof style.height == 'number' && typeof style.top == 'number') {
        height = style.height;
        top = style.top;
    }

    const vencedorCartelaKuadra = (codigo: number) => {
        if (bolasTurno?.find((val) => sorteio?.bola_kuadra == val) !== undefined)
            if (sorteio?.vencedores_kuadra.find((val) => codigo == val)) return true;
            else return false;
        else return false;
    };

    const vencedorCartelaKina = (codigo: number) => {
        if (bolasTurno?.find((val) => sorteio?.bola_kina == val) !== undefined)
            if (sorteio?.vencedores_kina.find((val) => codigo == val)) return true;
            else return false;
        else return false;
    };

    const vencedorCartelaKeno = (codigo: number) => {
        if (bolasTurno?.find((val) => sorteio?.bola_keno == val) !== undefined)
            if (sorteio?.vencedores_keno.find((val) => codigo == val)) return true;
            else return false;
        else return false;
    };

    const ballDrawn = (number:number) =>{
        let drawn:boolean=false;
        bolasTurno?.find((bolas) =>{
            if(bolas == number) return drawn = true
        })
        return drawn;
    }

    return (
        <>
            {cartelas.length > 0 && style !== null && (
                <Card key={`cartela_div_${cartelas[index].codigo}`}
                    style={{
                        ...style,
                        top: top + 5,
                        height: height - 5
                    }}>
                    <HeaderCard>
                        <CardNumber>{cartelas[index].codigo}</CardNumber>
                        <NomeEstabelecimento>
                            {cartelas[index]?.estabelecimento
                                ? estabelecimentoNome(cartelas[index]?.estabelecimento, 15)
                                : ""}
                        </NomeEstabelecimento>
                    </HeaderCard>
                    { }
                    <ContainerNumbers>
                      
                        {cartelas[index].linha1_lista.map((number, index) =>
                            ballDrawn(number)?
                                <Number
                                    style={ {background:"#008000"}}
                                    key={`cartela_number_${number}`}
                                >
                                    {number}
                                </Number>
                            :
                                <Number
                                    key={`cartela_number_${number}`}
                                >
                                    {number}
                                </Number>
                        )}
                        {cartelas[index].linha2_lista.map((number, index) =>
                            ballDrawn(number)?
                                <Number
                                    style={ {background:"#008000"}}
                                    key={`cartela_number_${number}`}
                                >
                                    {number}
                                </Number>
                            :
                                <Number
                                    key={`cartela_number_${number}`}
                                >
                                    {number}
                                </Number>
                        )}
                        {cartelas[index].linha3_lista.map((number, index) =>
                            ballDrawn(number)?
                                <Number
                                    style={ {background:"#008000"}}
                                    key={`cartela_number_${number}`}
                                >
                                    {number}
                                </Number>
                            :
                                <Number
                                    key={`cartela_number_${number}`}
                                >
                                    {number}
                                </Number>
                        )}
                    </ContainerNumbers>
                    <div id="line" />
                    {
                        cartelas[index].codigo ? (
                            <div style={{ display: "flex", padding: "0.2rem" }}>
                                <CardPremiacao
                                    tipo="kuadra"
                                    isPremio={vencedorCartelaKuadra(cartelas[index].codigo)}
                                />
                                <CardPremiacao
                                    tipo="kina"
                                    isPremio={vencedorCartelaKina(cartelas[index].codigo)}
                                />
                                <CardPremiacao
                                    tipo="keno"
                                    isPremio={vencedorCartelaKeno(cartelas[index].codigo)}
                                />
                            </div>
                        ) : (
                            <></>
                        )
                    }

                </Card>
            )}

        </>
    )
}

export default Cartela;