import styled from "styled-components";
import moedas from "../../images/moeda.svg";
import moedasGrande from "../../images/moedaGrande.svg";

export const ContentPremio = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  background: #000000b2;
  width: 100vw;
  min-height: 100vh;
`;

export const ImagemPremio = styled.div`
  width: 100%;
  max-width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${moedas});
  background-size: auto 100vh;
  background-position: center;
  background-repeat: no-repeat;
  box-sizing: border-box;
  margin: 0;

  @media (min-width: 620px) {
    background-size: auto 125%;
    align-self: center;
    align-content: center;
    background-image: url(${moedasGrande});
    /* background-image: none; */
  }
`;

export const ConatinerModal = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 10;
  background: radial-gradient(
    116.41% 62.81% at 50% 50%,
    #8f94ce 0%,
    #282a81 100%
  );
`;

export const ContentHeader = styled.div<{ tipo: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

export const ContentBoby = styled.div`
  width: 100%;
  height: 55vh;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2%;
`;

export const QuadroVencedor = styled.div<{ tipo: string }>`
  width: 80%;
  padding: 1rem 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1rem solid #ffffff;
  background: ${(props) =>
    props.tipo === "kuadra"
      ? "#156410"
      : props.tipo === "kina"
      ? "#131144"
      : props.tipo === "keno"
      ? "#8B8417"
      : "#F2AA0E"};
  border-radius: 1rem;
  position: relative;
`;

export const TipoVencedores = styled.img`
  height: 4rem;
  position: absolute;
  top: -3rem;
  z-index: 6;
`;

export const Stars = styled.img`
  height: 70vh;
  position: absolute;
`;

export const BlocoQuadro = styled.div<{ tipo: string }>`
  width: 75%;
  border: 0.4rem solid
    ${(props) =>
      props.tipo === "kuadra"
        ? "#194F06"
        : props.tipo === "kina"
        ? "#131126"
        : props.tipo === "keno"
        ? "#A4460B"
        : "#602E13"};
  border-radius: 0.5rem;
  padding: 1rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 5;
  background: ${(props) =>
    props.tipo === "kuadra"
      ? "radial-gradient(79.29% 79.29% at 49.87% 45.36%, #23A830 0%, #116C1A 100%)"
      : props.tipo === "kina"
      ? "radial-gradient(79.29% 79.29% at 49.87% 45.36%, #1B10B1 0%, #111040 100%)"
      : props.tipo === "keno"
      ? "radial-gradient(79.29% 79.29% at 49.87% 45.36%, #E8D316 0%, #A69F00 100%)"
      : "#F8DC23"};
`;

export const BlocoInfoPremio = styled.div`
  width: 90%;
  div {
    margin-bottom: 0.4rem;
    background: rgba(18, 18, 18, 0.5);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    font-weight: bold;
    color: #ffffff;
    h2 {
      margin: 0;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    p {
      margin: 0;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.1rem;
    }
  }
`;

export const HeaderVencedor = styled.div<{ tipo: string }>`
  padding: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  background: ${(props) =>
    props.tipo === "kuadra"
      ? "radial-gradient(79.29% 79.29% at 49.87% 45.36%, #1FA71C 0%, #0D3107 100%)"
      : props.tipo === "kina"
      ? "radial-gradient(79.29% 79.29% at 49.87% 45.36%, #1B10B1 0%, #111040 100%)"
      : props.tipo === "keno"
      ? "radial-gradient(79.29% 79.29% at 49.87% 45.36%, #E8D316 0%, #585504 100%)"
      : props.tipo === "acumulado"
      ? "radial-gradient(79.29% 79.29% at 49.87% 45.36%, #8B7E07 0%, #696509 100%)"
      : "radial-gradient(79.29% 79.29% at 49.87% 45.36%, #1B10B1 0%, #111040 100%)"};
  @media (min-width: 1024px) {
    background: transparent;
    grid-area: header;
  }
`;

export const HeaderVencedores = styled(HeaderVencedor)`
  @media (min-width: 1024px) {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 4px solid #ffffff;
    box-shadow: 1px 10px 15px 10px #0d0a0a7f;
    margin-bottom: 2rem;
    background: radial-gradient(
      79.29% 79.29% at 49.87% 45.36%,
      #1b10b1 0%,
      #111040 100%
    );
    grid-area: header;
  }
`;

export const ContainerVencedoresTables = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0.5rem 1rem;
  @media (min-width: 1024px) {
    flex-direction: row;
    align-items: flex-start;
    gap: 2rem;
    padding: 2rem;
  }
`;

export const Title = styled.h1`
  color: #ffffff;
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
`;

export const BlocoTitle = styled.div`
  position: relative;
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    line-height: 1.9rem;
    font-size: 2.1rem;
    text-align: center;
    font-weight: 700;
    color: #ffffff;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #000;
    z-index: 5;
    @media (min-width: 1024px) {
      font-size: 2.5rem;
      font-weight: 900;
      line-height: 2.2rem;
    }
  }
`;

export const BlocoTitleVen = styled(BlocoTitle)`
  @media (min-width: 1024px) {
    padding: 0 2rem;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const Premiacao = styled.div`
  width: 100%;
  padding: 0.7rem 1rem;
  background: radial-gradient(
    79.29% 79.29% at 49.87% 45.36%,
    #656cd5 0%,
    #1a1b43 100%
  );
  @media (min-width: 1024px) {
    width: 18rem;
    padding: 0;
  }
`;

export const TabelaWinner = styled.div`
  width: 95%;
  border-radius: 6px;
  background: #ffffff;
  padding: 0.7rem;
`;

export const TabelaHeader = styled.div`
  width: 100%;
  padding: 0.1rem 0;
  color: #12104e;
  font-size: 1.4rem;
  font-weight: 900;
  text-align: center;
  letter-spacing: -1px;
`;

export const TabelaBoby = styled.div<{ tipo: string }>`
  width: 100%;
  padding: 0.6rem 0;
  background: ${(props) =>
    props.tipo === "kuadra"
      ? "radial-gradient(79.29% 79.29% at 49.87% 45.36%, #1FA71C 0%, #0D3107 100%)"
      : props.tipo === "kina"
      ? "radial-gradient(79.29% 79.29% at 49.87% 45.36%, #1B10B1 0%, #111040 100%)"
      : "radial-gradient(79.29% 79.29% at 49.87% 45.36%, #E8D316 0%, #585504 100%)"};
  border-radius: 6px;
`;

export const PremiacaoContent = styled.div<{
  widthTitle: number;
  widthValor: number;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0;
  background: rgba(18, 18, 18, 0.5);
  color: #fcfcfc;
  font-weight: bold;
  margin-bottom: 0.3rem;

  h2 {
    width: ${(props) => props.widthTitle}%;
    font-size: 1.3rem;
    letter-spacing: 0px;
    margin: 0;
    padding: 0;
    text-align: center;
    @media (min-width: 1024px) {
      text-align: center;
      width: 55%;
      background: radial-gradient(
        79.29% 79.29% at 49.87% 45.36%,
        #1fa71c 0%,
        #0d3107 100%
      );
    }
  }
  p {
    width: ${(props) => props.widthValor}%;
    border-radius: 0 0.5rem 0.5rem 0;
    text-align: center;
    font-size: 1.3rem;
    margin: 0;
    padding: 0;
  }
`;

export const BlocoImage = styled.div`
  width: 30%;
  @media (min-width: 1024px) {
    width: 40%;
  }
`;

export const BlocoImageVen = styled(BlocoImage)`
  @media (min-width: 1024px) {
    width: auto;
  }
`;

export const TitleCard = styled.h1<{ tipo: string }>`
  width: 100%;
  color: #ffffff;
  text-align: center;
  border-radius: 0.2rem;
  background: ${(props) =>
    props.tipo === "kuadra"
      ? "radial-gradient(79.29% 79.29% at 49.87% 45.36%, #1FA71C 0%, #0D3107 100%)"
      : props.tipo === "kina"
      ? "radial-gradient(79.29% 79.29% at 49.87% 45.36%, #1B10B1 0%, #111040 100%)"
      : "radial-gradient(79.29% 79.29% at 49.87% 45.36%, #E8D316 0%, #585504 100%)"}; ;
`;

export const ConatinerCard = styled.div`
  margin: 0.25rem 0;
  width: 100%;
  max-width: 25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  border-radius: 6px;
`;

export const HeaderCard = styled.div<{ tipo: String }>`
  padding: 0.1rem 0.4rem;
  width: 100%;
  @media (min-width: 1024px) {
  }
  display: flex;
  align-items: center;
  background: ${(props) =>
    props.tipo === "kuadra"
      ? "radial-gradient(79.29% 79.29% at 49.87% 45.36%, #1FA71C 0%, #0D3107 100%)"
      : props.tipo === "kina"
      ? "radial-gradient(79.29% 79.29% at 49.87% 45.36%, #1B10B1 0%, #111040 100%)"
      : props.tipo === "acumulado"
      ? "radial-gradient(79.29% 79.29% at 49.87% 45.36%, #8B7E07 0%, #696509 100%)"
      : "radial-gradient(79.29% 79.29% at 49.87% 45.36%, #E8D316 0%, #585504 100%)"};

  border-radius: 6px 6px 0 0;
  h1 {
    margin: 0;
    padding: 0.05rem 0.6rem;
    font-size: 1rem;
    font-weight: 500;
    color: #fffffb;
    @media (min-width: 1024px) {
      font-size: 1.2rem;
    }
  }
`;

export const BlocoNumber = styled.div`
  margin: 0;
  background: #ffffff;
  color: #0c0c0c;
  font-weight: bold;
  font-size: 1rem;
  text-align: center;
  width: 4rem;
  border-radius: 0.2rem;
  @media (min-width: 1024px) {
    font-size: 1.1rem;
  }
`;

export const BobyCard = styled.div`
  background: radial-gradient(
    79.29% 79.29% at 49.87% 45.36%,
    #ffffff 0%,
    #acacac 100%
  );
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0.2rem 0.2rem;
  border-radius: 0 0 0.2rem 0.2rem;
`;

export const LineCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;

export const BlocoCard = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  justify-content: space-around;
  height: 30px;
  padding: 0.1rem;
  border-radius: 0.3rem;
  font-weight: 900;
  @media (min-width: 1024px) {
    font-size: 1.1rem;
  }

  div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #838383;
    border-radius: 0.2rem;
    background-color: #f2ff00;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
`;

export const FooterCard = styled.div<{ tipo: string }>`
  padding: 0.1rem 0.6rem;
  background: ${(props: { tipo: string }) =>
    props.tipo === "kuadra"
      ? "radial-gradient(79.29% 79.29% at 49.87% 45.36%, #1FA71C 0%, #0D3107 100%)"
      : props.tipo === "kina"
      ? "radial-gradient(79.29% 79.29% at 49.87% 45.36%, #1B10B1 0%, #111040 100%)"
      : props.tipo === "acumulado"
      ? "radial-gradient(79.29% 79.29% at 49.87% 45.36%, #8B7E07 0%, #696509 100%)"
      : "radial-gradient(79.29% 79.29% at 49.87% 45.36%, #E8D316 0%, #585504 100%)"};
  border-radius: 0 0 0.4rem 0.4rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  h2 {
    display: flex;
    font-size: 1.5rem;
    font-weight: 500;
    color: #fffffb;
  }
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #0b0e1a;
    width: calc(100% * (1 / 2));
    border-radius: 0.2rem;
    border: 0.01rem solid #ffffff;
    height: 2rem;
    color: #ffff00;
    font-weight: 900;
    font-size: 1.2rem;
  }
`;

export const ContentLine = styled.div`
  width: 100%;
  padding: 0.2rem 0.2rem 0 0.2rem;
`;

export const LineCart = styled.div`
  background: radial-gradient(
    165.86% 165.86% at 50% 50%,
    #170e90 0%,
    #313240 100%
  );
  width: 100%;
  height: 0.2rem;
`;
