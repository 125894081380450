import { format, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import * as Style from "../componentsStyle/CardCompraSorteio";
import { currentValue, currentValueFormated } from "../hooks/anticipatedValue";
import { checkValidDate } from "../hooks/checkValidDate";
import { formatDate, timerSort } from "../hooks/formatTime";
import useInterval from "../hooks/useIntervals";

import { addToCart, remove, updateAmount } from "../store/modules/carrinho/actions";


interface ISorteiosSelecionados {
  sorteio: ISorteio;
  quantidade: number;
}

interface CardCompraSorteioProps {
  fullSize?: boolean;
  DataServidor?: string;
  DataPartida?: string;
  BilheteRequest?: string;
  pule?: string;
  sorteio?: ISorteio | undefined;
  showButtonCard?: boolean;
  confirm?: boolean;
  quantidade?: number | undefined;
  query?: string | undefined | null;
}

interface IPurchase {
  count: number;
}

const CardCompraSorteio: React.FC<CardCompraSorteioProps> = ({
  sorteio,
  confirm,
  quantidade,
  fullSize,
  DataServidor,
  DataPartida,
  BilheteRequest,
  pule,
  query,
  showButtonCard,
}) => {
  var template = "primaryNormal";
  const history = useHistory();
  const [codigo, setCodigo] = useState<number | undefined>(
    sorteio ? sorteio?.codigo : 0
  );
  //const codigo: number | undefined = +sorteio ? sorteio?.codigo : 0;
  const [count, setcount] = useState(0);
  const initialInfoState = {
    count: 1,
  };
  const [purchase, setPurchase] = useState<IPurchase>(initialInfoState);
  const dispatch = useDispatch();
  const carrinho = useSelector((state: any) => state.carrinho);

  useEffect(() => {}, []);
  if (codigo && sorteio) {
    if (sorteio?.tipo_rodada === 1) {
      if (codigo % 2 === 0) {
        template = "primaryNormal";
      }
      if (codigo % 2 !== 0) {
        template = "secondaryNormal";
      }
      // if (codigo % 3 === 0) {
      //   template = "tertiaryNormal";
      // }
    }
    if (sorteio?.tipo_rodada === 2) {
      if (codigo % 2 === 0) {
        template = "primarySpecial";
      }
      if (codigo % 2 !== 0) {
        template = "secondarySpecial";
      }
    }
    if (sorteio?.tipo_rodada === 3) {
      template = "primarySuperSpecial";
    }
  }

  const handleInputChange = (event: any) => {
    const re = /^[0-9\b]+$/;
    if (!re.test(event.target.value) && event.target.value !== "" || event.target.value > 999) return;
    const { name, value } = event.target;
    setPurchase({ ...purchase, [name]: parseInt(value) });
  };

  const handleOneLess = () => {
    if (purchase.count <= 1) {
    toast.error("O limite mínimo de compra é de 1 cartela.");
    return
  };
    if (purchase.count == null) {
      toast.error("O limite mínimo de compra é de 1 cartela.");
      setPurchase({count: purchase.count = 1 });
    };
    if (purchase.count == undefined) {
      toast.error("O limite mínimo de compra é de 1 cartela.");
      setPurchase({count: purchase.count = 1 });
    };
    setPurchase({ count: purchase.count - 1 });
  };

  const handleOneMore = () => {
    if (purchase.count >= 999) {
      toast.error("O limite máximo de compra é de 999 cartela por sorteio.");
      return;
    }
    setPurchase({ count: purchase.count + 1 });
  };

  const formatLocaleString = (value: string) => {
    const valueInt: number = +value;
    return valueInt.toLocaleString("pt-br", { minimumFractionDigits: 2 });
  };
  const formatLocaleStringNumber = (value: number) => {
    return value.toLocaleString("pt-br", { minimumFractionDigits: 2 });
  };

  const formatDateString = (value: string) => {
    //const formattedDate = format(parseISO(value), "dd'/'MM' ÀS ' HH:mm");
    const formattedDate = format(parseISO(value), "dd'/'MM'");
    return formattedDate;
  };

  const formatHourString = (value: string) => {
    const formattedDate = format(parseISO(value), "HH:mm");
    return formattedDate;
  };

  const handleAdd = async () => {
    if (purchase.count > 999) {
      toast.error("O limite é de 999 cartelas por sorteio.");
      return;
    }
    if (purchase.count == 0) return;

    if (sorteio && !isNaN(purchase.count)) {
      if (!checkValidDate(sorteio.data_partida)) {
        toast.error(
          "Esse sorteio não está mais disponível por conta do horário."
        );
        history.push("/comprar/sorteios?type=" + query + "&reload=true");
        return;
      }
      const item = { sorteio: sorteio, quantidade: purchase.count };

      if (carrinho.length > 0) {
        const index = carrinho.findIndex(
          (item: any) => item.sorteio.codigo === sorteio?.codigo
        );

        if (index >= 0) {
          if (carrinho[index].quantidade + purchase.count > 999) {
            toast.error("O limite é de 999 cartelas por sorteio.");
            return;
          }
          dispatch(updateAmount(sorteio.codigo, purchase.count));
        } else {
          dispatch(addToCart(item));
        }
      } else {
        dispatch(addToCart(item));
      }
      setPurchase({ count: 1 });
    }
  };

  useInterval(() => {
    setcount(count + 1);
  }, 1000);

  const handleDelete = () => {
    if (sorteio) {
      dispatch(remove(sorteio?.codigo));
    }
  };

  return (
    <Style.Container className={template}>
      <Style.Header>
        <h1>
          {sorteio ? (
            format(new Date(sorteio?.data_partida), "dd/MM/yyyy")
          ) : (
            <Style.SSkeletonLine />
          )}
        </h1>
        <span style={{ display: "flex", alignItems: "center" }}>
          SORTEIO:
          {sorteio ? <h3>{sorteio?.codigo}</h3> : <Style.SSkeletonLine />}
        </span>
        <Style.DataAndTrashGroup>
          <h1>
            {sorteio ? (
              format(new Date(sorteio?.data_partida), "HH:mm:ss")
            ) : (
              <Style.SSkeletonLine />
            )}
          </h1>
          {confirm && <Style.default onClick={() => handleDelete()} />}
        </Style.DataAndTrashGroup>
      </Style.Header>

      <Style.Content
        style={
          fullSize ? { flexDirection: "column" } : { flexDirection: "row" }
        }
      >
        <Style.ContentLeft
          className={template}
          style={fullSize ? { width: "100%" } : {}}
        >
          <Style.Values>
            <Style.Value>
              <span className={template}>KUADRA</span>
              {sorteio ? (
                <h4>{formatLocaleString(sorteio.valor_kuadra)}</h4>
              ) : (
                <Style.SSkeletonPulse />
              )}
            </Style.Value>
            <Style.Value>
              <span className={`${template} textKeno`}>KENO</span>

              {sorteio ? (
                <h4 className="textKeno">
                  {formatLocaleString(sorteio ? sorteio.valor_keno : "")}
                </h4>
              ) : (
                <Style.SSkeletonPulse />
              )}
            </Style.Value>
            <Style.Value>
              <span className={template}>KINA</span>
              {sorteio ? (
                <h4>{formatLocaleString(sorteio.valor_kina)}</h4>
              ) : (
                <Style.SSkeletonPulse />
              )}
            </Style.Value>
          </Style.Values>
          <Style.ConteinerInfoSorteio>
            <Style.InfoSorteio>
              {sorteio?.valor_antecipado &&
                "ANTECIPADA: R$" + sorteio?.valor_antecipado}
            </Style.InfoSorteio>

            <Style.InfoSorteio>
              {sorteio?.valor_cartela && "NORMAL: R$" + sorteio?.valor_cartela}
            </Style.InfoSorteio>
          </Style.ConteinerInfoSorteio>

          <Style.ContentLeftFooter />

        </Style.ContentLeft>

        {!fullSize ? (
          <Style.ContentRight>
            {confirm ? (
              <>
                <span>CONFIRME O VALOR</span>
                <span>
                  UNIDADE: R$ {sorteio ? currentValueFormated(sorteio) : ""}
                </span>
                <span>QUANTIDADE: {quantidade} </span>
                {quantidade && (
                  <button>
                    {formatLocaleStringNumber(
                      parseFloat(sorteio ? currentValue(sorteio) : "") *
                        quantidade
                    )}
                  </button>
                )}
              </>
            ) : (
              <>
                <span>QUANTIDADE DE CARTELAS</span>
                <Style.AmountGroup>
                  <button onClick={() => handleOneLess()}>
                    <Style.SubtractIcon />
                  </button>
                  <input
                    type="number"
                    name="count"
                    minLength={1}
                    maxLength={3}
                    onChange={handleInputChange}
                    value={purchase.count}
                  />
                  <button onClick={() => handleOneMore()}>
                    <Style.AddIcon />
                  </button>
                </Style.AmountGroup>

                {sorteio ? (
                  <button
                    style={{ height: "2rem", fontSize: "1rem" }}
                    onClick={() => handleAdd()}
                  >
                    ADICIONAR
                  </button>
                ) : (
                  <Style.SSkeletonPulse />
                )}
              </>
            )}
          </Style.ContentRight>
        ) : (
          <>
            {showButtonCard && (
              <>
                {sorteio && (
                  <Style.DivRodape>
                    {DataServidor && DataPartida && (
                      <div
                        style={{
                          color: "black",
                          fontWeight: "900",
                          alignSelf: "center",
                        }}
                      >
                        {sorteio.status == "replay" || sorteio.status == "agora"
                          ? "SORTEIO PRONTO"
                          : timerSort(
                              formatDate(DataPartida),
                              formatDate(DataServidor),
                              count
                            )}
                      </div>
                    )}

                    {sorteio.status == "replay" || sorteio.status == "agora" ? (
                      <Style.ButtonRed
                        onClick={() => history.push(`/${sorteio.codigo}`)}
                      >
                        REPLAY
                      </Style.ButtonRed>
                    ) : (
                      <Style.ButtonGreen
                        onClick={() =>
                          history.push(
                            `/sorteionaoaconteceu/?bilhete=${BilheteRequest}&data_partida=${sorteio.data_partida}&sorteio=${sorteio.codigo}&pule=${pule}`
                          )
                        }
                      >
                        VER SORTEIO
                      </Style.ButtonGreen>
                    )}
                  </Style.DivRodape>
                )}
              </>
            )}
          </>
        )}
      </Style.Content>
    </Style.Container>
  );
};
export default CardCompraSorteio;
