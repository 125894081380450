import axios from "axios";


const api = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    'Authorization': `Token ${process.env.REACT_APP_TOKEN}`
  },

});

export default api;

