import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import CheckNetwork from "../../components/CheckNetwork";
import Header from "../../components/Header";
import NomeServidor from "../../components/NomeServidor";
import Moedas1 from "../../images/comprar/Moedas1.svg";
import Moedas2 from "../../images/comprar/Moedas2.svg";
import Moedas3 from "../../images/comprar/Moedas3.svg";
import { GetProximosSorteiosCompra } from "../../WebRequests/requestsJson";
import * as S from "../Styles/ComprarStyle";


export function Comprar() {
  const history = useHistory();
  const [query, setQuery] = useState("");
  const [sorteiosNormais, setSorteiosNormais] = useState<ISorteio[]>([]);
  const [sorteioEspeciais, setSorteiosEspeciais] = useState<ISorteio[]>([]);
  const [sorteiosSuperEspeciais, setSorteiosSuperEspeciais] = useState<
    ISorteio[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };

  useEffect(() => {
    clearCacheData();
    setIsLoading(true);
    async function fetch() {
      const { data, error } = await GetProximosSorteiosCompra();
      if (data) {
        setSorteiosNormais(
          data.sorteios.filter((x: any) => x.tipo_rodada === 1)
        );
        setSorteiosEspeciais(
          data.sorteios.filter((x: any) => x.tipo_rodada === 2)
        );
        setSorteiosSuperEspeciais(
          data.sorteios.filter((x: any) => x.tipo_rodada === 3)
        );
      }
      setIsLoading(false);
    }

    fetch();
  }, []);

  const loading = () => (
    <S.StyledSpinner viewBox="0 0 50 50">
      <circle
        className="path"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        strokeWidth="8"
      />
    </S.StyledSpinner>
  );
  return (
    <S.Container>
      <Header tipo="loja" />
      <S.ContentMeio>
        <S.Title>ESCOLHA O TIPO DE SORTEIO</S.Title>
        <S.TitleBox>
          {!isLoading ? sorteiosNormais.length : loading()}{" "}
          {sorteiosNormais && sorteiosNormais?.length > 1
            ? "SORTEIOS"
            : "SORTEIO"}
        </S.TitleBox>
        <S.Box onClick={() => history.push("/comprar/sorteios?type=normal")}>
          <S.DivBox>
            <S.Text2Box>CLIQUE PARA PARTICIPAR</S.Text2Box>
          </S.DivBox>
          <img style={{ margin: "0" }} src={Moedas1} />
        </S.Box>
        <S.TitleBox>
          {!isLoading ? sorteioEspeciais?.length : loading()}{" "}
          {sorteioEspeciais && sorteioEspeciais?.length > 1
            ? "SORTEIOS ESPECIAIS"
            : "SORTEIO ESPECIAL"}
        </S.TitleBox>
        <S.Box2 onClick={() => history.push("/comprar/sorteios?type=special")}>
          <S.DivBox>
            <S.Text2Box
              style={{
                textShadow:
                  "1px 0 0 #0d0b2d, -1px 0 0 #0d0b2d, 0 1px 0 #0d0b2d, 0 1px 0 #0d0b2d, 1px 1px #0d0b2d, -1px -1px 0 #0d0b2d, 1px -1px 0 #0d0b2d, -1px 1px 0 #0d0b2d",
              }}
            >
              CLIQUE PARA PARTICIPAR
            </S.Text2Box>
          </S.DivBox>
          <img style={{ margin: "0" }} src={Moedas2} />
        </S.Box2>
        <S.TitleBox>
          {!isLoading ? sorteiosSuperEspeciais?.length : loading()}{" "}
          {sorteiosSuperEspeciais && sorteiosSuperEspeciais?.length > 1
            ? "SORTEIOS SUPER ESPECIAIS"
            : "SORTEIO SUPER ESPECIAL"}
        </S.TitleBox>
        <S.Box3 onClick={() => history.push("/comprar/sorteios?type=super")}>
          <S.DivBox>
            <S.Text2Box>CLIQUE PARA PARTICIPAR</S.Text2Box>
          </S.DivBox>
          <img style={{ margin: "0" }} src={Moedas3} />
        </S.Box3>
      </S.ContentMeio>
      <NomeServidor />
      <CheckNetwork />
    </S.Container>
  );
}

export default Comprar;
