import styled from "styled-components";
import { Unmute } from "styled-icons/octicons";
import { VolumeMute } from "@styled-icons/remix-fill/VolumeMute";

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 3rem);
  display: flex;
  flex-wrap: wrap;
  background: radial-gradient(
    79.29% 79.29% at 49.87% 45.36%,
    #656cd5 0%,
    #1a1b43 100%
  );
  position: relative;
`;

export const Header = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 13.5vh;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const GroupLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 40%;
  height: 15%;
  padding: 0.5rem 0.5rem;

  border-bottom-right-radius: 30px;
  grid-area: acumulado;

  background: #1f1a64;
  box-shadow: 0px 4px 0px #000000;
  @media (min-width: 1024px) {
    padding: 1rem 0.5rem;
  }
`;

export const ContentLogo = styled.div`
  margin-left: 10px;
`;

export const BoxAcumulado = styled.div`
  background: #02031b;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 1rem;
  border-radius: 10px;
  border: 1px solid white;
  height: 100%;

  @media (min-width: 2048px) {
  }

  h3 {
    color: #f0d50c;
    margin: 0px;
    font-weight: 500;
    align-self: center;
  }
`;

export const TextRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
  span {
    font-size: 1rem;
    color: white;
    margin: 0px;
    margin-right: 3px;
    font-weight: 500;
    line-height: 1.3rem;
    font-family: "Oswald", sans-serif;
  }
  @media (min-width: 2048px) {
    span {
    }
  }
`;

export const TextRowValue = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  span {
    font-size: 1rem;
    color: white;
    margin: 0px;
    margin-right: 3px;
    font-weight: 500;
    font-family: "Oswald", sans-serif;
  }
  @media (min-width: 2048px) {
    span {
    }
  }
`;

export const GroupRight = styled.div`
  width: 40%;
  height: 15%;
  padding: 0 1rem;
  border-bottom-left-radius: 30px;
  grid-area: premio;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1f1a64;
  box-shadow: 0px 4px 0px #000000;
`;

export const BoxSorteio = styled.div`
  width: 20%;
  height: 10%;
  background: #1f1a64;
  box-shadow: 0px 4px 0px #000000;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding: 15px;
  grid-area: sorteio;

  span {
    font-size: 1.5rem;
    color: #f0d50c;
    margin: 0px;
    font-weight: 500;
    align-self: center;
  }
  h3 {
    font-size: 2rem;
    color: white;
    margin: 0px;
    font-weight: 500;
    line-height: 1.5rem;
    align-self: center;
    margin-left: 5px;
  }
  @media (min-width: 2048px) {
    span {
      color: #f0d50c;
      margin: 0px;
      font-weight: 500;
      line-height: 1.5rem;
      align-self: center;
    }
    h3 {
      color: white;
      margin: 0px;
      font-weight: 500;
      line-height: 1.5rem;
      align-self: center;
      margin-left: 5px;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
  @media (min-width: 2048px) {
    height: 80%;
  }
`;

export const ContainerBolasSorteadas = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
`;

export const ContentBolas = styled.div`
  width: 5rem;
  height: 1.2rem;
  background: #ffffff;
  border-radius: 0.2rem;
  font-size: 1.2rem;
  color: #0c0c0c;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 1024px) {
    height: 1.5rem;
  }
`;

export const GroupCenterContent = styled.div`
  width: 100%;
`;

export const CartHolder = styled.div`
  width: 20%;
  margin: -2rem 0 0;
  height: calc(83% + 2rem);
  padding: 0 0rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  grid-area: cartelas;
`;

export const GroupLeftContent = styled.div`
  padding: 1rem 0.8rem 0;
  width: 40%;
  height: 83%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  grid-area: tabela;
  @media (min-width: 2048px) {
    height: 102%;
  }
`;

export const GroupRightContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 40%;
  @media (min-width: 2048px) {
    height: 100%;
    display: grid;
    grid-template-areas:
      "bola tubo"
      "bolasSorte tubo";
    grid-template-rows: 60% 40%;
    grid-template-columns: 80% 20%;
  }
`;

export const ContainerTubeBall = styled.div`
  width: 100%;
  grid-area: globo;
  @media (min-width: 1366px) {
  }
  @media (min-width: 2048px) {
  }
`;

export const TittleBalls = styled.div`
  height: 15%;
  margin: 0;
  padding: 0 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  font-weight: 600;
  text-align: "center";
  background: #1f1a64;
  width: 100%;
  color: white;

  border-radius: 0.5rem 0.5rem 0 0;
  @media (min-width: 1024px) {
    font-size: 1.6rem;
  }
  @media (min-width: 2048px) {
    font-size: 1.3rem;
  }
  p {
    margin: 0;
  }
`;

export const BlocoBalls = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 85%;

  background: #0c0c0c;
  padding: 0.4rem 0.2rem;

  border-radius: 0 0 0.5rem 0.5rem;
  @media (min-width: 1028px) {
    padding: 0 0;
  }
  @media (min-width: 1366px) {
  }
  @media (min-width: 2048px) {
  }
`;

export const ContentBalls = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  width: calc(100% * (1 / 10));
  height: calc(100% * (1 / 9));
`;

export const Balls = styled.p<{ Ativo: boolean }>`
  font-size: 1.1rem;
  font-weight: 300;
  color: ${(props) => (props.Ativo ? "#09d335" : "#bbbbbb")};

  @media (min-width: 900px) {
    font-size: 0.9rem;
  }

  @media (min-width: 1028px) {
    font-size: 1rem;
  }
  @media (min-width: 2048px) {
  }
`;

export const ContentAudio = styled.div`
  width: 100%;
  align-items: flex-start;
  display: flex;
  padding-left: 0.8rem;
  align-items: center;
  align-items: stretch;
`;

export const GoMuteIcon = styled(Unmute)`
  color: white;
  width: 2rem;
  height: 2rem;
`;

export const VolumeMuteIcon = styled(VolumeMute)`
  color: white;
  width: 2rem;
  height: 2rem;
`;
