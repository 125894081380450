import styled from "styled-components";
import { BodyADS, CardContainerTv, CardSecond, CardThird, ContainerADS, HeaderADS, Title } from "./CardSorteTv";

export const TitleContent = styled(Title)`
    
`;
export const Container = styled(CardContainerTv)`
    height: 70%;

    h1{
        color: #12104E;
        text-align: center;
        
    }
`;

export const ContaineCard = styled(ContainerADS)`

`;

export const HeaderCard = styled(HeaderADS)`

`;

export const BlocoHeader = styled.div<{t:string,jC:"left"|"center"|"right"}>`
    width: ${props=>props.t};
    padding: 0.7rem 1rem;
    text-align: ${props=>props.jC};
    font-size: 1.7rem;
    font-weight: bold;
    color: #12104E;

`;

export const BodyCard = styled(BodyADS)`
    margin-bottom: 1rem;
    padding: 1rem 0 0.6rem;
`;

export const LineCard = styled.div`
    width:100%;
    margin-bottom: 0.4rem;
    display: flex;
    flex-direction: row;
    background: #12121280;
`;

export const BlocoCard = styled.div<{t:string,jC:"left"|"center"|"right",tipo?:number,tipoPremio?:1|2|3|4}>`
    color: #ffffff;
    font-size: 1.7rem;
    font-weight: bold;

    padding: 0rem 1rem;

    width: ${props=>props.t};
    text-align: ${props=>props.jC};

    ${props => props.tipoPremio&& `
        display: flex;
        justify-content: space-around;
    `}

    span{
        ${props => props.tipo === 2 ?
            `background: -webkit-linear-gradient(89.48deg, #CD911B -9.2%, #F8B123 14.81%, #FEFC37 26.55%, #FFFE8E 44.15%, #FEFD5A 62.3%, #F8B125 79.37%, #CD911B 93.24%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;`
        :props.tipo === 3?
            `background: -webkit-linear-gradient(92.18deg, #5993D4 -8.3%, #AFE2FF 16.91%, #FFEDF7 46.1%, #FDCDE1 74.65%, #FE7CEC 96.4%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;`
        :
            `color:#ffffff;`
        };

        ${props => props.tipoPremio === 1 && `color: #20B12E;`}
        ${props => props.tipoPremio === 2 && `color: #202EB1;`}
        ${props => props.tipoPremio === 3 && `color: #F0D50C;`}
        ${props => props.tipoPremio === 4 && `color: #D0941F;`}
    }
`;


export const CardSecondary = styled(CardSecond)``;

export const Cardthird = styled(CardThird)``;