import { RefObject, useEffect, useRef } from "react";

export function useClick(elementRef: RefObject<HTMLElement>) {
  const clicksRef = useRef(0);
  useEffect(() => {
    const element = elementRef?.current
    if (element && clicksRef.current < 1) {
      console.log('click')
      clicksRef.current += 1;
      elementRef.current?.dispatchEvent(new Event('click', { bubbles: true }))
    }
  });
}