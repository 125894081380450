import { Star } from "@styled-icons/evaicons-solid/Star";
import { Spinner } from "@styled-icons/icomoon/Spinner";
import { Trash } from "@styled-icons/ionicons-sharp/Trash";
import { ExpandMore } from '@styled-icons/material-sharp/ExpandMore';
import styled, { keyframes } from "styled-components";
import { ArrowIosBack } from "styled-icons/evaicons-solid";

const rotate = keyframes`
    from {
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
`;

interface Props {
  isExpanded: boolean;
}

export const Container = styled.div`
  background: radial-gradient(
    116.41% 62.81% at 50% 50%,
    #8f94ce 0%,
    #3f408a 100%
  );
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const ContainerSemjogo = styled.div`
  display: flex;
  color: black;
  font-size: 2rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 2rem;
  padding: 1rem;
`;

export const Input = styled.input`
  height: 3.5rem;
  width: 60%;
  border: none;
  letter-spacing: 0.05rem;
  border-radius: 5px;
  //border: 4px solid #19164d;
  outline: none;
  font-size: 1.3rem;
  margin-top: 0.2rem;
  text-align: center;
  font-weight: 900;
  color: #0f0f0f;
  padding: 1rem;
`;

export const ButtonRed = styled.button`
  border: solid 1px #111040;
  color: white;
  background: radial-gradient(
    79.29% 79.29% at 49.87% 45.36%,
    #f73434 0%,
    #6c1c11 100%
  );
  border-radius: 5px;
  width: calc((1 / 3) * 100%);
  height: 1.6rem;
`;

export const ContainerInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-top: 1rem;
`;

export const ButtonGreen = styled(ButtonRed)`
  background: radial-gradient(
    79.29% 79.29% at 49.87% 45.36%,
    #23a830 0%,
    #116c1a 100%
  );
  width: 80%;
  font-size: 1.5rem;
  height: 3.5rem;
  margin-top: 1rem;

  svg {
    animation: ${rotate} 2s linear infinite;
  }
`;

export const Tittle = styled.h4`
  font-size: 1.7rem;
  color: #f0d50c;
`;

export const TittleWhite = styled.h4`
  font-size: 2.5rem;
  color: white;
  text-align: center;
  text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.81);
  margin-top: 1rem;
`;

export const TitleContainer = styled.div`
  width: 100%;
  margin-top: 1rem;
  background: radial-gradient(
    79.29% 79.29% at 49.87% 45.36%,
    #1b10b1 0%,
    #111040 100%
  );
  display: flex;
  height: auto;
  justify-content: center;
  align-items: center;
  font-weight: 900;
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem 0.5rem;
  grid-area: logo;
  width: 30%;
`;

export const LogoContainerSorteios = styled.div<Props>`
  display: flex;
  justify-content: center;
  width: 80%;
`;

export const ContainerCabecalho = styled.div`
  height: 8rem;
  width: 100%;
  display: flex;
  align-items: center;
  background: radial-gradient(
    79.29% 79.29% at 49.87% 45.36%,
    #1b10b1 0%,
    #111040 100%
  );
  justify-content: space-between;
`;

export const ContainerLeft = styled.div`
  border-right: solid 1px white;
  display: flex;
  width: 20%;
  height: 100%;
  background: radial-gradient(
    79.29% 79.29% at 49.87% 45.36%,
    #1e1a59 0%,
    #080720 100%
  );
  align-items: center;
  justify-content: center;
`;

export const ContainerMenu = styled.div`
  display: flex;
`;

export const Back = styled(ArrowIosBack)`
  color: white;
  height: 2rem;
`;

export const FormContainer = styled.div`
  margin-right: 1rem;
  grid-area: search;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f0d50c;
  border-radius: 5px 5px 5px 5px;
  border: none;
  width: 50%;
  height: 2.5rem;
  @media (min-width: 1024px) {
    margin-left: 5rem;
    margin-top: 1rem;
    width: 16rem;
  }
`;

export const Bilhetes = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Bilhete = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContainerSorteio = styled.div<Props>`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  overflow: auto;

  &.active {
    transition: max-height 0.2s ease-in-out;
    max-height: 15rem;
  }
  &.inative {
    transition: max-height 0.4s ease-in-out;
    max-height: 2.2rem;
  }
  overflow: hidden;
`;

export const HeaderSorteio = styled.div<Props>`
  width: 100%;
  align-self: flex-start;
  display: flex;
  align-items: center;
  text-align: center;
  span {
    color: #fff;
    font-size: 1.4rem;
    font-weight: bold;
    text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.81);
    margin-left: 5px;
  }
  button {
    background: radial-gradient(
      397.62% 397.62% at 51.13% -283.33%,
      #1b10b1 0%,
      #111040 100%
    );
    color: #fff;
    height: 35px;
    width: 40px;
    border-radius: 4px;
    border: 1px solid #fff;
    font-size: 1.2rem;
    font-weight: bold;
    &.right {
      right: 5%;
      position: absolute;
    }
    svg {
      font-weight: bold;
      &.expand {
        transform: ${(props) =>
          props.isExpanded ? "rotate(180deg)" : "rotate(0deg)"};
        transition: transform 0.3s ease-out;
      }
    }
  }
`;

export const ContentSorteio = styled.div<Props>`
  width: 100%;
  transform: ${(props) =>
    props.isExpanded ? "translateX(0vw)" : "translateX(100vw)"};

  transition: transform 0.3s ease-out;
`;

export const CheckBoxWrapper = styled.div`
  position: relative;
`;

export const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
  height: 26px;
  border-radius: 15px;
  background: #bebebe;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;

export const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 42px;
  height: 26px;
  &:checked + ${CheckBoxLabel} {
    background: #4fbe79;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      margin-left: 21px;
      transition: 0.2s;
    }
  }
`;

export const ButtonStar = styled.div`
  background: radial-gradient(
    397.62% 397.62% at 51.13% -283.33%,
    #1b10b1 0%,
    #111040 100%
  );
  color: #fff;
  height: 35px;
  width: 40px;
  border-radius: 4px;
  border: 1px solid #fff;
  font-size: 1.2rem;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

export const ContainerTittleStar = styled.div`
  display: flex;
  align-items: center;
  width: 90%;
  justify-content: flex-start;
  margin: 0;
  padding-top: 1rem;
`;

export const ContainerSkeleton = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  height: 15rem;
`;

export const HeaderSkeleton = styled.div`
  margin-bottom: 1rem;
  border-radius: 3px;
  display: inline-block;
  height: 2rem;
  width: 90%;
  background: linear-gradient(
    -90deg,
    #ffffff 0%,
    transparent 50%,
    #ffffff 100%
  );
  background-size: 400% 400%;
  animation: pulse 1.2s ease-in-out infinite;
  @keyframes pulse {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
`;

export const ContentSkeleton = styled.div`
  margin-bottom: 1rem;
  border-radius: 3px;
  display: inline-block;
  height: 10rem;
  width: 90%;
  background: linear-gradient(
    -90deg,
    #ffffff 0%,
    transparent 50%,
    #ffffff 100%
  );
  background-size: 400% 400%;
  animation: pulse 1.2s ease-in-out infinite;
  @keyframes pulse {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
`;

export const StarIcon = styled(Star)`
  height: 1rem;
  width: 1rem;
`;

export const SpinnerIcon = styled(Spinner)`
  height: 1rem;
  width: 1rem;
`;

export const TrashIcon = styled(Trash)`
  height: 1rem;
  width: 1rem;
`;

export const ExpandIcon = styled(ExpandMore)`
  height: 1rem;
  width: 1rem;
`;
