/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { useState } from "react";
import { VencedoresAnimacao } from "./TVcomponent/vencedores";
import { QuadroVencedores } from "./QuadroVencedores";
import useWindowDimensions from "../hooks/useWindowSize";
import { QuadroVencedoresTV } from "./TVcomponent/QuadroVencedoresTV";

type Card = {
  bolas?: number[];
  vencedor: number[];
  cartelas: ICartelas[];
  linhas_vencedor: number[];
  premio: number;
  tipo: string;
};

export const CardWinner: React.FC<Card> = ({
  cartelas,
  linhas_vencedor,
  premio,
  tipo,
  vencedor,
  bolas,
}) => {
  const [animacao, setAnimacao] = useState(true);
  setInterval(() => {
    setAnimacao(false);
  }, 2500);
  const { width } = useWindowDimensions();
  return (
    <>
      {animacao ? (
        <VencedoresAnimacao tipo={tipo} />
      ) : width < 680 ? (
        <QuadroVencedores
          cartelas={cartelas}
          linhas_vencedor={linhas_vencedor}
          premio={premio}
          tipo={tipo}
          vencedor={vencedor}
          bolas={bolas}
        />
      ) : (
        <QuadroVencedoresTV
          cartelas={cartelas}
          linhas_vencedor={linhas_vencedor}
          premio={premio}
          tipo={tipo}
          vencedor={vencedor}
          bolas={bolas}
        />
      )}
    </>
  );
};
