import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import * as S from "../pages/Styles/ComprarStyle";
import { GetLogoServer } from "./GetLogoServer";

type header = {
  AbrirMeusSorteios: () => void;
  AbrirMinhasCartelas: () => void;
  valor_acumulado: number;
  numero_bolas_acumulado: number;
  sorteio_codigo: number;
  numero_bolas: number;
};

const Header = ({
  AbrirMeusSorteios,
  AbrirMinhasCartelas,
  valor_acumulado,
  numero_bolas_acumulado,
  sorteio_codigo,
  numero_bolas,
}: header) => {
  const [carrinhoQuantidade, setCarrinhoQuantidade] = useState(0);
  const carrinho = useSelector((state: any) => state.carrinho);

  useEffect(() => {
    if (carrinho.length > 0) {
      let count = 0;
      for (let item of carrinho) {
        count += item.quantidade;
        setCarrinhoQuantidade(count);
      }
    } else {
      setCarrinhoQuantidade(0);
    }
  }, [carrinho]);

  const [query, setQuery] = useState("");
  const history = useHistory();

  return (
    <S.ContainerCabecalho style={{ height: "8rem", marginBottom: "3.3rem" }}>
      <div
        style={{
          display: "flex",
          height: "100%",
        }}
      >
        <S.ContainerLeft onClick={() => history.push("/")}>
          <S.HomeIcon />
        </S.ContainerLeft>
      </div>

      <S.ContainerRight
        style={{
          flexDirection: "column",
          width: "100%",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <S.ContainerTopCabecalho>
          <S.ContainerTexts style={{ width: "25%", alignItems: "flex-start" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                paddingLeft: "1rem",
              }}
            >
              <S.textTopWhite>SORTEIO</S.textTopWhite>
              <S.textTopYellow>{sorteio_codigo}</S.textTopYellow>
            </div>
          </S.ContainerTexts>
          <S.ContainerRightTop>
            <S.ContainerTexts style={{ paddingLeft: "1.5rem" }}>
              <S.textTopWhite>BOLAS</S.textTopWhite>
              <S.textTopYellow>{numero_bolas}</S.textTopYellow>
            </S.ContainerTexts>
            <S.ContainerTexts>
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <S.textTopWhite>ACUMULADO:</S.textTopWhite>
                <S.textTopYellow style={{ fontSize: "1rem" }}>
                  {numero_bolas_acumulado}
                </S.textTopYellow>
              </div>

              {valor_acumulado && (
                <S.textTopYellow>
                  {valor_acumulado
                    .toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })
                    .replace("R$", "")}
                </S.textTopYellow>
              )}
            </S.ContainerTexts>
          </S.ContainerRightTop>
        </S.ContainerTopCabecalho>
        <S.ContainerBotCabecalho>
          <S.LogoContainer
            tipo="menuMain"
            style={{ width: "6rem", margin: "0px", paddingLeft: "0rem" }}
          >
            <GetLogoServer tamanho="6rem" />
          </S.LogoContainer>

          <S.ButtonGroupGreen>
            <S.ButtonGreen
              style={{ border: "2px solid white" }}
              onClick={() => AbrirMeusSorteios()}
            >
              MEUS SORTEIOS
            </S.ButtonGreen>

            <S.ButtonGreen
              style={{ border: "2px solid white" }}
              onClick={() => AbrirMinhasCartelas()}
            >
              MINHAS CARTELAS
            </S.ButtonGreen>
          </S.ButtonGroupGreen>
        </S.ContainerBotCabecalho>
      </S.ContainerRight>
    </S.ContainerCabecalho>
  );
};

export default Header;
