export function ReplacePadStart(number: string | undefined, width: number) {
  if(number){
    width -= number.toString().length;
    if (width > 0) {
      return new Array(width + (/\./.test(number) ? 2 : 1)).join("0") + number;
    }
    return number + "";
  }else{
    return "";
  }
 
}
export default  ReplacePadStart;
