import React, { useState } from "react"
import useInterval from "../hooks/useIntervals";
import useWindowSize from "../hooks/useWindowSize";

//Para alterar a cor, precisa alterar o nome do id da url para pintura #prefix__paint0_radial
//Ele precisa ser unico entre todos os componentes
// export interface CustomItemProps {
//   color: string[] | undefined;
// }


export const Ball6C: React.FC = () => {
  const [color, setColor] = useState<string[] | undefined>([]);
  const [index, setIndex] = useState(0);

  const { width } = useWindowSize();
  
  const DefineSizeBall = () => { 
    if(width >= 2048){
      return '47rem';
    }
    else if(width >= 1028 && width <= 2047){
      return '14.5rem';
    }
    else{
       return '6.5rem';
    }
  }

  const isLargeScreen = width >= 1028 ? true : false;

  const colorBalls = [
    ["#0016D9", "#0013BA", "#000D82"],
    ["#D90000", "#BA0000", "#820000"],
    ["#D4D900", "#BAA700", "#827C00"],
    ["#11D900", "#0FBA00", "#00820D"],
    ["#00BFD9", "#00AFBA", "#008282"],
    ["#c0c0c0", "#a3a3a3", "#9e9e9e"],
  ]

  useInterval(() => {
   
    setColor(colorBalls[index]);
    if(index >= colorBalls.length - 1){
      setIndex(0)
      return;
    }
    setIndex(index+1);


  }, 100);

  return (

    <svg
      width={DefineSizeBall()}
      height={DefineSizeBall()}

      viewBox="0 0 468 468"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"

    >
      <circle cx={234} cy={234} r={234} fill="url(#prefix__paint000001_radial)" />
      <circle
        cx={233.5}
        cy={217.5}
        r={168.5}
        fill="url(#prefix__paint111112_radial)"
      />
      <circle cx={234} cy={218} r={145} fill="url(#prefix__paint222223_radial)" />
      <circle
        cx={129.5}
        cy={203.5}
        r={22.5}
        fill="url(#prefix__paint33333_radial)"
      />
      <circle
        cx={99.5}
        cy={357.5}
        r={13.5}
        fill="url(#prefix__paint444445_radial)"
      />
      <circle
        cx={326.5}
        cy={399.5}
        r={13.5}
        fill="url(#prefix__paint55555_radial)"
      />
      <ellipse
        cx={330.271}
        cy={84.567}
        rx={10}
        ry={22}
        transform="rotate(-37.223 330.271 84.567)"
        fill="url(#prefix__paint666667_radial)"
      />
      <ellipse
        cx={382.896}
        cy={266.73}
        rx={44.3}
        ry={28.929}
        transform="rotate(-68.977 382.896 266.73)"
        fill="url(#prefix__paint77777_radial)"
      />
      <defs>
        <radialGradient
          id="prefix__paint000001_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(90 0 234) scale(234)"
        >
          <stop stopColor={color ? color[0] : '#000'} />
          <stop offset={0.771} stopColor={color ? color[1] : '#000'} />
          <stop offset={1} stopColor={color ? color[2] : '#000'} />
        </radialGradient>
        <radialGradient
          id="prefix__paint111112_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(90 8 225.5) scale(168.5)"
        >
          <stop stopColor="#373737" />
          <stop offset={1} />
        </radialGradient>
        <radialGradient
          id="prefix__paint222223_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(90 8 226) scale(145)"
        >
          <stop stopColor="#E2E2E2" />
          <stop offset={0.938} stopColor="#BABABA" />
          <stop offset={1} stopColor="#969696" />
        </radialGradient>
        <radialGradient
          id="prefix__paint333334_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(90 -37 166.5) scale(22.5)"
        >
          <stop stopColor="#fff" />
          <stop offset={0.339} stopColor="#FFFCFC" stopOpacity={0.408} />
          <stop offset={0.74} stopColor="#FFFCFC" stopOpacity={0} />
        </radialGradient>
        <radialGradient
          id="prefix__paint44444_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(90 -129 228.5) scale(13.5)"
        >
          <stop stopColor="#fff" />
          <stop offset={0.339} stopColor="#FFFCFC" stopOpacity={0.408} />
          <stop offset={0.74} stopColor="#FFFCFC" stopOpacity={0} />
        </radialGradient>
        <radialGradient
          id="prefix__paint555556_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(90 -36.5 363) scale(13.5)"
        >
          <stop stopColor="#fff" />
          <stop offset={0.339} stopColor="#FFFCFC" stopOpacity={0.408} />
          <stop offset={0.74} stopColor="#FFFCFC" stopOpacity={0} />
        </radialGradient>
        <radialGradient
          id="prefix__paint66666_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(0 22 -10 0 330.271 84.567)"
        >
          <stop stopColor="#fff" />
          <stop offset={0.339} stopColor="#FFFCFC" stopOpacity={0.408} />
          <stop offset={0.74} stopColor="#FFFCFC" stopOpacity={0} />
        </radialGradient>
        <radialGradient
          id="prefix__paint77777_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(0 28.9294 -44.3005 0 382.896 266.73)"
        >
          <stop stopColor="#fff" />
          <stop offset={0.339} stopColor="#FFFCFC" stopOpacity={0.408} />
          <stop offset={0.74} stopColor="#FFFCFC" stopOpacity={0} />
        </radialGradient>
      </defs>
    </svg>
  )
}

export default Ball6C;
