import { parseISO, isBefore } from 'date-fns';

export const checkValidDate = (date: any) => {
    const parsedDate = parseISO(date);
    if (isBefore(new Date(), parsedDate)) {
        return true;
    } else {
        return false;
    }
}

export const checkValidDateCart = (carrinho: any) => {
    let list = [];
    if (carrinho) {
        for (let data of carrinho) {
            const parsedDate = parseISO(data.sorteio.data_partida);
            if (!isBefore(new Date(), parsedDate)) {
                list.push({
                    sorteio: data.sorteio
                })
            }
        }
    }
    return list;
}

