import styled from "styled-components";
import BallImg from "./images/ball.svg";
import Painel from "./images/PainelBolasChamadas.svg";
import { Unmute } from "@styled-icons/octicons/Unmute";
import { VolumeMute } from "@styled-icons/remix-fill/VolumeMute";

// Containers gerais 👇🏼👇🏼
export const Container = styled.div<{ TVMode?: string }>`
  display: flex;
  ${(props) => props.TVMode && "justify-content:center;"}
  flex-direction: column;
  background: radial-gradient(
    79.29% 79.29% at 49.87% 45.36%,
    #8f94ce 0%,
    #282a81 100%
  );
  align-items: center;
  width: 100%;
  min-height: 100vh;
  padding-bottom: 3.2rem;
`;

export const ButtonBack = styled.button`
  width: 5rem;
  height: 2.5rem;
  background: radial-gradient(
    397.62% 397.62% at 51.13% -283.33%,
    #1b10b1 0%,
    #111040 100%
  );
  border-radius: 10px;
  border: 0.5px solid white;
  align-items: center;
  justify-content: center;
  color: white;
  outline: none;
  display: flex;
  font-weight: 900;
  font-size: 1.4rem;
`;

export const ContainerButton = styled.div`
  font-size: 30px;
  align-items: right;
  margin-right: 0;
  right: 5px;
  display: flex;
`;

export const TittleWhite = styled.h4`
  font-size: 2.5rem;
  color: white;
  text-align: center;
  text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.81);
  margin-top: 1rem;
`;

export const ContainerTopo = styled.div`
  display: flex;
  flex-direction: row;
  background: radial-gradient(
    165.86% 165.86% at 50% 50%,
    #170e90 0%,
    #313240 100%
  );
  width: 100%;
  height: 7rem;
  padding: 0rem 0.3rem;
  align-items: flex-end;
`;

export const PadContainerTopo = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0rem 0.3rem;
  width: 100%;
  height: 3rem;
  justify-content: space-between;
  align-items: center;
  background: #211c6f;

  #elementoInvisivel {
    width: 100%;
    height: 2.5rem;
    flex-grow: 1;
    min-width: 130px;
  }
`;

export const ButtonPad = styled.button`
  width: 100%;
  flex-grow: 1;
  background: radial-gradient(
    79.29% 79.29% at 49.87% 45.36%,
    #1fa71c 0%,
    #0d3107 100%
  );
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 5px;
  color: #ffffff;
  height: 2.5rem;
  font-size: 1rem;
  line-height: 1rem;
  cursor: pointer;
  transition: opacity ease-in-out 1s all;
  :hover {
    opacity: 0.9;
  }
`;

export const PadCon1 = styled.div`
  display: flex;
  flex-direction: row;
  width: ((1/3) * 100%);
  flex-grow: 1;
  align-items: center;
  height: 2.5rem;
  margin-right: 4px;
`;

export const PadCon2 = styled.div`
  display: flex;
  flex-direction: row;
  background: #211c6f;
  width: calc(100% * (1 / 5));
  height: 50%;
  min-width: 100px;
  padding: 0.3rem;
  align-items: flex-end;
  outline: none;
  border: none;
`;

export const PadCon3 = styled.div`
  display: flex;
  flex-direction: row;
  background: #211c6f;
  width: calc(100% * (1 / 5));
  height: 100%;
  padding: 0.3rem;
  align-items: flex-end;
  border-bottom-left-radius: 10px;
`;

export const PadCon4 = styled.div`
  display: flex;
  flex-direction: row;
  background: #211c6f;
  width: calc(100% * (1 / 3));
  height: 100%;
  padding: 0rem;
  align-items: center;
`;

export const PadCon5 = styled.div`
  display: flex;
  flex-direction: row;
  background: #211c6f;
  width: calc(100% * (1 / 3));
  height: 100%;
  padding: 0.3rem;
  align-items: flex-end;
`;

export const ContainerTopLeft = styled.div`
  display: flex;
  flex-direction: column;
  width: calc((1 / 3) * 100%);
`;

export const ConAcumulado = styled.div`
  display: flex;
  border-radius: 10px;
  background: linear-gradient(#12144d, #0e126f, #12144d);
  width: 100%;
  flex-grow: 1;
  height: 4rem;
  border: solid;
  border-color: white;
  border-top-color: black;
  border-left-color: black;
  border-width: 1px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 0.1rem solid #ffffff;
`;

export const ContainerTopRight = styled.div`
  width: calc((1 / 3) * 100%);
  display: flex;
  flex-direction: column;
`;

export const ConLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  background: transparent;
  height: 8rem;
  flex-grow: 1;
  padding: 0 0.5rem;
  width: calc((1 / 3) * 100%);
`;

export const ConTextBolasSort = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: transparent;
  height: 2.5rem;
`;

export const ConSorteio = styled(ConAcumulado)`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  border-radius: 10px;
`;

export const ConBolasSort = styled(ConAcumulado)`
  display: flex;
  flex-direction: row;
  width: 7rem;
  height: 2.5rem;
  align-items: center;
  justify-content: center;
  padding: 0.2rem;
`;

export const TextBolasSort = styled.h1`
  font-size: 0.9rem;
  line-height: 1;
  margin: 0px;
  font-weight: 500;
  color: #f0d50c;
`;

export const TextBolasSortNumber = styled(TextBolasSort)`
  padding-bottom: 3px;
  padding-left: 10px;
  font-size: 2.2rem;
  line-height: 1.5rem;
  color: white;
`;

export const Paragrafo = styled.p`
  margin: 0px 0px 2px 0px;
`;

export const ContainerAward = styled.div`
  width: 100%;
  height: 90%;
  padding: 0.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  @media (min-width: 2048px) {
  }
`;

export const CardAward = styled.div`
  width: calc(100% * (1 / 4));
  height: 100%;
  border-radius: 5px;
  border: solid 1px white;
  @media (min-width: 1024px) {
  }
`;

export const HeadAward = styled.div<{ bg: string }>`
  width: 100%;
  height: 40%;
  padding: 0.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.bg};
  border-radius: 0.2rem 0.2rem 0 0;
  color: #ffffff;
  @media (min-width: 2048px) {
  }
`;

export const BodyAward = styled.div`
  width: 100%;
  height: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2b2d32;
  color: #ffffff;
  padding: 0.2rem;
  border-radius: 0 0 0.3rem 0.3rem;
  @media (min-width: 2048px) {
  }
`;

export const Cart = styled.div`
  padding: 1rem 0.4rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: var(--bg-primary);
  align-items: center;
  width: 50%;
  padding: 1rem 0.5rem 0.1rem 0.5rem;
  @media (min-width: 900px) {
    padding: 1rem 0.4rem 0.1rem 0.4rem;
    width: 100%;
    height: calc(100% * (1 / 4));
  }
`;

export const CartContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #d3e6ea;
  border-radius: 0.2rem;
  @media (min-width: 2048px) {
  }
`;

export const CartHeader = styled.div`
  height: 20%;
  font-size: 0.8rem;
  padding: 0.1rem 0.1rem;
  border-radius: 0.2rem 0.2rem 0 0;
  background: #1f1a64;
  display: flex;
  align-items: center;
  @media (min-width: 1024px) {
    font-size: 1rem;
  }
`;

export const CartCodigo = styled.p`
  margin: 0;
  padding-left: 0rem;
  width: 3.5rem;
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 0.2rem;
  line-height: 1.1rem;
  font-weight: bold;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 1024px) {
  }
`;

export const CartEstab = styled.p`
  margin: 0;
  padding-left: 0.5rem;
  color: #ffffff;
  line-height: 1.1rem;
  @media (min-width: 2048px) {
  }
`;

export const CartRow = styled.div`
  display: flex;
  width: 100%;
  place-content: center;
  height: calc(((80% - 0.6rem) * (1 / 3)));
  @media (min-width: 2048px) {
  }
`;
export const ContentCartNumber = styled.div`
  width: calc(100% * (1 / 5));
  padding: 0.1rem;
  display: flex;
  justify-content: center;
  @media (min-width: 2048px) {
  }
`;

export const CartNumber = styled.div`
  background: radial-gradient(
    168.26% 168.26% at 50% 50.43%,
    #ececec 0%,
    #727175 100%
  );
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.1rem;
  font-weight: bold;
  @media (min-width: 2048px) {
  }
`;

export const CartNumberMarked = styled(CartNumber)`
  background: green;
  color: #f5f5f5;
  font-weight: 500;
`;

export const ContentLineCart = styled.div`
  width: 100%;
  padding: 0.2rem 0.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0.6rem;
`;

export const LineCart = styled.div`
  background: radial-gradient(
    165.86% 165.86% at 50% 50%,
    #170e90 0%,
    #313240 100%
  );
  width: 98%;
  height: 0.2rem;
  border-radius: 2rem;
`;

export const TextReplay = styled.a`
  font-size: 1.8rem;
  color: white;
  font-weight: 800;
  line-height: 0.5;
  //-webkit-text-stroke: 1px black;
  //text-shadow: 0 0 3px #FF0000, 0 0 5px #0000FF;
`;

export const DivReplay = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-right: 1.4rem;
`;

export const ContainerReplay = styled.div`
  display: flex;
  background: #ac0000;
  width: 10rem;
  border: white;
  border-radius: 0px 0px 10px 10px;
  height: 2.8rem;
  align-items: center;
  justify-content: center;
  border: 1px white solid;
  margin-top: -2rem;
`;

export const CartHolder = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media (min-width: 2048px) {
    height: 100%;
  }
`;

export const Logo = styled.img`
  max-width: 85px;
  max-height: 65px;
`;

export const TextAcum = styled.h1`
  font-size: 0.9rem;
  color: white;
  margin: 0px;
  margin-right: 3px;
  font-weight: 500;
  line-height: 1rem;
  font-family: "Oswald", sans-serif;
`;

export const TextAcumNumber = styled.h1`
  font-size: 1rem;
  color: white;
  margin: 0px;
  font-weight: 400;
  line-height: 1.5rem;
`;

export const TextAcumValor = styled.h1`
  font-size: 2.2rem;
  color: #f0d50c;
  margin: 0px;
  font-weight: 500;
  line-height: 1.5rem;
`;

export const TextSorteio = styled.h1`
  font-size: 1rem;
  color: #f0d50c;
  margin: 0px;
  font-weight: 500;
  line-height: 1.5rem;
  font-family: "Oswald", sans-serif;
`;

export const TextSorteioValor = styled.h1`
  font-size: 2.2rem;
  color: white;
  margin: 0px;
  font-weight: 500;
  line-height: 1.5rem;
`;

export const ContUl = styled.div`
  font-size: 1rem;
  line-height: 10px;
  color: black;
  font-weight: 500;
  line-height: 1.7rem;
  width: 100%;
  @media (min-width: 2048px) {
    height: 98%;
    font-size: 4rem;
    border-radius: 2rem;
  }
`;

// Tabela 👇🏼👇🏼
export const DivTabela = styled.div`
  height: 100%;
  background: #1f1a64;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 0.2rem;
  border: solid 1px #0c0c0c;
  border-radius: 0.5rem;
  padding-bottom: 0.2rem;
  @media (min-width: 2048px) {
    height: 100%;
    width: 98%;
  }
`;
export const TopoTabela = styled.div`
  height: 7%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  text-align: center;
`;

export const TextTopoTabela = styled.h1<{ width: string }>`
  padding-left: 0.2rem;
  font-size: 1.1rem;
  line-height: 10px;
  color: white;
  margin: 0px;
  font-weight: 500;
  line-height: 1.7rem;
  width: ${(props) => props.width};
  @media (min-width: 2048px) {
  }
`;

export const BottomTabela = styled.div`
  height: 93%;
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media (min-width: 2048px) {
    font-size: 4rem;
    border-radius: 2rem;
    align-items: center;
  } ;
`;

export const ContDivTable = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  flex-direction: row;

  height: 1.8rem;

  padding-left: 0.4rem;
  @media (min-width: 900px) {
    height: calc(100% * (1 / 15));
  }
`;

export const LiLine = styled.div<{ width: string }>`
  display: flex;
  width: ${(props) => props.width};
  padding: 0.1rem;
  margin: 0.1rem;
  border-radius: 5px;
  background: radial-gradient(
    168.26% 168.26% at 50% 50.43%,
    #ececec 0%,
    #727175 100%
  );
  justify-content: center;
  line-height: 1.4rem;
  font-size: 1rem;
  font-weight: 500;
  height: 90%;
  @media (min-width: 1024px) {
    font-size: 1.1rem;
  }
  @media (min-width: 1366px) {
    padding: 0.1rem;
    margin: 0.2rem;
  }
  @media (min-width: 2048px) {
    height: 90%;
  }
`;

export const LiNumbers = styled(LiLine)`
  background: transparent;
`;

export const LiNumber = styled.div`
  display: flex;
  margin: 0rem 0.2rem 0rem;
  width: 100%;
  justify-content: center;
  background: radial-gradient(
    168.26% 168.26% at 50% 50.43%,
    #ececec 0%,
    #727175 100%
  );
  border-radius: 5px;
`;

export const ContainerTubeBall = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 2048px) {
    height: 100%;
  }
`;

export const ContentTubeBall = styled.div`
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  width: 95%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 1028px) {
    justify-content: space-around;
    align-items: flex-start;
    width: 100%;
  }
  @media (min-width: 1366px) {
    justify-content: space-between;
  }
  @media (min-width: 2048px) {
    height: 100%;
  }
`;

export const GloboContainer = styled.div`
  width: 6.7rem;
  height: 6.7rem;
  border: 0.1rem solid #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  background: radial-gradient(
    178.57% 178.57% at 50% 50.43%,
    #7f77f2 0%,
    #010733 100%
  );
  border-radius: 100%;
  position: relative;
  z-index: 2;
  @media (min-width: 1028px) {
    width: 14rem;
    height: 14rem;
    margin-top: 0.5rem;
  }
  @media (min-width: 1366px) {
    margin-left: 5.5rem;
    margin-top: 1rem;
  }
  @media (min-width: 2048px) {
    height: 48rem;
    width: 48rem;
    grid-area: bola;
  }
`;

export const GloboContainer2 = styled(GloboContainer)`
  width: 5.5rem;
  height: 5.5rem;
  border: none;
  background: radial-gradient(
    87.19% 87.19% at 50% 50%,
    #23243da6 0%,
    #2424337f 100%
  );
`;

export const BallContainer = styled.div<{}>`
  width: 6rem;
  height: 6rem;
  background: radial-gradient(
    178.57% 178.57% at 50% 50.43%,
    #7f77f2 0%,
    #010733 100%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: 100%;
  border-radius: 100%;
  position: relative;
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
`;

export const BallCalledContainer = styled.div<{}>`
  width: 25%;
  height: 25%;
  background-repeat: no-repeat;
  background-size: 100%;
  background-image: url(${Painel});
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
`;

export const Ball = styled.div`
  position: relative;
  display: flex;
`;

export const NumberBall = styled.p`
  margin: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  font-size: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
  font-weight: bold;
  @media (min-width: 1028px) {
    font-size: 5rem;
  }
  @media (min-width: 1028px) {
    font-size: 15rem;
  }
`;

export const NumberBallSmaller = styled.p`
  position: absolute;
  font-size: 1.5rem;
  text-align: center;
  color: #000000;
  font-weight: bold;
  margin: 0;
  margin-bottom: 0.3rem;
  @media (min-width: 1028px) {
    font-size: 2.2rem;
    margin-bottom: 0.5rem;
  }
`;

export const RepositorioBalls = styled.div`
  background: radial-gradient(
    178.57% 178.57% at 50% 50.43%,
    #77f2a8 0%,
    #12859e 39.58%,
    #013333 66.67%
  );
  min-width: 8rem;
  width: calc(100% - 6rem);
  padding: 0 1rem;
  margin-left: -1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 3.5rem;
  border-radius: 0 0.3rem 0.3rem 0;
  z-index: 0;
  border: 0.1rem solid #fff;
  overflow: hidden;
  @media (min-width: 1028px) {
    flex-direction: column;
    min-width: 0;
    width: 7rem;
    padding: 0.5rem 1rem 0rem 1rem;
    height: 30rem;
    margin-top: 0.5rem;
    margin-left: 0rem;
    border-radius: 0.3rem;
  }
  @media (min-width: 1366px) {
    height: 32.7rem;
    margin-right: -0.1rem;
  }
  @media (min-width: 2048px) {
    flex-direction: column;
    min-width: 0;
    width: 7rem;
    padding: 0.5rem 1rem 0rem 1rem;
    height: 30rem;
    margin-top: 0.5rem;
    margin-left: 0rem;
    border-radius: 0.3rem;
    width: 25%;
    height: 100%;
    margin-top: 1rem;
    grid-area: tubo;
  }
`;

export const BallContainerMin = styled.div`
  width: 3.2rem;
  height: 3.2rem;
  margin-left: 0.2rem;
  margin-right: 0.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background-image: url(${BallImg});
  background-repeat: no-repeat;
  background-size: 100%;
  border-radius: 100%;
  @media (min-width: 1028px) {
    flex-direction: column;
    width: 5.4rem;
    height: 5.4rem;
    margin-top: 0.1rem;
  }
  @media (min-width: 2048px) {
    width: 5.4rem;
    height: 100%;
    margin-top: 0.5rem;
  }
`;

export const ContainerError = styled.div`
  display: flex;
  align-content: center;
  padding: 2rem;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background: radial-gradient(
    79.29% 79.29% at 49.87% 45.36%,
    #8f94ce 0%,
    #282a81 100%
  );
`;

export const ContentAudio = styled.div`
  width: 3rem;
  align-self: flex-start;
  display: flex;
  padding-left: 0.8rem;
  margin-top: -2.5rem;

  left: 0;
`;

export const GoMuteIcon = styled(Unmute)`
  color: white;
  width: 2rem;
  height: 2rem;
`;

export const VolumeMuteIcon = styled(VolumeMute)`
  color: white;
  width: 2rem;
  height: 2rem;
`;

export const ModalAudio = styled.div`
  display: flex;
  width: 90%;
  height: 20rem;
  position: absolute;
  background: #111040;
  margin-top: 3rem;
  border-radius: 10px;
  color: white;
  justify-content: center;
  align-items: center;
  border: solid 4px white;
  z-index: 9999;
  flex-wrap: wrap;
  min-width: 0;
`;
export const TextLoading = styled.p`
  color: white;
  font-size: 3rem;
  text-align: center;
  margin: 0;
  border: none;
  min-width: 0;
`;

export const TextAnimationPoints = styled(TextLoading)`
  animation: dots 1s steps(5, end) infinite;
  font-size: 4rem;
  margin: 0rem 0rem 0.9rem 0rem;

  @keyframes dots {
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }
    40% {
      color: white;
      text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }
    60% {
      text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
    }
    80%,
    100% {
      text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
    }
  }
`;
