import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { formatMoneyNotSDec } from "../hooks/formatNumber";
import * as V from "../pages/Styles/Vencedores";

type vencedores = {
    sorteio: IPartida;
    vencedoresKuadra: number[];
    vencedoresKina: number[];
    vencedoresKeno: number[];
};

export const PremioUser = ({ sorteio, vencedoresKuadra, vencedoresKina, vencedoresKeno }: vencedores) => {
  
    const [cardInUse, setCardInUse] = useState<ICartelas[]>()
    const [Total, setTotal] = useState<number>(0)
    const UserCard: IBilheteSorteio[] = useSelector((state:any)=>state.bilhetes.lista)

    const [Kuadra, setKuadra] = useState(false)
    const [Kina, setKina] = useState(false)
    const [Keno, setKeno] = useState(false)
  
    const listAtual = ()=>{
      const cards:IBilheteSorteio[] = []
      const cardWard:ICartelas[]=[]
      let total:number = 0
      UserCard.filter(x=>x.sorteio.codigo === sorteio.codigo).forEach(cart=>
        cards.push(cart)
      )
      cards.forEach(x=>x.cartelas.forEach(cart=>{
        if(vencedoresKuadra.find(win=>win == cart.codigo)){
            total += sorteio.valor_kuadra/vencedoresKuadra.length
            cardWard.push(cart)
            setKuadra(true)
        }
        if(vencedoresKina.find(win=>win == cart.codigo)){
            total += sorteio.valor_kuadra/vencedoresKina.length
            cardWard.push(cart)
            setKina(true)
        }
        if(vencedoresKeno.find(win=>win == cart.codigo)){
            total += sorteio.valor_kuadra/vencedoresKeno.length
            cardWard.push(cart)
            setKeno(true)
        }
      }))
      setCardInUse(cardWard)
      setTotal(total)
    }

    console.log(cardInUse)
    
    useEffect(() => {
      listAtual()
    }, [])
    
    if(cardInUse && cardInUse.length > 0){
        return(
            <V.Container>
              <V.Header tipo="keno">{("Parabéns, você venceu!").toUpperCase()}</V.Header>
              <V.Boby tipo="keno">
                  {cardInUse?.map((card,index)=>(
                        <V.Line key={index}>
                            <div
                                style={{
                                width: "calc(100% * (2/5))",
                                padding: "0rem 0.2rem 0rem 0rem",
                                }}
                            >
                                <V.Bloco>{card.codigo}</V.Bloco>
                            </div>
                            <div
                                style={{
                                width: "calc(100% * (3/5))",
                                padding: "0rem 0.2rem 0rem 0rem",
                                }}
                            >
                                <V.Bloco>{card.estabelecimento}</V.Bloco>
                            </div>
                        </V.Line>
                    ))}
                    <V.Line>
                        <div
                            style={{
                            width: "calc(100% * (2/5))",
                            padding: "0rem 0.2rem 0rem 0rem",
                            }}
                        >
                            <V.Bloco>{"PREMIO"}</V.Bloco>
                        </div>
                        <div
                            style={{
                            width: "calc(100% * (3/5))",
                            padding: "0rem 0.2rem 0rem 0rem",
                            }}
                        >
                            <V.Bloco>{formatMoneyNotSDec(Total)}</V.Bloco>
                        </div>
                    </V.Line>
                    <V.Line>
                        <div
                            style={{
                            width: "calc(100% * (2/6))",
                            padding: "0rem 0.2rem 0rem 0rem",
                            }}
                        >
                            <V.BlocoPremios active={Kuadra}>{"KUADRA"}</V.BlocoPremios>
                        </div>
                        <div
                            style={{
                            width: "calc(100% * (2/6))",
                            padding: "0rem 0.2rem 0rem 0rem",
                            }}
                        >
                            <V.BlocoPremios active={Kina}>{"KINA"}</V.BlocoPremios>
                        </div>
                        <div
                            style={{
                            width: "calc(100% * (2/6))",
                            padding: "0rem 0.2rem 0rem 0rem",
                            }}
                        >
                            <V.BlocoPremios active={Keno}>{"KENO"}</V.BlocoPremios>
                        </div>
                    </V.Line>
                </V.Boby>
            </V.Container>
        )}else{
            return(
            <V.Container>
                <V.Header tipo="keno">{"NÃO FOI DESSA VEZ!"}</V.Header>
                <V.Boby tipo="keno">
                    <V.LineOver>
                        <div
                            style={{
                            width: "100%",
                            }}
                        >
                            <V.Bloco style={{height:"3.5rem",borderRadius:"0.2rem"}}>
                                {"TENTE NOVAMENTE"}<br/>{"EM OUTRO SORTEIO."}
                            </V.Bloco>
                        </div>
                    </V.LineOver>
                </V.Boby>
            </V.Container>
        )
    }
  }